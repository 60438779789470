
import { Ref, ref, watch, computed, defineComponent, toRef, watchEffect } from 'vue';
import RwModal from './RwModal.vue';
import RwModalBody from './RwModalBody.vue';
import RwModalFooter from './RwModalFooter.vue';
import RwModalHeader from './RwModalHeader.vue';
import RsButton from '../RsButton/RsButton.vue';
import { useModalDragDown } from '../../composables/useModalDragDown';

export default defineComponent({
  name: 'RwDialog',
  components: { RsButton, RwModal, RwModalBody, RwModalFooter, RwModalHeader },
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
      default: '',
    },
    parentheticalTitle: {
      type: String,
      default: '',
    },
    clickOutsideToClose: {
      type: Boolean,
      default: true,
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    aside: {
      type: Boolean,
      default: false,
    },
    sheet: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    showBackText: {
      type: Boolean,
      default: false,
    },
    backIcon: {
      type: String,
      default: 'ltkfont-left-chevron-outline',
    },
    padded: {
      type: Boolean,
      default: true,
    },
    dragCollapse: {
      type: Boolean,
      default: false,
    },
    dragDismiss: {
      type: Boolean,
      default: false,
    },
    primaryCloseBtn: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': (v: boolean) => true,
    back: (v: boolean) => true,
    closed: () => true,
    'drag-close': () => true,
    'drag-cancelled': (e: any) => true,
  },
  model: { prop: 'modelValue', event: 'update:modelValue' },
  setup(props, { emit, slots }) {
    const active = ref(false) as Ref<boolean>;
    const hasSlot = (slot: string) => slots[slot] !== undefined;
    const dragTargetRef: Ref<InstanceType<typeof RwModal> | null> = ref(null);
    const dragTriggerRef: Ref<InstanceType<typeof RwModalHeader> | null> = ref(null);
    const { stage, dragCreate, dragDestroy, dragDirection } = useModalDragDown(
      dragTargetRef,
      dragTriggerRef,
      toRef(props, 'dragDismiss'),
      toRef(props, 'dragCollapse'),
    );

    watch(
      () => props.modelValue,
      (next: boolean) => {
        if (next) active.value = next;
      },
      {
        immediate: true,
      },
    );

    watch(
      () => active.value,
      (next: boolean) => {
        emit('update:modelValue', next);
      },
      {
        immediate: true,
      },
    );

    watch(
      () => stage.value,
      (next: number) => {
        // When `dragCollapse === true` Close the modal if the stage is -1.
        const dismissOnly = props.dragDismiss && !props.dragCollapse && next === -1;
        // When `dragCollapse === false` Close the modal if the stage is 0.
        const collapseThenDismiss = props.dragDismiss && props.dragCollapse && next === -1;

        if (dismissOnly || collapseThenDismiss) {
          emit('drag-close');
          return onClose();
        }
      },
    );

    const modifiers = computed(() => {
      return {
        'has--actions': hasSlot('actions-start') || props.showBack,
        'backbutton--show-text': props.showBackText,
      };
    });

    const onClose = () => {
      active.value = false;
      // Teardown the drag handler if it was enabled. This will cleanup and remove the event listeners and reset the stage to 1.
      emit('update:modelValue', false);
      emit('closed');
      if (props.dragCollapse || props.dragDismiss) {
        dragDestroy();
      }
    };

    const onBack = () => {
      emit('back', false);
    };

    // `initDragHandler` must be triggered on @opened which is fired after the modal animation is complete. Which is nessercary to capture accurate element positions. This allows us to avoid dealing with the `requestAnimationFrame` api.
    const initDragHandler = async () => {
      if (props.dragCollapse || props.dragDismiss) {
        // Setup the drag handler if it was enabled. This grabs the dom content of the template refs and adds the event listeners.
        dragCreate();
      }
    };

    watchEffect(
      () => {
        if (dragTriggerRef.value?.$el as HTMLElement) {
          (dragTriggerRef.value?.$el as HTMLElement).addEventListener('drag-cancelled', (e: any) => {
            emit('drag-cancelled', e.detail);
          });
        }
      },
      {
        flush: 'post',
      },
    );

    return {
      active,
      onClose,
      onBack,
      hasSlot,
      modifiers,
      dragTargetRef,
      dragTriggerRef,
      stage,
      initDragHandler,
      dragDirection,
    };
  },
});
