
import { defineComponent } from 'vue';
import uuid from '../../utils/uuid';

export default defineComponent({
  name: 'RsBreadcrumbs',
  props: {
    id: {
      type: String,
      default: () => 'rs-breadcrumbs' + uuid(),
    },
  },
  setup(props) {},
});
