import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  CreateProfileRequest,
  Profile,
  ProfilesStoreState,
  UpdateProfileRequest,
  UploadImageRequest,
} from '@/store/profiles/types';

import ProfilesApi from '@/store/profiles/api';
import { auth } from '@/utils/auth';

@Module({ dynamic: true, name: 'ProfilesStore', namespaced: true, store })
class ProfilesStore extends VuexModule implements ProfilesStoreState {
  public profile: Profile = {
    id: '',
    searchable: true,
    rs_account_id: 0,
    display_name: '',
    bio: '',
    full_name: '', // store/profile name
    instagram_name: '',
    blog_name: '',
    blog_url: '',
    image_mime_type: '',
    bg_image_mime_type: '',
    avatar_url: '',
    avatar_upload_url: '',
    has_uploaded_avatar_image: false,
    bg_image_url: '',
    bg_upload_url: '',
    has_uploaded_bg_image: false,
    classification: {
      height: undefined,
      body_shapes: [],
    },
  };

  public profileError: Error | null = null;

  public hasFetchedProfile = false;

  /**
   * Fetches a Profile
   * @param {Object} options - Options object
   * @param {number} options.accountId - The account id
   * @param {boolean} options.overwrite - Determines whether the action fetches and overwrites the data. Set to false (cache data from first action call) by default
   * @returns true if the Account has a Profile, false if the Account does not have a Profile, undefined if there is an Error
   */
  @Action
  public async fetchProfile({ accountId, overwrite }: { accountId?: number; overwrite?: boolean }) {
    if (!accountId) {
      // Account ID hasn't been provided, so use the ID token claims to read it
      accountId = (await auth.getIdTokenClaims())?.account_id as number;
    }

    if (!overwrite) overwrite = false;

    const canOverwrite = overwrite || !this.hasFetchedProfile;

    try {
      if (canOverwrite) {
        const response = await ProfilesApi.getProfilesForAccount(accountId);
        const hasProfile = response.profiles !== null && response.profiles.length > 0;
        if (!hasProfile) return false;

        if (response.profiles?.[0]) this.SET_PROFILE(response.profiles?.[0]);
        this.HAS_FETCHED_PROFILE(true);
        this.HANDLE_PROFILE_ERROR(null);
        return true;
      } else {
        return this.hasFetchedProfile;
      }
    } catch (error) {
      this.HANDLE_PROFILE_ERROR(error as Error);
    }
  }

  @Action
  public async createProfile({
    profile,
    userId,
    avatar,
    bgImage,
  }: {
    profile: CreateProfileRequest;
    userId: number;
    avatar?: Omit<UploadImageRequest, 'signedUploadUrl'>;
    bgImage?: Omit<UploadImageRequest, 'signedUploadUrl'>;
  }) {
    let newProfile = this.profile;

    try {
      const createProfileResponse = await ProfilesApi.createProfile(profile);
      newProfile = createProfileResponse.profile;

      await ProfilesApi.linkUser(userId, newProfile.id);

      if (avatar) {
        await ProfilesApi.uploadImage(
          {
            ...avatar,
            signedUploadUrl: newProfile.avatar_upload_url as string,
          },
          'Avatar',
        );
      }
      if (bgImage) {
        await ProfilesApi.uploadImage(
          {
            ...bgImage,
            signedUploadUrl: newProfile.bg_upload_url as string,
          },
          'Background',
        );
      }

      const updateProfileResponse = await ProfilesApi.updateProfile({
        ...newProfile,
        has_uploaded_avatar_image: avatar ? true : false,
        has_uploaded_bg_image: bgImage ? true : false,
      });

      newProfile = updateProfileResponse.profile;

      this.HANDLE_PROFILE_ERROR(null);
    } catch (error) {
      this.HANDLE_PROFILE_ERROR(error as Error);
    } finally {
      this.SET_PROFILE(newProfile);
    }
  }

  @Action
  public async updateProfile({
    profile,
    avatar,
    bgImage,
  }: {
    profile: UpdateProfileRequest;
    avatar?: Omit<UploadImageRequest, 'signedUploadUrl'>;
    bgImage?: Omit<UploadImageRequest, 'signedUploadUrl'>;
  }) {
    let updatedProfile = this.profile;

    try {
      let updateProfileResponse = await ProfilesApi.updateProfile(profile);
      updatedProfile = updateProfileResponse.profile;

      if (avatar || bgImage) {
        // Update ONLY the Profile's `image_mime_type` and `bg_image_mime_type` to generate a new
        // signed `avatar_upload_url` and `bg_upload_url` respectively for image upload
        updateProfileResponse = await ProfilesApi.updateProfile({
          id: profile.id,
          image_mime_type: avatar?.image.type,
          bg_image_mime_type: bgImage?.image.type,
        });
        updatedProfile = updateProfileResponse.profile;

        if (avatar) {
          await ProfilesApi.uploadImage(
            {
              ...avatar,
              signedUploadUrl: updatedProfile.avatar_upload_url as string,
            },
            'Avatar',
          );
        }
        if (bgImage) {
          await ProfilesApi.uploadImage(
            {
              ...bgImage,
              signedUploadUrl: updatedProfile.bg_upload_url as string,
            },
            'Background',
          );
        }

        updateProfileResponse = await ProfilesApi.updateProfile({
          ...updatedProfile,
          has_uploaded_avatar_image: updatedProfile.avatar_url ? true : avatar ? true : false,
          has_uploaded_bg_image: updatedProfile.bg_image_url ? true : bgImage ? true : false,
        });

        updatedProfile = updateProfileResponse.profile;
      }

      this.HANDLE_PROFILE_ERROR(null);
    } catch (error) {
      this.HANDLE_PROFILE_ERROR(error as Error);
    } finally {
      this.SET_PROFILE(updatedProfile);
    }
  }

  @Mutation
  public SET_PROFILE(profile: Profile) {
    if (profile) {
      this.profile = profile;
    }
  }

  @Mutation
  public HAS_FETCHED_PROFILE(hasFetchedProfile: boolean) {
    this.hasFetchedProfile = hasFetchedProfile;
  }

  @Mutation
  public HANDLE_PROFILE_ERROR(error: Error | null) {
    this.profileError = error;
  }
}

export default getModule(ProfilesStore);
