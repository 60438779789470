import { Hook } from '@/router/navigation-guards/afterEach/types';
import { getDefaultTitle } from '@/composables/usePageTitle';
import { useGettext } from '@/composables/useGettext';

/**
 * **Note:** This Hook should use `document.title` directly instead of our `usePageTitle()` composable. This is due to it being based on [VueUse's `useTitle()`](https://github.com/vueuse/vueuse/blob/main/packages/core/useTitle/index.ts), which updates the `document.title` asynchronously via an internal watch call.
 *
 * This is unfortunate, since it creates a race condition between the moment navigation is confirmed and this hook is executed and the moment a component is created.
 */
export const checkPageTitle: Hook = function (to, from) {
  const { $gettext } = useGettext();

  if (!to.meta?.title) {
    document.title = getDefaultTitle();
    return;
  }

  let { name } = to.meta.title;
  const { translate } = to.meta.title;

  // Unwrap name
  name = typeof name === 'function' ? name() : name;
  // Translate name
  name = translate ? $gettext(name) : name;

  document.title = `${getDefaultTitle()} - ${name}`;
};
