import { computed, nextTick, reactive, ref, toRef, toRefs } from 'vue';
import type { Ref } from 'vue';
import { RwModal, RwModalHeader } from '@/lib/components/RwModal';
import { toValue } from '@vueuse/core';
const useModalDragDown = (
  dragTargetRef: Ref<InstanceType<typeof RwModal> | null>,
  dragTriggerRef: Ref<InstanceType<typeof RwModalHeader> | null>,
  closable: Ref<boolean>,
  collapsable: Ref<boolean>,
) => {
  const mTarget: Ref<HTMLElement | null> = ref(null);

  // Typing these as <any> since any typing that is okay in the IDE is causing failures in unrelated unit tests.
  // TODO: Add proper typing to these when working on the VUE3 migration.
  // eg. const child = ref<ComponentPublicInstance | null>(null)
  // eg. const modal = ref<InstanceType<typeof MyModal> | null>(null)
  const targetRekt = ref({}) as Ref<DOMRect | undefined>;
  const rekt = ref({}) as Ref<DOMRect | undefined>;
  const pointer = reactive({
    start: 0,
    current: 0,
    end: 0,
    dragging: false,
    dragClose: false,
    distance: 0,
    stage: 1,
    distanceFromCenter: 0,
  });

  const dragDirection = computed(() => {
    if (pointer.start >= pointer.current) return 'up';
    if (pointer.start <= pointer.current) return 'down';
    return '';
  });

  const handleMouseMove = async (e: MouseEvent | TouchEvent) => {
    if (!mTarget.value || !rekt.value) return;
    await nextTick();
    // Update cursor, positioning and set/update styling.
    switch (e.type) {
      case 'touchmove':
        e.preventDefault();
        pointer.current = (e as TouchEvent).changedTouches[0].clientY;
        pointer.distance = Math.sqrt(Math.pow(pointer.start - (e as TouchEvent).changedTouches[0].clientY, 2));
        pointer.distanceFromCenter = dragDirection.value === 'down' ? pointer.distance : -pointer.distance;
        break;
      case 'mousemove':
        pointer.current = (e as MouseEvent).clientY;
        pointer.distance = Math.sqrt(Math.pow(pointer.start - (e as MouseEvent).clientY, 2));
        pointer.distanceFromCenter = dragDirection.value === 'down' ? pointer.distance : -pointer.distance;
        break;
    }
    // Set and update the styling of the target element.
    mTarget.value.style.transition = 'transform 0s';
    // When undocked:
    //   The top and bottom styling is zeroed by default.
    //   Dragging down the `top` is set to the distance the cursor has moved.
    //   Dragging up the `top` is set to the -distance the cursor has moved.
    // When docked:
    //   The top and bottom styling is set by css
    //   When dragging up the `top` is set to the cursor's Y position minus half of the height of the trigger.
    //   When dragging up the `top` is set to the cursor's Y position. However the style tag is not removed to allow for a smooth transition.
    mTarget.value.style.top =
      pointer.stage === 1 ? `${pointer.distanceFromCenter}px` : `${pointer.current - rekt.value.height / 2}px`;
  };

  const dragCancelled = new CustomEvent('drag-cancelled', {
    detail: {
      stage: pointer.stage,
      canClose: toValue(closable),
    },
  });
  const handleDragState = () => {
    if (!dragTriggerRef.value) return;

    const dragUp = dragDirection.value === 'up' && pointer.distance > 50;
    const dragDown = dragDirection.value === 'down' && pointer.distance > 50;
    const canCollapse = toValue(collapsable);
    const canClose = toValue(closable);
    const isCollaped = pointer.stage === 0;
    const isOpen = pointer.stage === 1;
    if (canCollapse) {
      if (isCollaped && dragDown && !canClose) {
        (dragTriggerRef.value.$el as HTMLElement).dispatchEvent(dragCancelled);
      } else if (isOpen && dragDown) {
        pointer.stage = 0;
      } else if (isCollaped && dragDown) {
        pointer.stage = -1;
      } else if (isCollaped && dragUp) {
        pointer.stage = 1;
      }
    } else {
      if (isOpen && dragDown && !canClose) {
        (dragTriggerRef.value.$el as HTMLElement).dispatchEvent(dragCancelled);
      } else if (isOpen && dragDown && canClose) {
        pointer.stage = -1;
      }
    }
  };

  const onMouseUp = () => {
    // Update the stage and remove event listeners.
    if (!mTarget.value || !dragTriggerRef.value) return;
    handleDragState();
    pointer.current = 0;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('touchmove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('touchend', handleMouseUp);
  };

  const handleMouseUp = async (e: MouseEvent | TouchEvent) => {
    // Update last pointer position and remove styles if the modal is not closing.
    if (!mTarget.value || !rekt.value) return;
    switch (e.type) {
      case 'touchend':
        pointer.end = (e as TouchEvent).changedTouches[0].clientY;
        break;
      case 'mouseup':
        pointer.end = (e as MouseEvent).clientY;
        break;
    }
    // Reenable to the transition that is set in the css.
    mTarget.value.style.transition = '';

    onMouseUp();
    // Dont remove styles if the modal is closing this will smooth the closing transition.
    if (pointer.stage === 0 && toValue(closable)) {
      mTarget.value?.removeAttribute('style');
    } else if (pointer.stage !== -1) {
      pointer.distance = 0;
      mTarget.value?.removeAttribute('style');
    }
  };

  const onMouseDown = () => {
    // Add event listeners
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove, { passive: false });
    document.addEventListener('touchend', handleMouseUp);
    document.addEventListener('touchmove', handleMouseMove, { passive: false });
  };

  const handleMouseDown = async (e: MouseEvent | TouchEvent) => {
    // Update Rekts since they may have changed since the last check. Establish the initial pointer position.
    if (!mTarget.value && !dragTargetRef.value && !dragTriggerRef.value) return;
    await nextTick();
    targetRekt.value = mTarget.value?.getBoundingClientRect();
    rekt.value = dragTriggerRef.value?.$el.getBoundingClientRect();
    if (rekt.value) {
      switch (e.type) {
        case 'touchstart':
          pointer.start = (e as TouchEvent).touches[0].clientY;
          break;
        case 'mousedown':
          pointer.start = (e as MouseEvent).clientY;
          break;
      }
      onMouseDown();
    }
  };

  const handleResize = async (e: Event) => {
    // Update rekts on resize.
    if (!mTarget.value || !rekt.value) return;
    await nextTick();
    targetRekt.value = mTarget.value?.getBoundingClientRect();
    rekt.value = dragTriggerRef.value?.$el.getBoundingClientRect();
  };

  const dragCreate = async () => {
    await nextTick();
    if (dragTargetRef.value && dragTriggerRef.value) {
      // Note: This property check and type cast is needed to avoid TS compiler errors in our unit test suite; this might be a limitation of Vue 2.7's component typing and may not be needed in Vue 3.x
      mTarget.value =
        'modalWrapperRef' in dragTargetRef.value ? (dragTargetRef.value.modalWrapperRef as HTMLElement | null) : null;
      (dragTriggerRef.value.$el as HTMLElement).addEventListener('mousedown', handleMouseDown, { passive: false });
      (dragTriggerRef.value.$el as HTMLElement).addEventListener('touchstart', handleMouseDown, { passive: false });
      window.addEventListener('resize', handleResize);
    }
  };
  const dragDestroy = async () => {
    // Set stage to 1 before `nextTick` to prevent the modal from jumping.
    // FUTURE: May be worth concidering a lazy mode which would maintain the stage after close.
    pointer.stage = 1;
    await nextTick();
    if (dragTriggerRef.value) {
      (dragTriggerRef.value.$el as HTMLElement).removeEventListener('mousedown', handleMouseDown);
      (dragTriggerRef.value.$el as HTMLElement).removeEventListener('touchstart', handleMouseDown);
    }
  };

  return {
    ...toRefs(pointer),
    dragCreate,
    dragDestroy,
    dragTargetRef,
    dragTriggerRef,
    dragDirection,
  };
};
export { useModalDragDown };
