import { NavigationGuard } from 'vue-router';
import { useRouterError } from '@/composables/useRouterError';

/**
 * @description A higher-order function for optimistic error handling for (unexpected) navigation guard errors.
 */
export const handleErrorsFor: (f: NavigationGuard) => NavigationGuard = (f) => async (to, from, next) => {
  const { error } = useRouterError();
  try {
    await Promise.resolve(f(to, from, next));
  } catch (e) {
    error.value = e as Error;
    next();
  }
};
