import { NavigationGuard } from 'vue-router';
import { HOME } from '@/utils/router/namedRoutes';

export const checkAndroid: NavigationGuard = async function (to, from, next) {
  if (process.env.NODE_ENV === 'development') {
    return next();
  }

  const ua = navigator.userAgent;

  if (/^LTK Consumer\/.* Android\//i.test(ua)) {
    return next();
  }

  return next({ name: HOME });
};
