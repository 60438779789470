import { defineStore } from 'pinia';
import { ref, computed, set } from 'vue';
import { ApiError } from '@/utils/api';
import ltksApi from '@/services/ltksApi';
import s3Api from '@/services/s3Api';
import ProfilesStore from '@/store/profiles';
import { useHashtagsStore } from '@/store/hashtags';
import {
  type CreateLtkRequest,
  type MediaObject,
  type LtkMap,
  type PublishLtkRequest,
  type Post,
  type Meta,
  type Ltks,
  type LtkPost,
  type LtkPostProduct,
  type MediaObjectMap,
} from '@/types/ltks';
import { DRAFT, FULFILLED } from '@/rs-publish/consts/publish';
import { usePostProductsStore } from './postProducts';
import { useDraftsStore } from './drafts';
import { PostProduct, PostProductAction } from '@/types/postProducts';
import { S3Client } from '@aws-sdk/client-s3';

export const usePublishStore = defineStore('Publish Service', () => {
  // Initial State
  const selectedImage = ref<File | null>(null);
  const selectedImageURL = ref<string | undefined>(undefined);
  const inProcessImageURL = ref<string | undefined>(undefined);
  const processedImageURL = ref<string | undefined>(undefined);
  const videoFile = ref<File | null>(null);
  const posterFile = ref<File | null>(null);
  const selectedVideoFileList = ref<FileList | null>(null);
  const selectedVideo = ref<string>('');
  const selectedVideoPoster = ref<string | undefined>(undefined);
  const uploadProgress = ref<number | undefined>(undefined);

  const mediaObjects = ref<Array<MediaObject>>([]);
  const mediaObjectMap = ref<MediaObjectMap>({});
  const postId = ref<string | null>(null);
  const ltkMap = ref<LtkMap>({});
  const captionInput = ref<string>('');
  const ltkPostList = ref<Array<Post>>([]);
  const meta = ref<Meta | undefined>(undefined);
  const savedProducts = ref<Array<PostProduct>>([]);

  const ltkPostRequest = ref<PublishLtkRequest>({
    accepted_copyright_terms: true,
    attributes: [],
    caption: '',
    date_scheduled: '',
    media_updates: [],
    product_updates: [],
    rank: undefined,
    state: '',
    title: '',
    type: '',
  });

  const androidUploadData = computed(() => {
    let postMediaType = null;
    let mediaPayload = null;
    const hasMedia = videoFile.value !== null || processedImageURL.value !== undefined;
    if (hasMedia) {
      postMediaType = videoFile.value ? 'video' : 'picture';
      mediaPayload = videoFile.value ? videoFile.value : processedImageURL.value;
    } else {
      postMediaType = null;
      mediaPayload = null;
    }
    return {
      hasMedia,
      postMediaType,
      mediaPayload,
      mediaObjects: mediaObjects.value,
    };
  });

  /*
    For now getUploadClient and handleUpload are not going to be
      utilized because all uploads have been shifted to the Android.
      Depending on future iterations of the posting
      flow they might be used again so they have been refactored
      out of the Post Create/Patch flow into their stand-alone
      functions to prevent rework and act as documentation if
      web-side uploads are needed again.
  */
  const getUploadClient = (mediaObject: MediaObject) => {
    return new S3Client({
      region: 'us-east-1',
      useAccelerateEndpoint: true,
      credentials: {
        accessKeyId: mediaObject.upload_auth.aws_access_key_id,
        secretAccessKey: mediaObject.upload_auth.aws_secret_access_key,
        sessionToken: mediaObject.upload_auth.aws_session_token,
      },
    });
  };

  const handleUpload = async (mediaObjects: MediaObject[]) => {
    for (const mediaObject of mediaObjects) {
      const bucket = mediaObject.metadata.s3_upload_bucket!;
      const uploadKey = mediaObject.metadata.s3_upload_key;
      if (bucket && uploadKey) {
        if (videoFile.value) {
          // Uploads a video
          await s3Api.uploadVideoS3ClientMultipart(getUploadClient(mediaObject), bucket, videoFile.value, uploadKey);
          // Uplaods a video thumbnail
          const thumbnail = mediaObject.thumbnails?.[0];
          if (selectedVideoPoster.value && thumbnail) {
            await s3Api.uploadImageS3Client(
              getUploadClient(thumbnail),
              thumbnail.metadata.s3_upload_bucket || '',
              selectedVideoPoster.value,
              thumbnail.metadata.s3_upload_key || '',
            );
          }
        }
        if (processedImageURL.value) {
          await s3Api.uploadImageS3Client(getUploadClient(mediaObject), bucket, processedImageURL.value, uploadKey);
        }
      }
    }
  };

  function $reset() {
    const hashtagStore = useHashtagsStore();
    const postProductsStore = usePostProductsStore();
    hashtagStore.$reset();
    postProductsStore.reset();
    resetImage();
    resetVideo();
    ltkPostRequest.value = {} as PublishLtkRequest;
    captionInput.value = '';
    posterFile.value = null;
    postId.value = null;
    uploadProgress.value = undefined;
    savedProducts.value = [];
    mediaObjects.value = [];
  }

  const profileId = computed(() => ProfilesStore?.profile?.id);
  const profileDisplayName = computed(() => ProfilesStore?.profile?.display_name);
  const profileAvatar = computed(() => ProfilesStore?.profile?.avatar_url);

  const handleImage = async (file: File | null) => {
    const reader = new FileReader();
    if (file === null) return;
    await new Promise((res, rej) => {
      reader.addEventListener('load', res);
      reader.addEventListener('error', rej);
      reader.readAsDataURL(file);
    });
    return (reader.result as string | null) || undefined;
  };

  const handleVideo = async (file: File) => {
    return URL.createObjectURL(file);
  };
  const setRawVideoFile = async (files: FileList) => {
    selectedVideoFileList.value = files;
  };

  const setSelectedVideo = (url: string) => {
    selectedVideo.value = url;
  };

  const setVideo = async (file: File) => {
    videoFile.value = file;
    if (selectedVideo.value) {
      URL.revokeObjectURL(selectedVideo.value);
    }
    selectedVideo.value = await handleVideo(file);
  };
  const setVideoPoster = async (file: File) => {
    if (!file) return;
    posterFile.value = file;
    selectedVideoPoster.value = await handleImage(file);
  };

  const setSelectedImage = async (image: File | null) => {
    selectedImageURL.value = await handleImage(image);
    selectedImage.value = image;
    setInProcessImage(selectedImageURL.value);
  };

  const setInProcessImage = (imgUrl: string | undefined) => {
    inProcessImageURL.value = imgUrl;
  };

  const setProcessedImage = (imageUrl: string | undefined) => {
    processedImageURL.value = imageUrl;
  };

  const setUploadProgress = (progress: number | undefined) => {
    uploadProgress.value = progress;
  };

  const updateVideo = (videoUri: string, imageUri: string) => {
    selectedVideo.value = videoUri;
    selectedVideoPoster.value = imageUri;
  };

  const resetImage = () => {
    processedImageURL.value = undefined;
    inProcessImageURL.value = undefined;
    selectedImage.value = null;
    selectedImageURL.value = undefined;
  };

  const resetVideo = () => {
    videoFile.value = null;
    selectedVideoPoster.value = undefined;
    selectedVideo.value = '';
    selectedVideoFileList.value = null;
  };

  const setCaptionInput = (caption: string) => {
    captionInput.value = caption;
  };

  const setMediaObjects = (media: Array<MediaObject>) => {
    mediaObjects.value = media;
  };

  const setPublishLtkRequest = (request: PublishLtkRequest) => {
    ltkPostRequest.value = request;
  };

  const addRemovePostToPostList = (response: LtkPost) => {
    if (response.post.state === DRAFT) {
      if (!ltkPostList.value.map((item) => item.id === response.post.id).includes(true)) {
        ltkPostList.value.unshift({
          ...response.post,
          cover_photo_url: processedImageURL.value || selectedVideoPoster.value,
          post_products: response.post_products || [],
        });
      }
      ltkMap.value[response.post.id] = {
        ...response.post,
        cover_photo_url: processedImageURL.value || selectedVideoPoster.value,
        post_products: response.post_products,
      };
    } else {
      ltkPostList.value = ltkPostList.value.filter((item) => item.id !== response.post.id);
    }
  };

  const setProducts = () => {
    const postProductsStore = usePostProductsStore();
    savedProducts.value = JSON.parse(JSON.stringify(postProductsStore.products));
  };

  const getProductsInput = (isNewPhoto: boolean) => {
    const draftsStore = useDraftsStore();
    let updatedProducts: PostProduct[] = [];

    const draftProducts = draftsStore.selectedDraftPost?.post_products;
    if (draftProducts && draftProducts.length > 0) {
      draftProducts.map((product) => {
        if (
          !savedProducts.value?.some((newProduct) => newProduct.product_reference_id === product.product_reference_id)
        ) {
          updatedProducts.push({ ...product, action: PostProductAction.DELETE } as unknown as PostProduct);
        }
      });
      const productIds = new Set();

      savedProducts.value?.map((newProduct) => {
        // Filter duplicates
        if (!productIds.has(newProduct.product_reference_id)) {
          productIds.add(newProduct.product_reference_id);
          // If updating a photo, existing products will need to be re-added to the post.
          updatedProducts.push({
            ...newProduct,
            action: isNewPhoto ? PostProductAction.CREATE : newProduct.action,
          });
        }
      });
    } else {
      updatedProducts = savedProducts.value ? savedProducts.value : [];
    }
    return updatedProducts.length > 0 ? updatedProducts : undefined;
  };

  const createLtk = async () => {
    try {
      const ltkParams: CreateLtkRequest = {
        profile_id: profileId.value,
        caption: getCaptionHashtagsString(),
      };
      /*
        To account for when we create a post without media (e.g. a draft with no image or video selected)
          we should only add `media_object` if we actually have media.
          Otherwise a default video or picture media will be added but since there is nothing to upload
            the draft will be stuck in an "Uploading" state.
      */
      if (videoFile.value || processedImageURL.value) {
        ltkParams['media_objects'] = videoFile.value ? ['video'] : ['picture'];
      }
      const postLtkResponse = await ltksApi.createLtk(ltkParams);
      postId.value = postLtkResponse.post?.id;
      mediaObjects.value = postLtkResponse.media_objects;
      // Temporarily add the newly created post to the ltkPostList state until the ltks can be fetched
      addRemovePostToPostList(postLtkResponse);
      return true;
    } catch (error) {
      const errorResponse = (error as ApiError).response;
      // other errors should fail silently
      return false;
    }
  };

  const publishLtk = async () => {
    try {
      const patchLtkResponse = await ltksApi.publishLtk(postId.value, ltkPostRequest.value);
      // Temporarily add or remove published post to/from the ltkPostList state until the ltks can be fetched
      addRemovePostToPostList(patchLtkResponse);
      return true;
    } catch (error) {
      const errorResponse = (error as ApiError).response;
      return false;
      // other errors should fail silently
    }
  };

  const fetchAllLtksByProfileId = async (state?: string) => {
    let data: Meta | undefined = undefined;
    try {
      if (meta?.value?.next === null) {
        return;
      }
      if (meta?.value?.next !== undefined) {
        data = meta.value;
      }
      const params = {
        'profiles[]': profileId.value,
        limit: 20,
        state: state,
        next: data?.next,
      };
      const getResponse = await ltksApi.getLtks(params);
      meta.value = getResponse.meta;

      const ids: string[] = [];

      getResponse.posts.forEach((post, x) => {
        ids.push(JSON.stringify(post.id));
        const photoId = post?.cover_photo_ids?.[0];
        // Add cover photo
        getResponse.cover_photos?.find((item) => {
          if (item.id === photoId) {
            post.cover_photo_url = item.media_cdn_url;
          }
        });
        // Add Products
        post.post_products = addFormattedPostProducts(getResponse, post);

        set(ltkMap.value, post.id, post);
        const matches = post?.media_object_ids?.map((mediaObjId) =>
          getResponse.media_objects?.find((mediaObj) => mediaObj.id === mediaObjId),
        );
        if (matches) {
          set(mediaObjectMap.value, post.id, matches[0]);
        }
      });
      setPostFeed(getResponse);
    } catch (error) {
      // other errors should fail silently
    }
  };

  const addFormattedPostProducts = (response: Ltks, post: Post) => {
    // Add Products
    post.post_products = [];
    response.post_products?.map((item) => {
      if (item.post_id === post.id) {
        post.post_products.push(item);
      }
    });
    // Add Product Image
    post.post_products?.map((item) =>
      item.media_object_ids?.map((mediaObjId) =>
        response.media_objects?.find((mediaObj) => {
          if (mediaObj.id === mediaObjId) {
            item.image_url = mediaObj.media_cdn_url;
          }
        }),
      ),
    );
    const draftsStore = useDraftsStore();
    // Return formatted post products
    return draftsStore.formatPostProducts(post.post_products) as unknown as LtkPostProduct[];
  };

  const setPostFeed = (response: Ltks) => {
    if (ltkPostList.value.length > 0 && meta.value) {
      const start = ltkPostList.value.length;
      ltkPostList.value.splice(start, 0, ...response.posts);
    } else {
      ltkPostList.value = response.posts;
    }
  };

  const deleteLtkById = async (id: string) => {
    try {
      const response = await ltksApi.deleteLtk(id);
      delete ltkMap.value[response.id];
      ltkPostList.value = ltkPostList.value.filter((item) => item.id !== response.id);
    } catch (error) {
      // other errors should fail silently
    }
  };

  const deleteMultipleLtks = async (selected: string[]) => {
    const ltks: any[] = [];
    selected.map((postId) => {
      ltks.push(ltksApi.deleteLtk(postId));
    });
    try {
      const response = await Promise.allSettled(ltks);
      response.map((resp) => {
        if (resp.status === FULFILLED) {
          delete ltkMap.value[resp.value.id];
          ltkPostList.value = ltkPostList.value.filter((item) => item.id !== resp.value.id);
        } else {
          return false;
        }
      });
      return true;
    } catch (error) {
      // other errors should fail silently
      return false;
    }
  };
  const getLtkById = (id: string) => {
    return computed(() => ltkMap.value[id]);
  };

  const getMediaObjectById = (id: string) => {
    return computed(() => mediaObjectMap.value[id]);
  };

  // TODO: Replace implemention of getCaptionHashtagsString() with the captionHashtagsString.value
  const getCaptionHashtagsString = () => {
    const hashtagStore = useHashtagsStore();
    const cleanedCaption = captionInput.value.replace(/\n/g, '');
    const captionInputString = captionInput.value ? cleanedCaption + '\n\n' : '';
    return captionInputString + hashtagStore.hashtagInput.join(' ');
  };

  const captionHashtagsString = computed(() => {
    const hashtagStore = useHashtagsStore();
    if (!captionInput.value && hashtagStore.hashtagInput.length === 0) {
      return '';
    }
    return captionInput.value + hashtagStore.hashtagInput.join(' ');
  });

  const draftable = computed(() => {
    const postProductsStore = usePostProductsStore();
    return (
      !!processedImageURL.value ||
      !!videoFile.value ||
      !!captionHashtagsString.value ||
      postProductsStore.products.length > 0
    );
  });

  return {
    captionInput,
    ltkMap,
    ltkPostList,
    ltkPostRequest,
    selectedImage,
    mediaObjects,
    postId,
    profileAvatar,
    profileDisplayName,
    inProcessImageURL,
    meta,
    processedImageURL,
    selectedImageURL,
    createLtk,
    deleteLtkById,
    deleteMultipleLtks,
    fetchAllLtksByProfileId,
    getCaptionHashtagsString,
    captionHashtagsString,
    getLtkById,
    getProductsInput,
    savedProducts,
    setProducts,
    getMediaObjectById,
    publishLtk,
    $reset,
    setCaptionInput,
    setMediaObjects,
    setInProcessImage,
    setProcessedImage,
    setSelectedImage,
    setPublishLtkRequest,
    setUploadProgress,
    videoFile,
    selectedVideo,
    selectedVideoPoster,
    setVideo,
    setSelectedVideo,
    setVideoPoster,
    resetImage,
    resetVideo,
    setRawVideoFile,
    selectedVideoFileList,
    draftable,
    mediaObjectMap,
    uploadProgress,
    androidUploadData,
    updateVideo,
  };
});
