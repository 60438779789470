import { GlobalElementId } from './globalElementIds';

/**
 * @function
 * Utility for determining whether an element is visible in the DOM. Not exhaustive but should work for "virtual viewport" scrolling puposes.
 * @param element
 * @returns {boolean} Whether the element is visible
 */
function isElementVisible(element: HTMLElement | null): element is HTMLElement {
  if (!element) return false;

  const elemStyles = getComputedStyle(element);

  if (elemStyles.display === 'none') return false;
  if (elemStyles.visibility === 'hidden') return false;

  return true;
}

/**
 * @function
 * @description Context-aware `window.scrollBy()` utility for handling viewport shifts introduced by non-statically positioned page elements such as our fixed header. Should be used to "scroll under" our "virtual viewport" towards an element (target) on the page instead of using the built-in scroll APIs in most cases.
 * @param element - valid CSS selector or HTMLElement reference for the scroll target.
 * @param options - {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTo ScrollToOptions} object
 */
export function scrollUnderBy(
  element: string | Element,
  options: ScrollToOptions = { top: 0, left: 0, behavior: 'smooth' },
): void {
  let targetElem: Element;
  options.top = options.top ?? 0;

  if (typeof element === 'string') {
    const selectedElem = document.querySelector(element);
    if (!selectedElem) throw new Error('Invalid element selector');
    targetElem = selectedElem;
  } else {
    targetElem = element;
  }

  const targetElemRect: DOMRect = targetElem.getBoundingClientRect();
  const fixedHeaderElem = document.getElementById(GlobalElementId.SiteHeader);

  if (isElementVisible(fixedHeaderElem)) {
    const fixedHeaderRect: DOMRect = fixedHeaderElem.getBoundingClientRect();
    options.top += targetElemRect.top - fixedHeaderRect.height;
  } else {
    options.top += targetElemRect.top;
  }

  // Use scrollBy due to DOMRect dimensions being relative to the current position of the viewport
  window.scrollBy(options);
}
