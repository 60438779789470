import { S3Client, PutObjectCommand, Progress } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { _dataUriToBlob, _dataUriToFile } from '../utils/imageConversion';
import { usePublishStore } from '@/store/publish';
import { useMediaObjectsStore } from '@/store/mediaObjects';
import { UploadProgress } from '@/types/ltks';

class S3Api {
  public uploadImageS3Client = async (
    client: S3Client,
    bucket: string,
    mediaDataUri: string,
    uploadKey: string,
    webUpload?: { isWebUpload: boolean; ltkId: string },
  ) => {
    try {
      const imageFile = _dataUriToBlob(mediaDataUri);
      if (imageFile) {
        const command = new PutObjectCommand({
          Bucket: bucket,
          Key: uploadKey,
          Body: imageFile,
        });

        const response = await client.send(command);
        if (webUpload && webUpload.isWebUpload && response.$metadata.httpStatusCode === 200) {
          const mediaObjectStore = useMediaObjectsStore();
          mediaObjectStore.setMediaObjectProgress(webUpload.ltkId, { loaded: 1, total: 1, part: 1 });
        }
        return response;
      }
    } catch (e) {
      throw new Error('Upload failed');
    }
  };

  public uploadVideoS3ClientMultipart = async (
    client: S3Client,
    bucket: string,
    videoFile: File,
    uploadKey: string,
    webUpload?: { isWebUpload: boolean; ltkId: string },
  ) => {
    try {
      const parallelUploads3 = new Upload({
        client,
        params: { Bucket: bucket, Key: uploadKey, Body: videoFile },
        queueSize: 4, // optional concurrency configuration
        partSize: 1024 * 1024 * 5, // optional size of each part, in bytes, at least 5MB
        leavePartsOnError: false, // optional manually handle dropped parts
      });
      parallelUploads3.on('httpUploadProgress', (progress) => {
        if (webUpload && webUpload.isWebUpload) {
          const mediaObjectStore = useMediaObjectsStore();

          mediaObjectStore.setMediaObjectProgress(webUpload.ltkId, progress as UploadProgress);
        } else {
          const publistStore = usePublishStore();
          if (progress.loaded && progress.total) {
            publistStore.setUploadProgress(progress.loaded / progress.total);
          }
        }
      });

      const response = await parallelUploads3.done();
      return response;
    } catch (e) {
      throw new Error('Upload failed');
    }
  };
}

export default new S3Api();
