import { AccountStoreState } from '@/store/accounts/types';
import { ProfilesStoreState } from '@/store/profiles/types';
import { computed } from 'vue';

export function useAboutYouContent(accounts: Readonly<AccountStoreState>, profiles: Readonly<ProfilesStoreState>) {
  const isOwner = computed(() => accounts.user?.is_owner);
  const isAdmin = computed(() => accounts?.isUserAdmin);
  const isUserFromUnitedStates = computed(() => accounts.user?.address.country === 'US');
  const hasProfile = computed(() => (profiles.profile?.id ? true : false));

  const hasAboutYouContent = computed(
    () => (isOwner.value || isAdmin.value) && isUserFromUnitedStates.value && hasProfile.value,
  );

  return {
    hasAboutYouContent,
  };
}
