export type ValidatorOptions = {
  errorMessage: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  dupeArray?: Array<string>;
};

export type Validator = (options: ValidatorOptions) => Rule;
export type SelectValidator = (options: ValidatorOptions) => SelectRule;
export type Rule = (value: string) => true | string;
export type SelectRule = (values: string[]) => true | string;

const hasValueTrim = (value: string) => value?.trim().length > 0;
const hasValue = (value: string) => value.length > 0;
const hasChecked = (value: string) => !!value;
const isValidationNotRequired = (options: ValidatorOptions, value: string) => !options?.required && !hasValue(value);

export const required: Validator = (options) => (value: string) => hasValueTrim(value) || options.errorMessage;
export const checkRequired: Validator = (options) => (value) => hasChecked(value) || options.errorMessage;
export const simpleRequired: Validator = (options) => (value: string) => hasValue(value) || options.errorMessage;

export const minLengthRequired: SelectValidator = (options) => (values: string[]) =>
  options.minLength && values.length >= options.minLength ? true : options.errorMessage;

export const maxLength: SelectValidator = (options) => (values: string[]) =>
  options.maxLength && values.length <= options.maxLength ? true : options.errorMessage;

export const email: Validator = (options) => (value: string) => {
  if (isValidationNotRequired(options, value)) return true;
  return (
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
      value,
    ) || options.errorMessage
  );
};

export const alpha: Validator = (options) => (value: string) => {
  return /^(0(\.\d+)?|1(\.0+)?)$/.test(value) || options.errorMessage;
};

import { RsForm } from '@rscollabs/rs-ui-library/src/components';

type RewardStyleForm = typeof RsForm;

export interface RsFormComponent extends RewardStyleForm {
  validate(): boolean;
}

export const validate = (form: RsFormComponent | null | undefined): boolean => {
  return !!form?.validate();
};
