import { datadogLogs } from '@datadog/browser-logs';

type logLevel = 'debug' | 'info' | 'log' | 'warn' | 'error';

type ConsoleWithLogLevel = {
  [key in logLevel]: (...args: any[]) => void;
} & Console;

/* eslint-disable no-console */
const customConsole: ConsoleWithLogLevel = {
  trace: console.trace.bind(console),
  debug: console.debug.bind(console),
  info: console.info.bind(console),
  log: console.log.bind(console),
  warn: console.warn.bind(console),
  error: console.error.bind(console),
} as ConsoleWithLogLevel;
/* eslint-disable no-console */

export default (level: logLevel, message: string, type: string, ...rest: any) => {
  if (process.env.NODE_ENV === 'production') {
    datadogLogs.logger[level](message, { type, ...rest });
    return;
  }

  customConsole[level](
    `%c[rsthecom]%c ${message}`,
    'background: #D1ECF2; color: #000; padding: 1px; border-radius: 3px 0 0 3px',
    'background: #E7F5F8; color: #000; padding: 1px; border-radius: 0 3px 3px 0',
    'background: transparent',
    type,
    ...rest,
  );
};
