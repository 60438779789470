
import { defineComponent, computed, ref, watch, onUnmounted } from 'vue';
import RsFocusTrap from '@/lib/components/RsModal/RsFocusTrap.vue';
import { scrollLock } from '@/lib/utils/scrollLock';
import { Portal } from '@linusborg/vue-simple-portal';
import { uniqueId } from '@/utils/uniqueId';

export default defineComponent({
  name: 'RsModal',
  components: { Portal, RsFocusTrap },
  emits: ['update:modelValue', 'closed'],
  props: {
    allowOverflow: {
      type: Boolean,
      default: false,
    },
    portalTo: {
      type: String,
      default: '#app',
    },
    size: {
      type: String,
      default: 'md',
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    allowOutsideClick: {
      type: Boolean,
      default: true,
    },
    allowEscClose: {
      type: Boolean,
      default: true,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    customZIndex: {
      type: Number,
      required: false,
    },
    containerStyleObject: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    ariaTitle: {
      type: String,
      default: '',
      required: false,
    },
    ariaDescription: {
      type: String,
      default: '',
      required: false,
    },
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  setup(props, { emit }) {
    const isOpen = ref(props.modelValue);
    const modalRef = ref<HTMLElement | null>(null);

    const classes = computed(() => {
      return {
        [`rs--modal--size-${props.size}`]: props.size,
        'has--padding': props.hasPadding,
        'allow-overflow': props.allowOverflow,
      };
    });

    const styleObj = props.customZIndex ? { 'z-index': props.customZIndex } : {};
    const styleObjOverlay = props.customZIndex ? { 'z-index': props.customZIndex + 1 } : {};
    const styleObjContain: any = props.customZIndex ? { 'z-index': props.customZIndex + 2 } : {};
    Object.keys(props.containerStyleObject).forEach((key) => {
      styleObjContain[key] = props.containerStyleObject[key];
    });

    watch(
      () => props.modelValue,
      (newValue) => {
        isOpen.value = newValue;
      },
    );

    const id = uniqueId();

    watch(
      isOpen,
      (newValue) => {
        emit('update:modelValue', newValue);
        scrollLock(id, newValue);
      },
      { immediate: true },
    );

    onUnmounted(() => {
      // Ensure that if this component is removed, the scroll lock is released.
      // Watcher may not fire a final time depending how how the card is removed
      scrollLock(id, false);
    });

    function closeModal() {
      isOpen.value = false;
      emit('closed');
    }

    function onEsc() {
      if (props.allowEscClose) {
        closeModal();
      }
    }

    function handleOutsideClick() {
      if (props.allowOutsideClick) {
        closeModal();
      }
    }

    return {
      modalRef,
      isOpen,
      classes,
      closeModal,
      onEsc,
      handleOutsideClick,
      styleObj,
      styleObjContain,
      styleObjOverlay,
    };
  },
});
