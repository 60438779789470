import { RouteConfig } from 'vue-router';
import { ERROR } from '@/utils/router/namedRoutes';
import { gettext } from '@/utils/fakeGetText';
import { Layout } from '@/layouts/types';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';

/*
  Documentation of using gettext without Vue: https://github.com/Polyconseil/vue-gettext#usage-of-translate-without-vue
  Note: Gives warning of no exported member
*/

const routes: RouteConfig[] = [
  {
    path: '/something-went-wrong',
    name: ERROR,
    beforeEnter: async (to, from, next) => {
      await validateRoute([], to, from, next);
    },
    meta: {
      auth: { required: true },
      title: {
        name: gettext('Something went wrong'),
        translate: true,
      },
      layout: Layout.APPLICATION,
    },
    component: () => import(/* webpackChunkName: "products" */ '../ErrorPage.vue'),
  },
];

export default routes;
