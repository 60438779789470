import uid from 'lodash.uniqueid';

declare const uniqueIdSymbol: unique symbol;
/**
 * Using some interesting TypeScript magic to create an ad-hoc string type for type checking unique ids generated with this util: https://stackoverflow.com/a/68693007.
 *
 * This gets around the issue of TypeScript's structural typing allowing string values on an empty interface that extends the String type.
 */
export type UniqueId = string & { [uniqueIdSymbol]: never };

/**
 * @function
 * @description Wraps lodash's `uniqueId` function with a special `UniqueId` return type for providing uniqueness guarantees for TypeScript types.
 * @param prefix - The value to prefix the ID with.
 * @returns `UniqueId` value (behaves and can be used exactly like a `String` because it is a `string`).
 */
export const uniqueId = (prefix?: string): UniqueId => {
  return uid(prefix) as UniqueId;
};
