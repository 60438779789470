
import { defineComponent } from 'vue';

import { Layout } from '@/layouts/types';

import SiteHeaderApplication from '@/components/SiteHeaderApplication.vue';
import ApplyToBeCreatorBanner from '@/components/ApplyToBeCreatorBanner.vue';

export default defineComponent({
  name: Layout.SIGNUP,
  components: {
    SiteHeaderApplication,
    ApplyToBeCreatorBanner,
  },
  setup(props, context) {},
});
