import { LegacyStatus } from '@rscollabs/rs-ui-auth';
import { NavigationGuardNext, Route } from 'vue-router';
import { auth } from '@/utils/auth';

// Extracted from rs-ui-library so it can be reused across session types
// without having to be redefined.
export const validateRoute = async function (
  blacklist: LegacyStatus[],
  to: Route,
  from: Route,
  next: NavigationGuardNext,
) {
  const authenticated = await auth.isAuthenticated();

  // validate login
  if (!authenticated) {
    return auth.login({
      options: {
        appState: {
          target: window.location.href,
        },
      },
      next: window.location.href,
    });
  }

  const idToken = await auth.getIdTokenClaims();
  const statusObj = idToken?.legacy_status;

  const blacklisted = blacklist.some((status) => statusObj?.[status]);

  if (blacklisted) return window.location.replace(window.rewardStyle.LEGACY_HOME_PAGE);

  next();
};
