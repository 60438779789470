export enum PostProductAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

/*
  Note: There is an unused third option supported by the backend, "opt-out".
*/
export enum PostMatchType {
  SIMILAR = 'similar',
  EXACT = 'exact',
}

export type PostProduct = {
  favorite_id: string; // Not used in iOS but needed to track which favorites have been added
  match_type: PostMatchType;
  position: number;
  image_url: string;
  action: PostProductAction;
  link_url: string;
  product_reference_id: string; //Product retailer ID
};

export enum PostType {
  PUBLISHED = 'published',
  SCHEDULED = 'scheduled',
  DRAFT = 'draft',
}
