import log from '../utils/logger';

declare const window: Window &
  typeof globalThis & {
    webViewHandler: any;
  };

const webview = window.webViewHandler;

const appMessaging = () => {
  const checkAndroid = (callback: Function) => {
    const ua = navigator.userAgent;

    if (/^LTK Consumer\/.* Android\//i.test(ua)) {
      return callback();
    }
  };

  const receiveMessage = (callback: Function, target: Window = window) => {
    target.addEventListener('message', (event: MessageEvent) => {
      callback(event);
    });
  };

  const sendMessage = (type: string, payload?: any, target: Window = window) => {
    target?.postMessage({ type, payload }, '*');
  };

  const webViewSendMessage = async (type: string, payload: any = undefined) => {
    log('debug', 'Send webview message', type, payload);
    if (!webview) return;
    const stringifyObj = JSON.stringify({ data: { type, payload } });
    checkAndroid(() => window.webViewHandler.postMessage(stringifyObj));
  };

  return { webViewSendMessage, sendMessage, receiveMessage };
};

export { appMessaging };
