
import { PropType, computed, defineComponent } from 'vue';
import { Portal } from '@linusborg/vue-simple-portal';
import type { Placement } from '@popperjs/core';
import usePopper from '../../composables/usePopper';

export default defineComponent({
  name: 'RsTip',
  components: { Portal },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: [Number],
      default: 100,
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'top',
    },
    auto: {
      type: Boolean,
      default: true,
    },
    offset: {
      type: Array as PropType<number[]>,
      default: () => [0, 8],
    },
    target: {
      type: String,
      default: '#tooltip-portal',
    },
    portalDisabled: {
      type: Boolean,
      default: false,
    },
    focusable: {
      type: Boolean,
      default: false,
    },
    outsideClick: {
      type: Boolean,
      default: true,
    },
    escapeClose: {
      type: Boolean,
      default: true,
    },
    hideArrow: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'opened', 'closed'],
  setup(props, { emit }) {
    const themeClass = computed(() => props.theme && `is--${props.theme}`);
    const { shouldRender, targetRef, contentRef } = usePopper(props, emit, 'tooltip');

    return {
      targetRef,
      contentRef,
      themeClass,
      shouldRender,
    };
  },
});
