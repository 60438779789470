import { RouteConfig } from 'vue-router';
import { RS_SOCIAL_INSTAGRAM } from '@/utils/router/namedRoutes';
import { gettext } from '@/utils/fakeGetText';
import { Layout } from '@/layouts/types';
import { ExperimentName, ExperimentType } from '@/types/experiments';

const routes: RouteConfig[] = [
  {
    path: '/social/instagram/:redirectPath',
    name: RS_SOCIAL_INSTAGRAM,
    component: () => import(/* webpackChunkName: "rs-social" */ '@/rs-social/views/instagram/SocialInstagram.vue'),
    meta: {
      auth: {
        required: false,
      },
      title: {
        name: gettext('Connect with Instagram'),
        translate: true,
      },
      layout: Layout.EMPTY,
    },
  },
  {
    path: '/social/test/instagram',
    component: () => import(/* webpackChunkName: "rs-social" */ '@/rs-social/views/instagram/TestSandboxInstagram.vue'),
    meta: {
      title: {
        name: 'Instagram Test Area',
        translate: false,
      },
      experiment: {
        name: ExperimentName.INSTAGRAM_SOCIAL_INTEGRATION_TEST_SANDBOX,
        type: ExperimentType.FLAG,
        allowed: ['on'],
      },
    },
  },
];

export default routes;
