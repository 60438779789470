import { Folder } from '@/store/folders/types';
import { Favorite, MultipleFavoriteResponse, SingleFavoriteResponse } from '@/store/favorites/types';
import { processResponse, Api } from '@/utils/api';
import { FavoriteSortOptions } from '@/store/favorites/constants';

export interface SearchParams {
  folderIds?: string[];
  query: string;
  limit?: number;
  offset?: number;
  beforeId?: string | null;
  sort?: FavoriteSortOptions | null;
}

export interface SortParams {
  folderIds: string[];
  limit?: number;
  sort: FavoriteSortOptions;
}

const config = window.rewardStyle;

class FavoritesApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/pub/v1/`;

  public async fetchFolderRecentFavorites(folders: Folder[]) {
    const favoriteIdsParams = folders
      .filter((folder) => folder.most_recent_favorite_id !== null)
      .map((folder) => `ids[]=${folder.most_recent_favorite_id}`)
      .join('&');

    if (favoriteIdsParams.length === 0) {
      return;
    }

    const response = await this.sendRequest('GET', `favorites/?${favoriteIdsParams}`);
    return await processResponse<MultipleFavoriteResponse>(response);
  }

  public async fetchMultipleUserRecentFavorites({ limit, beforeId }: { limit: number; beforeId?: string | null }) {
    const params = new URLSearchParams();
    if (limit) {
      params.set('limit', limit.toString());
    }
    if (beforeId) {
      params.set('before_id', beforeId);
    }

    const response = await this.sendRequest('GET', `favorites/?${params.toString()}`);
    return await processResponse<MultipleFavoriteResponse>(response);
  }

  public async get(productId: string) {
    const response = await this.sendRequest('GET', `favorites/?product_ids[]=${productId}`);

    return await processResponse<MultipleFavoriteResponse>(response);
  }

  public async create({ productId, folderIds }: { productId: string; folderIds: string[] }) {
    const body = JSON.stringify({ product_id: productId, folder_ids: folderIds });
    const response = await this.sendRequest('POST', 'favorites/', { body });

    return await processResponse<SingleFavoriteResponse>(response);
  }

  public async update(favorite: Favorite) {
    const body = JSON.stringify({ id: favorite.id, folder_ids: favorite.folder_ids });
    const response = await this.sendRequest('PATCH', 'favorites/', { body });

    /**
     * Might be a good idea to calculate the folders that need to be updated here and refresh them as well.
     * This is to better support the "most_recent_favorite_id" and preview image functionality. A temporary
     * stop gap would be to reimplement the server functionality on the front end to avoid making API calls
     * to refresh data, but it's probably better just to update the data from the source fo truth.
     */

    return await processResponse<SingleFavoriteResponse>(response);
  }

  public async delete(favoriteId: string) {
    const response = await this.sendRequest('DELETE', `favorites/?ids[]=${favoriteId}`);

    if (response.status !== 204) {
      return await processResponse<{}>(response);
    }

    return true;
  }

  public async search({ query, folderIds, limit = 30, offset = 0, beforeId, sort }: SearchParams) {
    const params = new URLSearchParams();
    if (query) {
      params.set('query', query);
    } else {
      params.set('query', '');
    }
    if (folderIds) {
      folderIds.forEach((id) => params.append('folder_ids[]', id));
    }
    params.set('offset', offset.toString());
    if (beforeId) {
      params.set('beforeId', beforeId);
    }
    if (sort) {
      params.set('sort', sort);
    }

    params.set('limit', limit.toString());

    const response = await this.sendRequest('GET', `favorites/search/?${params.toString()}`);
    const results = await processResponse<MultipleFavoriteResponse>(response);

    return results;
  }

  public async sort({ folderIds, limit = 30, sort }: SortParams) {
    const params = new URLSearchParams();
    params.set('query', '');
    folderIds.forEach((id) => params.append('folder_ids[]', id));
    params.set('sort', sort);
    params.set('limit', limit.toString());

    const response = await this.sendRequest('GET', `favorites/search/?${params.toString()}`);
    const results = await processResponse<MultipleFavoriteResponse>(response);

    return results;
  }
}

export default new FavoritesApi();
