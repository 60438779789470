import {
  ExtractEventData,
  Nullable,
  ExtractedExtendedEventData,
  UserEvent,
  UserEventType,
  UserPropertyParams,
} from '@/utils/UserEvents/types';

export abstract class UserEventsSubscriber {
  abstract sendEvent<T extends UserEventType>(
    type: T,
    data?: Nullable<ExtractEventData<UserEvent, T>>,
    additionalData?: Partial<ExtractedExtendedEventData<UserEvent, T>>,
  ): Promise<void | null>;

  abstract ping(): Promise<Response>;

  addUserAttributes(attributes: Partial<UserPropertyParams>): Promise<void> {
    return Promise.resolve();
  }
}
