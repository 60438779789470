import { NavigationGuard } from 'vue-router';

import { getHackyPinia } from '@/utils/getHackyPinia';
import { ExperimentMeta } from '@/types/experiments';
import { NOT_FOUND } from '@/utils/router/namedRoutes';
import { useExperimentsStore } from '@/store/experiments';
import { hasExperimentMeta } from '@/utils/router/metaRouteGuard';

export const checkExperiments: NavigationGuard = async (to, from, next) => {
  const pinia = await getHackyPinia();

  const { useExperiment } = useExperimentsStore(pinia);

  // Check if the navigation contains a valid instance of ExperimentMeta, if not we can continue the navigation without checking Variant Values.
  if (!hasExperimentMeta(to.meta)) {
    return next();
  }

  const { name, type, control, allowed }: ExperimentMeta = to.meta.experiment;
  const { variant, isVariant } = useExperiment(name, type);

  const isValidOn = isVariant('on').value;
  const isValidExperimentOrFF = variant.value.value && allowed.includes(variant.value.value);

  if (!(isValidOn || isValidExperimentOrFF)) {
    if (control) {
      /**
       * To allow redirecting out of our Vue app control can be either:
       * - a string to be used as a url
       * - a Vue Router Location
       */
      if (typeof control === 'string') {
        return (window.location.href = control);
      } else {
        return next(control);
      }
    } else {
      return next({ name: NOT_FOUND });
    }
  }

  return next();
};
