import { NavigationGuard } from 'vue-router';
import ExchangeRate from '@/store/exchangeRate';

export const fetchExchangeRates: NavigationGuard = async function (to, from, next) {
  // Exchange rate is evaluated on a per route basis (false by default)
  const useExchangeRate: boolean | undefined = to.meta?.useExchangeRate;

  // Fetch data (once) for exchange rates on a per route basis
  if (useExchangeRate) {
    await ExchangeRate.fetchExchangeRates({});
  }

  return next();
};
