import { useTitle } from '@vueuse/core';

export interface TitleMeta {
  name: string | (() => string);
  translate: boolean;
}

// TODO: Determine whether the default title needs to be annotated and used with $gettext for localization
export const getDefaultTitle = () => 'LTK Creator';

export const usePageTitle = useTitle;
