
import { defineComponent, getCurrentInstance, PropType } from 'vue';
import { useRoute } from 'vue-router/composables';

type DownloadIcons = 'ALL' | 'ANDROID' | 'IOS' | 'NONE';

export default defineComponent({
  name: 'DownloadBadges',
  props: {
    display: {
      type: String as PropType<DownloadIcons>,
      required: false,
      default: 'ALL',
    },
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { $userEvents } = vm?.proxy as Vue;

    const $route = useRoute();

    const onClickPlayStore = () => {
      $userEvents.sendEvent('/new-account-setup/download-android', {
        page: $route.path,
        referrer: $route.name || null,
        'root-referrer': null,
      });
    };

    const onClickAppStore = () => {
      $userEvents.sendEvent('/new-account-setup/download-ios', {
        page: $route.path ?? '',
        referrer: $route.name || null,
        'root-referrer': null,
      });
    };

    return {
      onClickAppStore,
      onClickPlayStore,
    };
  },
});
