
import { PropType, Ref, onMounted, computed, defineComponent, ref, watch, watchEffect } from 'vue';
import type { Placement } from '@popperjs/core';
import usePopper from '../../composables/usePopper';
import { Portal } from '@linusborg/vue-simple-portal';
import { RwFocusTrap } from '../RwFocusTrap';
import containsElement from '../../utils/contains-element';
import uuid from '../../utils/uuid';
import { useElementHover } from '@vueuse/core';

export default defineComponent({
  name: 'RsPopover',
  components: { Portal, RwFocusTrap },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    id: {
      type: String,
      default: () => `rs-popover-${uuid()}`,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 100,
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'auto',
    },
    auto: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Array as PropType<number[]>,
      default: () => [0, 24],
    },
    target: {
      type: String,
      default: '#modal-portal',
    },
    portalDisabled: {
      type: Boolean,
      default: false,
    },
    focusable: {
      type: Boolean,
      default: false,
    },
    outsideClick: {
      type: Boolean,
      default: true,
    },
    escapeClose: {
      type: Boolean,
      default: true,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String as PropType<'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent' | 'unset'>,
      default: 'unset',
    },
    width: {
      type: String,
      default: '355px',
    },
  },
  emits: ['update:modelValue', 'opened', 'closed', 'hovering'],
  setup(props, { emit, slots }) {
    const { shouldRender, targetRef, contentRef } = usePopper(props, emit, 'popover');
    const wrapperRef = ref(null) as Ref<HTMLElement | null>;
    const hoveringPopover = useElementHover(contentRef);
    const lastFocusedElement = ref(null) as Ref<any | null>;
    const hasSlot = (name: string) => !!slots[name];

    watch(
      () => shouldRender.value,
      () => {
        if (shouldRender.value) emit('opened');
        else emit('closed');
      },
    );

    watch(
      () => hoveringPopover.value,
      (next) => {
        emit('hovering', next);
      },
    );

    const themeClass = computed(() => {
      const theme = `rs--popover__${props.theme}`;
      return {
        [theme]: props.theme,
        'is--dark': props.dark,
      };
    });

    const computedStyles = computed(() => {
      return {
        textAlign: props.textAlign,
      };
    });

    const computedContentStyles = computed(() => {
      return {
        maxWidth: props.width,
      };
    });

    const isTrigger = (event: Event) => {
      if (slots.default) {
        const trigger = slots.default()[0].elm as any;
        return trigger ? containsElement(trigger, event.target as Node) : false;
      }
    };

    const isPopover = (event: Event) => {
      return wrapperRef.value ? containsElement(wrapperRef.value, event.target as Node) : false;
    };

    const isPopoverContent = (event: Event) => {
      return contentRef.value ? containsElement(contentRef.value, event.target as Node) : false;
    };

    const onKeydown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          e.stopPropagation();
          shouldRender.value = false;
          emit('update:modelValue', false);
          break;
      }
    };

    const handleClickEvents = (event: Event) => {
      if (document && shouldRender.value) {
        const insideClick = isPopover(event) || isPopoverContent(event) || isTrigger(event);
        const outsideClick = !isPopover(event) && !isPopoverContent(event);
        if (!insideClick || outsideClick) {
          event.stopPropagation();
          shouldRender.value = false;
          emit('update:modelValue', false);
          document.body.removeEventListener('mouseup', handleClickEvents);
          document.body.removeEventListener('keyup', onKeydown);
        }
      }
    };

    const createKeyboardObserver = () => {
      if (props.escapeClose) document.body.addEventListener('keyup', onKeydown, { passive: false });
    };

    const createClickObserver = () => {
      if (props.outsideClick) document.body.addEventListener('mouseup', handleClickEvents, { passive: true });
    };

    onMounted(() => {
      if (slots.default) {
        const trigger = slots.default()[0].elm as HTMLElement | null;
        trigger?.setAttribute('aria-controls', props.id);
      }
    });

    watchEffect(
      () => {
        if (shouldRender.value) lastFocusedElement.value = document.activeElement;

        if (shouldRender.value && wrapperRef.value instanceof HTMLElement) {
          createClickObserver();
          createKeyboardObserver();
        }
      },
      { flush: 'post' },
    );

    return {
      shouldRender,
      targetRef,
      contentRef,
      themeClass,
      wrapperRef,
      lastFocusedElement,
      hasSlot,
      computedStyles,
      computedContentStyles,
    };
  },
});
