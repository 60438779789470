import { NavigationGuard } from 'vue-router';
import { auth } from '@/utils/auth';
import { VERIFY_EMAIL } from '@/utils/router/namedRoutes';
import { useExperimentsStore } from '@/store/experiments';
import { ExperimentName, ExperimentType } from '@/types/experiments';

/**
 * Ensures that users who have not had their email verified yet must be redirected
 * to a page that allows them to do so
 *
 * @param to
 * @param _
 * @param next
 */
export const checkEmailVerification: NavigationGuard = async function (to, _, next) {
  if (!(await auth.isAuthenticated())) {
    return next();
  }

  const { useExperiment } = useExperimentsStore();
  const { isVariant } = useExperiment(ExperimentName.APPLICATION_SIGNUP_LOGIN, ExperimentType.FLAG);
  const isAslExperimentEnabled = isVariant('on');

  // Skip if ASL feature is not enabled
  if (!isAslExperimentEnabled.value) {
    return next();
  }

  const idTokenClaims = await auth.getIdTokenClaims();
  const shouldVerifyEmail = idTokenClaims?.email_verified === false;

  if (shouldVerifyEmail) {
    // Avoid an infinite loop by checking if the user is already on the VERIFY_EMAIL Page
    return to.name === VERIFY_EMAIL ? next() : next({ name: VERIFY_EMAIL });
  }

  return next();
};
