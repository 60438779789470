
import { computed, defineComponent, ref, ComputedRef, Ref } from 'vue';
import useKeyboardFocus from '../../composables/useKeyboardFocus';
import { uuid } from '../../utils';

export default defineComponent({
  name: 'RsButton',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: () => `rs-button-${uuid()}` as String,
    },
    href: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    full: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: [Boolean, String],
      default: false,
    },
    tab: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    lowercase: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const buttonRef = ref(null) as Ref<HTMLElement | null>;
    const hasFocus = useKeyboardFocus(buttonRef);

    const rootClass = computed(() => {
      return props.tab ? 'rs--tab-button' : 'rs--button';
    }) as ComputedRef<string>;

    const themeSelector = computed(() => {
      if (props.theme === 'default') return props.primary ? 'primary' : 'default';
      else return props.theme;
    }) as ComputedRef<string>;

    const themeClass = computed(() => {
      return `${rootClass.value}__${themeSelector.value}`;
    }) as ComputedRef<string>;

    const classes = computed(() => {
      return {
        [`is--${props.size}`]: props.size,
        'is--dark': props.dark,
        'is--focused': hasFocus.value,
        'is--disabled': props.disabled,
        'is--active': props.active,
        'is--full': props.full,
        'is--rounded': typeof props.rounded === 'boolean' && props.rounded === true,
        'is--rounded--start': props.rounded === 'start',
        'is--rounded--end': props.rounded === 'end',
        'is--icon-only': props.iconOnly,
        'is--lowercase': props.lowercase,
        'is--flat': props.flat,
      };
    });

    const elementTag = computed(() => {
      return props.href ? 'a' : 'button';
    }) as ComputedRef<string>;

    return {
      buttonRef,
      classes,
      elementTag,
      rootClass,
      themeClass,
    };
  },
  // TODO: [VUE3] This will have to be updated to use the new render() api.
  render(createElement) {
    const self: any = this;

    return createElement(
      self.elementTag as string,
      {
        ref: 'buttonRef',
        class: [self.rootClass, self.themeClass, self.classes],
        attrs: {
          id: self.id,
          disabled: self.disabled,
          href: self.href,
          ...self.$attrs,
        },
        on: {
          ...self.$listeners,
        },
      },
      [
        createElement(
          'div',
          {
            class: self.rootClass + '__content',
          },
          [self.$slots.default],
        ),
      ],
    );
  },
});
