
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'RwChip',
  props: {
    theme: {
      type: String,
      default: 'default',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    focusable: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleClick = (e: Event) => {
      emit('click', e);
    };
    const classes = computed(() => {
      return {
        [`rw--chip__${props.theme}`]: props.theme,
        'is--dark': props.dark,
        'is--focusable': props.focusable,
        'is--clickable': props.clickable,
        'is--dense': props.dense,
        'rw--chip__primary': props.primary,
        'rw--chip__info': props.info,
        'rw--chip__success': props.success,
        'rw--chip__warning': props.warning,
        'rw--chip__danger': props.danger,
      };
    });
    return { classes, handleClick };
  },
});
