import { processResponse, Api } from '@/utils/api';

const config = window.rewardStyle;

class SigilApi extends Api {
  public apiBaseUrl = `${config.SIGIL_URL}/api/`;

  public async changePasswordFor(email: string) {
    const data = {
      email,
    };

    const response = await this.sendRequest('POST', `password/request/rs/`, {
      body: JSON.stringify(data),
    });

    return processResponse(response);
  }

  public async createPasswordForContributor(email: string) {
    const data = {
      email,
      type: 1,
    };

    const response = await this.sendRequest('POST', `password/create/`, {
      body: JSON.stringify(data),
    });

    return processResponse(response);
  }

  public async changeUserEmail(email: string) {
    const data = {
      email,
    };

    const response = await this.sendRequest('PUT', `users/me/`, {
      body: JSON.stringify(data),
    });

    return processResponse(response);
  }
}

export default new SigilApi();
