
import { PropType, computed, defineComponent } from 'vue';
import { StyleValue } from 'vue/types/jsx';

const ROUNDED_DEFAULT = [0, 0, 0, 0] as const;

export default defineComponent({
  name: 'RwModalBody',
  props: {
    padded: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: [Boolean, Array] as PropType<boolean | number[] | typeof ROUNDED_DEFAULT>,
      default: () => ROUNDED_DEFAULT,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return { 'is--padded': props.padded };
    });
    const computedStyles = computed((): StyleValue => {
      if (typeof props.rounded === 'boolean') {
        return {
          borderRadius: '6px',
        };
      } else if (props.rounded === ROUNDED_DEFAULT) {
        return {
          borderRadius: 'false',
        };
      }

      return {
        borderTopLeftRadius: `${props.rounded[0]}px`,
        borderTopRightRadius: `${props.rounded[1]}px`,
        borderBottomRightRadius: `${props.rounded[2]}px`,
        borderBottomLeftRadius: `${props.rounded[3]}px`,
      };
    });

    return {
      classes,
      computedStyles,
    };
  },
});
