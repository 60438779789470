import { Api, processResponse } from '@/utils/api';
import { PsResponse } from '@/types/hashtags';
import { useExperimentsStore } from '@/store/experiments';
import { ExperimentName, ExperimentType } from '@/types/experiments';

const config = window.rewardStyle;

class PresentationServiceApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/shopping/v1/presentation/modules`;

  public getHashtags = async (body: any, flags: string) => {
    const experimentStore = useExperimentsStore();
    const isPreStaged = experimentStore
      .useExperiment(ExperimentName.HASHTAGS_PRESTAGED, ExperimentType.VARIANT)
      .isVariant('treatment1')
      .value.toString();

    const headers = {
      'Content-Type': 'application/json',
      'x-ltk-prestaged': isPreStaged,
      'x-ltk-feature-flags': flags,
    };
    const response = await this.sendRequest('POST', '/v1_hashtags', {
      body: JSON.stringify(body),
      headers: headers,
    });
    return processResponse<PsResponse>(response);
  };
}

export default new PresentationServiceApi();
