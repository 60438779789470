
import { defineComponent, computed, getCurrentInstance } from 'vue';
import { getNativeLanguage } from '@/plugins/gettext';
import useLinks from '@/composables/useLinks';

export default defineComponent({
  name: 'GlobalLanguageSelectLink',
  emits: ['click'],
  props: {
    href: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
    color: {
      type: String,
      default: 'white',
    },
  },
  setup(props, context) {
    const vm = getCurrentInstance();
    const { $language } = vm?.proxy as Vue;

    const { siteLinks } = useLinks($language);

    // Set a safe dynamic default value for the href
    const link = computed(() => props.href ?? siteLinks.value.changeLanguage);

    const displayLanguage = computed(() => getNativeLanguage($language.current as Locales));

    return { link, displayLanguage };
  },
});
