export enum UserRoles {
  ADMIN = 'ADMIN',
  PRODUCT_SEARCH_ONLY = 'PRODUCT_SEARCH_ONLY',
  PRODUCT_SEARCH_ONLY_NO_COMMISSIONS = 'PRODUCT_SEARCH_ONLY_NO_COMMISSIONS',
}

export enum UserLocale {
  en_US = 'en_US',
  en_GB = 'en_GB',
  ko_KR = 'ko_KR',
  zh_CN = 'zh_CN',
  zh_TW = 'zh_TW',
  pt_BR = 'pt_BR',
  fr_FR = 'fr_FR',
  it_IT = 'it_IT',
  de_DE = 'de_DE',
  es_ES = 'es_ES',
  es_419 = 'es_419',
  nl_NL = 'nl_NL',
  ja_JP = 'ja_JP',
  ru_RU = 'ru_RU',
  sv_SE = 'sv_SE',
  nb_NO = 'nb_NO',
}
