
import { defineComponent, reactive, ref, watch, PropType } from 'vue';

type ValidationRule = (value: any) => any;

export default defineComponent({
  name: 'RsCheckbox',
  emits: {
    input: (v: boolean) => true,
    change: (v: string) => true,
    click: (v: string) => true,
    error: (v: Object) => true,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    dataTestid: {
      type: String,
      required: false,
      default: 'check-box',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array as () => ValidationRule[],
      default: () => [],
    },
  },
  setup(props, { emit, slots }) {
    const errorMessage = ref<string>('');
    const state = reactive({
      isChecked: props.value,
      isDirty: false,
      isError: false,
    });

    const validate = (force: boolean = false): boolean => {
      if (!force && !state.isDirty) {
        return true;
      }
      // reset dirty state
      state.isDirty = false;

      for (let x = 0, { length } = props.rules; x < length; x++) {
        errorMessage.value = props.rules[x](state.isChecked);
        if (typeof errorMessage.value === 'string' && errorMessage.value !== 'true') {
          const error = {
            fieldValue: state.isChecked,
            index: x,
            errorMessage: errorMessage.value,
          };
          emit('error', error);
          return false;
        }
      }

      return true;
    };

    const onChecked = (event: Event) => {
      const newValue = (event.target as HTMLInputElement).value;
      state.isDirty = true;
      state.isChecked = !state.isChecked;

      emit('input', state.isChecked);
      emit('change', newValue);
      emit('click', newValue);

      if (props.required && state.isDirty && !state.isChecked) {
        state.isError = true;
        validate();
      } else {
        state.isError = false;
      }
    };

    watch(
      () => props.value,
      (next) => {
        state.isChecked = next;
      },
    );

    return {
      onChecked,
      errorMessage,
      slots,
      state,
    };
  },
});
