import { RouteConfig } from 'vue-router';
import { ANALYTICS } from '@/utils/router/namedRoutes';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';

const routes: RouteConfig[] = [
  {
    path: '/analytics',
    name: ANALYTICS,
    beforeEnter: async (to, from, next) => {
      await validateRoute(['account_no_comm'], to, from, next);
    },
    component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue'),
  },
];

export default routes;
