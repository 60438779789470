import { CreatorIdToken } from '@rscollabs/rs-ui-auth';

const userStatus = {
  isAccountAdmin(idTokenClaims?: CreatorIdToken): boolean {
    if (idTokenClaims?.legacy_status && idTokenClaims.legacy_status.account_admin) {
      return idTokenClaims.legacy_status.account_admin;
    } else {
      return false;
    }
  },
  hasCommissionPermissions(idTokenClaims?: CreatorIdToken): boolean {
    return (
      this.isAccountAdmin(idTokenClaims) ||
      (idTokenClaims?.legacy_status !== undefined && !idTokenClaims?.legacy_status?.account_no_comm)
    );
  },
};

export default userStatus;
