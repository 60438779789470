import Vue, { VNode } from 'vue';

export default Vue.extend({
  name: 'slot-translate',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
  },
  render(h, context) {
    const slots = context.slots();
    const defaultSlot: VNode = slots.default[0];
    const sourceText =
      // Root level text: ""
      defaultSlot.text ??
      // First default child element: <span>""</span>
      defaultSlot.children?.[0]?.text ??
      // First default childComponent element: <translate>""</translate>
      defaultSlot.componentOptions?.children?.[0]?.text ??
      'Error detecting translation text';

    const translation = context.parent.$gettext(sourceText?.trim());

    // Allow for a configurable wrapper element
    const tag = context.props.tag;
    const children: any[] = [];

    // Matching format: %{ slotName }
    const slotRegex = RegExp('%{\\s*([^}]+)\\s*}', 'g');
    // Offset is the index of the translated string so far
    let offset = 0;
    let chunk: RegExpExecArray | null;
    while ((chunk = slotRegex.exec(translation)) !== null) {
      const text = translation.substring(offset, chunk.index);
      const slot = chunk[0].slice(2, -1).trim();

      // Add the text so far, and then the corresponding slot contents
      // Known bad practice to use private method. _v  creates a text node
      children.push((context as any)._v(text));
      children.push(slots[slot][0]);

      // Increase the offset by the length of the slot token
      offset = chunk.index + chunk[0].length;
    }

    // Append the remainder of the translation
    const restOfTranslation = translation.substring(offset);
    children.push((context as any)._v(restOfTranslation));

    return h(tag, { ...context.data }, children);
  },
});
