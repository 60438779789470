import { NavigationGuard } from 'vue-router';
import AccountsStore from '@/store/accounts';
import { auth } from '@/utils/auth';
import { UserStatus } from '@/utils/decodeUserStatus';
import { useExperimentsStore } from '@/store/experiments';
import { usePostApplicationStore } from '@/store/postApplication';
import { ExperimentName, ExperimentType } from '@/types/experiments';
import { APPLY_ABOUT_YOU, APPLY_STATUS, isAnyApplicationRoute, isLegalRoute } from '@/utils/router/namedRoutes';

export const checkApplicationStatus: NavigationGuard = async function (to, from, next) {
  // Legal pages: Must be accessible despite any and all (authorized) user states
  if (isLegalRoute(to)) {
    return next();
  }

  // Just skip if user not logged in
  if (!(await auth.isAuthenticated())) {
    return next();
  }

  const { useExperiment } = useExperimentsStore();
  const { isVariant } = useExperiment(ExperimentName.APPLICATION_SIGNUP_LOGIN, ExperimentType.FLAG);
  const isAslExperimentEnabled = isVariant('on');
  const idTokenClaims = await auth.getIdTokenClaims();

  const isNewUser = AccountsStore.userStatusRoles.includes(UserStatus.REGISTERED);
  // email_verified claim can be missing and we treat it as verified
  const isEmailVerified = idTokenClaims?.email_verified !== false && idTokenClaims?.email;

  // Only redirect to application pages in certain cases
  if (isAslExperimentEnabled.value && isNewUser && isEmailVerified) {
    const postApplicationStore = usePostApplicationStore();
    await postApplicationStore.getStatus({ email: idTokenClaims.email! });

    // Application found -> Status page
    if (postApplicationStore.status) {
      return to.name === APPLY_STATUS ? next() : next({ name: APPLY_STATUS });
    }

    // No application -> Application. Allow user to enter any page in the flow.
    return isAnyApplicationRoute(to) ? next() : next({ name: APPLY_ABOUT_YOU });
  }

  return next();
};
