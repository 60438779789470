import _Vue, { PluginObject } from 'vue';
import Snowplow from '@/utils/snowplow';

const { SNOWPLOW_SERVER_URL: server } = window.rewardStyle;

class SnowplowPlugin extends Snowplow implements PluginObject<{}> {
  public install(Vue: typeof _Vue, options?: {}) {
    this.init();
    Vue.prototype.$snowplow = this;
  }
}

/**
 * `SnowplowPlugin` singleton for use outside of the Vue instance.
 */
export const snowplow: SnowplowPlugin = new SnowplowPlugin({
  server,
  trackerConfig: {
    appId: `rsthecom-v2-${process.env.ENV ?? 'local'}`,
    platform: 'web',
    cookieName: '_sp_',
    cookieDomain: undefined,
    cookieSecure: true,
    cookieSameSite: 'Lax',
    discoverRootDomain: true,
    encodeBase64: true,
    eventMethod: 'post', // use POST as primary method of delivery
    bufferSize: 1, // send events immediately, do not buffer
    respectDoNotTrack: false,
    sessionCookieTimeout: 3600, // 30 minutes
    cookieLifetime: 2678400, // 30 days
    stateStorageStrategy: 'cookieAndLocalStorage',
    connectionTimeout: 5000, // 5 seconds
    anonymousTracking: false,
    contexts: {
      webPage: true,
      session: true,
      browser: true,
    },
    retryStatusCodes: [],
    dontRetryStatusCodes: [],
  },
});

/**
 * Vue helper function for registering the `SnowplowPlugin`.
 */
export const registerSnowplow = (Vue: typeof _Vue) => {
  Vue.use(snowplow);
};

declare module 'vue/types/vue' {
  interface Vue {
    $snowplow: Snowplow;
  }
}
