import { Ref, computed, watch, reactive } from 'vue';
import { Language } from '@/rs-application/composables/useExperienceQuestions';

interface UseSmsOptInFeatureParams {
  phoneField: Readonly<Ref<RsPhoneFieldState>>;
  $language: Language;
  $gettext: Vue['$gettext'];
}

export type RsPhoneFieldState = {
  country: IntlTelInputIso2CountryCode;
  format: RsPhoneFieldFormatEvent;
};

const smsWhitelist = ['us'];
const whatsAppWhitelist = ['gb'];

export const useSmsOptInFeature = ({ phoneField, $language, $gettext }: UseSmsOptInFeatureParams) => {
  const optInCheckBoxContent = reactive({
    application: {
      label: '',
      description: '',
    },
    other: {
      label: '',
      description: '',
    },
  });

  const showOptInCheck = (whitelist: string[]) => {
    const {
      format: { isE164ValueValid, countryCode },
    } = phoneField.value;

    // Phone number must be valid
    if (!isE164ValueValid) return false;
    // Phone country code has to be on the whitelist
    else if (!whitelist.includes(countryCode.toLowerCase())) return false;

    return true;
  };

  const showOptInSmsFeature = computed(() => {
    return showOptInCheck(smsWhitelist);
  });

  const showOptInWhatsAppFeature = computed(() => {
    return showOptInCheck(whatsAppWhitelist);
  });

  const translations = computed(() => {
    const translations = {
      contact: {
        application: {
          label: $gettext(
            'Connect with the LTK Team! Text me important updates, exclusive offers, upcoming events, and more if my application is approved!',
          ),
          description: $gettext(
            'By checking the box, you agree to receive marketing text messages and updates sent by or on behalf of LTK. Messages may be from automated systems and frequency will vary. Consent does not impact your application. We will only text you if your application is approved. Message and data rates may apply. You can text STOP to cancel or text HELP for additional help. See the %{ ltkSMSTerms } and the %{ privacyPolicy } for more information.',
          ),
        },
        other: {
          label: $gettext(
            'Connect with the LTK Team! Text me important marketing updates, exclusive offers, upcoming events, and more!',
          ),
          description: $gettext(
            'By checking the box, you agree to receive marketing text messages and updates sent by or on behalf of LTK. Messages may be from automated systems, and frequency will vary. Message and data rates may apply. You can text STOP to cancel or text HELP for additional help. See the %{ ltkSMSTerms } and the %{ privacyPolicy } for more information.',
          ),
        },
      },
      whatsapp: {
        application: {
          label: $gettext(
            'Connect with the LTK Team! Send me important marketing updates, exclusive offers, upcoming events, and more if my application is approved!',
          ),
          description: $gettext(
            'By checking the box, you agree to receive marketing messages and updates sent by or on behalf of LTK using a third-party messaging platform. Consent does not impact your application. You’ll only receive messages from us if your application is approved. You can then manage your message preferences in your LTK Creator Profile. Our %{ privacyPolicy } describes how we process your personal information when we communicate with you for marketing purposes.',
          ),
        },
        other: {
          label: $gettext(
            'Connect with the LTK Team! Send me important marketing updates, exclusive offers, upcoming events, and more!',
          ),
          description: $gettext(
            'By checking the box, you agree to receive marketing messages and updates sent by or on behalf of LTK using a third-party messaging platform. You can manage your message preferences in your LTK Creator Profile. Our %{ privacyPolicy } describes how we process your personal information when we communicate with you for marketing purposes.',
          ),
        },
      },
    };

    if (showOptInSmsFeature.value) {
      return translations.contact;
    }

    return translations.whatsapp;
  });

  function updateOptInContent() {
    optInCheckBoxContent.application = translations.value.application;
    optInCheckBoxContent.other = translations.value.other;
  }

  watch(() => $language.current, updateOptInContent, { immediate: true });
  watch(() => phoneField.value.format.countryCode, updateOptInContent);

  return { showOptInSmsFeature, showOptInWhatsAppFeature, optInCheckBoxContent };
};
