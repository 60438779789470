import { Pinia, getActivePinia } from 'pinia';

/**
 * A humble riff on Pinia's `getActivePinia()`. Used for getting an active Pinia instance in hostile environments where the Vue 2.x instance may not have set up Pinia yet.
 *
 * Should be used in global router navigation guards until we move to Vue 3.x.
 *
 * It addresses the following production issues:
 *   - Pinia instance not being available for global nav guards in Vue Router: https://github.com/vuejs/pinia/discussions/723#discussioncomment-3432229
 *   - Vue Dev Tools bug when passing a Pinia instance to a `useStore()` call before `new Vue()` is executed: https://github.com/vuejs/pinia/issues/1630
 *
 * `getHackyPinia()` allows for "safe" Pinia store usage with a performance penalty of ~10 milliseconds. This could be shortened or improved in the future (i.e. with `setImmediate()`) with some device profiling.
 */
export function getHackyPinia({ timeout }: { timeout: number } = { timeout: 10 }): Promise<Pinia> {
  const checkPinia = (callback: (value: Pinia) => void): void => {
    const pinia: Pinia | undefined = getActivePinia();
    pinia
      ? callback(pinia)
      : setTimeout(() => {
          checkPinia(callback);
        }, timeout);
  };

  return new Promise((resolve) => {
    checkPinia(resolve);
  });
}
