
import { defineComponent, onMounted, provide, ref, onUnmounted } from 'vue';
import { useRouter } from 'vue-router/composables';

import { Route, NavigationGuardNext } from 'vue-router';
import { leavePagePopupLayoutInjector } from '@/config/vue-provide-inject-keys';

import LeavePagePopup from '@/components/LeavePagePopup.vue';

/**
 * @description Vue Router layout component for providing reusable Leave Page Popup functionality for nested views under the <router-view> component.
 * See https://v3.router.vuejs.org/guide/essentials/nested-routes.html#nested-routes for an example.
 *
 * Use with the useLeavePagePopupLayout composable for ease of use with the Composition API.
 */
export default defineComponent({
  name: 'LeavePagePopupLayout',
  components: { LeavePagePopup },
  setup(props, context) {
    const $router = useRouter();

    const enabled = ref(false);

    const showPopup = ref(false);
    const nextRoute = ref('');

    onMounted(function initLeavePagePopupLayout() {
      // Needed when a user is leaving the page to a non-rsthecom site or closing the tab, to check if there are changes in the form data.
      window.onbeforeunload = () => {
        return enabled.value ? false : undefined;
      };
    });

    onUnmounted(function teardownLeavePagePopupLayout() {
      window.onbeforeunload = null;
    });

    const enable = () => {
      return (enabled.value = true);
    };

    const disable = () => {
      return (enabled.value = false);
    };

    provide(leavePagePopupLayoutInjector, {
      enable,
      disable,
    });

    const handleLeave = async () => {
      enabled.value = false;
      $router.push(nextRoute.value);
    };

    /**
     * @description Public API
     */
    const beforeLeavePage = (path: string) => {
      if (enabled.value) {
        showPopup.value = true;
        nextRoute.value = path;
        return false;
      } else {
        showPopup.value = false;
        nextRoute.value = '';
        return true;
      }
    };

    return {
      enabled,
      showPopup,
      nextRoute,
      enable,
      disable,
      handleLeave,
      beforeLeavePage,
    };
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    // Using Vue Router hook outside of Composition API plugin for compatibility with Vue 2.x
    // Some console errors are to be expected: https://stackoverflow.com/a/65326844
    const canLeave = this.beforeLeavePage(to.path);
    if (!canLeave) return next(false);
    else next();
  },
});
