import { ApiErrorResponse, ApiErrorReturn } from './types';

export default class ApiError extends Error {
  public name = 'ApiError';
  public response: ApiErrorResponse;

  public constructor(error: ApiErrorResponse) {
    super(error.user_message);
    this.name = error.error_code;
    this.response = error;
  }

  public toString() {
    return JSON.stringify(this.response);
  }

  public static fromApiErrorReturn(payload: ApiErrorReturn | unknown): ApiError | null {
    if (payload instanceof Object) {
      if ('response' in payload) {
        const response = payload.response as ApiErrorResponse;
        return new ApiError(response);
      }
    }
    return null;
  }
}

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}
