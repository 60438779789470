import amplitude, { AmplitudeClient } from 'amplitude-js';
import userStatus from '@/utils/userStatus';
import { AmplitudeOptions } from './types';
import {
  IdentifyUserParams,
  UserEventType,
  ExtractEventData,
  UserEvent,
  UserPropertyParams,
  UserIdentityProperty,
} from '@/utils/UserEvents/types';
import { UserEventsSubscriber } from '../userEventSubscriber';
import { CreatorIdToken } from '@rscollabs/rs-ui-auth';

export class Amplitude extends UserEventsSubscriber {
  // Variable to let callers know if SDK is blocked or not
  private blocked: Promise<boolean>;

  constructor({ apiKey, userId, config }: AmplitudeOptions) {
    super();
    this.client.init(apiKey, userId, config);
    this.blocked = this.checkBlock();
  }

  get client(): AmplitudeClient {
    return amplitude.getInstance();
  }

  get isBlocked(): Promise<boolean> {
    return this.blocked;
  }

  private async checkBlock(): Promise<boolean> {
    try {
      await this.ping();
      // `Response` status is not important, what's important is that the network call was not blocked by an ad blocker.
      return false;
    } catch (error) {
      return true;
    }
  }

  /**
   * Pings the web domain used for all `amplitude-js` SDK functions to determine whether or not the client has access to the Amplitude APIs.
   *
   * This value is internally hardcoded in the npm package as `Constants.EVENT_LOG_URL` on the ESM build `node_modules/amplitude-js/amplitude.esm.js`.
   *
   * This is expected to remain constant save for major technical changes to the Amplitude organization itself.
   */
  public async ping(): Promise<Response> {
    return await fetch('https://api.amplitude.com');
  }

  public async sendEvent<T extends UserEventType>(type: T, data?: ExtractEventData<UserEvent, T>): Promise<void> {
    if (await this.blocked) {
      return;
    }

    await new Promise<void | string>((resolve, reject) => {
      this.client.logEvent(
        type,
        data,
        (responseCode, responseBody) => {
          if (responseCode === 200) {
            return resolve();
          }

          resolve(responseBody);
        },
        () => {
          // TODO: Add error handling logic for Amplitude event calls
        },
      );
    });
  }

  public async identifyUser({ userId }: IdentifyUserParams) {
    if (await this.blocked) {
      return;
    }

    this.client.setUserId(userId);
    const identity = new amplitude.Identify();
    await this.sendIdentification(identity);
  }

  public async addUserAttributes(attributes: Partial<UserPropertyParams>) {
    if (await this.blocked) {
      return;
    }

    const identity = new amplitude.Identify();

    // Aggregate all provided attributes
    Object.keys(attributes).forEach((key) => {
      const { isOnce, value } = attributes[key as UserIdentityProperty] ?? {};

      if (!value) {
        return;
      }

      if (!isOnce) {
        return identity.set(key, value);
      }

      return identity.setOnce(key, value);
    });

    await this.sendIdentification(identity);
  }

  private sendIdentification(identity: amplitude.Identify) {
    return new Promise<void | string>((resolve, reject) => {
      this.client.identify(
        identity,
        (responseCode, responseBody) => {
          if (responseCode === 200) {
            return resolve();
          }

          resolve(responseBody);
        },
        (responseCode, responseBody) => {
          // TODO: Add error handling logic for Amplitude event calls
        },
      );
    });
  }

  /**
   * Sends in an identification event to Amplitude so user events
   * from this browser session are attached to a particular identity (user account).
   */
  public async setupUserIdentity(idTokenClaims: Promise<CreatorIdToken | undefined>): Promise<boolean> {
    try {
      if (await this.blocked) {
        return false;
      }

      const claims = await idTokenClaims;

      if (!claims) {
        return true;
      }

      // `publisher_id` is what we are using for Amplitude `userId`
      const userId = claims.publisher_id?.toString() || '';
      const rsAccountId = claims.account_id?.toString();
      const isAdmin = userStatus.isAccountAdmin(claims).toString();

      await Promise.all([
        this.identifyUser({ userId }),
        this.addUserAttributes({
          account_id: { value: rsAccountId ?? '' },
          account_is_admin: { value: isAdmin },
        }),
      ]);

      return true;
    } catch (error) {
      return false;
    }
  }
}
