
import { defineComponent, computed } from 'vue';

import { Layout } from '@/layouts/types';

import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import SiteHeaderOnboarding from '@/components/SiteHeaderOnboarding.vue';

import { useRoute } from 'vue-router/composables';
import { isSetupRoute, isResetPasswordRoute } from '@/utils/router/namedRoutes';

export default defineComponent({
  name: Layout.DEFAULT,
  components: {
    SiteHeader,
    SiteHeaderOnboarding,
    SiteFooter,
  },
  setup(props, context) {
    const $route = useRoute();

    // TODO: Create new layout for Onboarding/Account Setup using Route Config meta properties

    // Onboarding flow check for showing correct header/footer.
    const onboardingFlow = computed(() => isSetupRoute($route) || isResetPasswordRoute($route));

    return {
      onboardingFlow,
    };
  },
});
