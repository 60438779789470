import removeDuplicateStringsInArray from '@/utils/removeDuplicateStringsInArray';

export type ContentType =
  | 'BEAUTY'
  | 'BRIDAL'
  | 'CELEBRITY'
  | 'DIY'
  | 'FAMILY'
  | 'FASHION'
  | 'FOOD'
  | 'HOME_INTERIOR'
  | 'MENS'
  | 'OTHER'
  | 'PETITE'
  | 'PLUS_SIZE'
  | 'SHOP_OVERSEAS'
  | 'STREET_STYLE'
  | 'TRAVEL'
  | 'WELLNESS';

export const contentTypeList: ContentType[] = [
  'BEAUTY',
  'BRIDAL',
  'CELEBRITY',
  'DIY',
  'FAMILY',
  'FASHION',
  'FOOD',
  'HOME_INTERIOR',
  'MENS',
  'OTHER',
  'PETITE',
  'PLUS_SIZE',
  'SHOP_OVERSEAS',
  'STREET_STYLE',
  'TRAVEL',
  'WELLNESS',
];

const contentTypeCheck = (contentTypes: ContentType[]): string[] => {
  if (contentTypes.length === 0) return [];

  const correctedList = contentTypes.map((contentType) => {
    const isInList = contentTypeList.findIndex((contentListItem) => contentType.toUpperCase() === contentListItem);
    if (isInList === -1) {
      contentType = 'OTHER';
    }

    return contentType;
  });

  return removeDuplicateStringsInArray(correctedList) as ContentType[];
};

export default contentTypeCheck;
