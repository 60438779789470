import Vue from 'vue';
import Vuex from 'vuex';
import { config } from 'vuex-module-decorators';

Vue.use(Vuex);

config.rawError = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {},
  actions: {},
});
