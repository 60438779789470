import { ExchangeRateResponse } from '@/store/exchangeRate/types';
import { processResponse, Api } from '@/utils/api';

const config = window.rewardStyle;

class ExchangeRateApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/co-api/v1/get_exchange_rates`;

  public async get() {
    const response = await this.sendRequest('GET', '');
    return processResponse<ExchangeRateResponse>(response);
  }
}

export default new ExchangeRateApi();
