import { processResponse, Api } from '@/utils/api';
import { ProductDetailsCoApiResponse } from '@/store/products/types';

const config = window.rewardStyle;

class ProductDetailsCoApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/co-api/v1/get_products_info`;
  public header: string;
  public unauthorized: boolean;

  public async get(productIds: string[]) {
    const query = new URLSearchParams();
    productIds.forEach((id) => {
      query.append('product_ids[]', id);
    });
    query.set('localize_currency', 'true');
    const response = await this.sendRequest('GET', `?${query.toString()}`);

    this.header = response.headers.get('www-authenticate') || '';

    return await processResponse<ProductDetailsCoApiResponse>(response);
  }
}

export default new ProductDetailsCoApi();
