
import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'RsSidebar',
  props: {
    sticky: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        'rs--sidebar': true,
        'is--sticky': props.sticky,
        'is--full': props.full,
        'is--dark': props.dark,
      };
    });
    return { classes };
  },
});
