
import { computed, defineComponent, getCurrentInstance, ref, watch } from 'vue';

import { VuetifyBreakpointCode } from '@/lib/models/NavigationItem';
import { GlobalElementId } from '@/utils/globalElementIds';
import AccountsStore from '@/store/accounts';
import { UserLocale } from '@/rs-account/constants';

export default defineComponent({
  name: 'SiteHeaderOnboarding',
  setup(props, context) {
    const vm = getCurrentInstance();
    const { $vuetify, $gettext, $language } = vm?.proxy as Vue;

    /**
     * @description Determines whether the viewport is at a specific breakpoint.
     */
    const isAtBreakpoint = (value: VuetifyBreakpointCode): boolean => {
      const breakpoints = $vuetify.breakpoint;
      return breakpoints[value];
    };
    const userLocale = ref<UserLocale>(AccountsStore.user.locale);

    watch(
      () => AccountsStore.user,
      (next) => {
        userLocale.value = next.locale;
      },
    );

    const labels = computed(() => ({
      language: $gettext('Language'),
    }));

    const languageOptions = Object.keys($language.available).map((locale) => ({
      value: locale,
      text: $language.available[locale],
    }));

    const updateLanguage = () => {
      // Update the application language and rs_lang cookie
      $language.current = userLocale.value;

      // Update userModel
      AccountsStore.patchUser({ locale: userLocale.value });
    };

    return {
      GlobalElementId,
      isAtBreakpoint,
      languageOptions,
      labels,
      updateLanguage,
      userLocale,
    };
  },
});
