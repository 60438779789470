import { defineStore } from 'pinia';
import { MediaUploadMap, MediaObjectProgressMap, UploadProgress } from '@/types/ltks';
import { computed, ref } from 'vue';
import { FileWithImageData } from '@/types/ltks';
import { type SelectedFile, type SelectedMediaUrl } from '@/types/webUpload';
import { _convertImgToJpegDataUri, _fetchVideoAsFile } from '@/utils/imageConversion';
import { isDataUrl } from '@/utils/isDataUrl';
import { FILE, MP4 } from '@/rs-desktop-publish/consts/publish';

export const useMediaObjectsStore = defineStore('Media Objects', () => {
  // Initial State
  const mediaObjectProgress = ref<MediaObjectProgressMap>({});
  const mediaUploads = ref<MediaUploadMap>({});
  const successfulMediaUploads = ref<(string | File | undefined)[]>([]);
  const desktopMediaUploads = ref<(string | File)[]>([]);

  const posterFile = ref<File | null>(null);
  const selectedImage = ref<File | null>(null);
  const selectedImageURL = ref<string | undefined>(undefined);
  const selectedVideo = ref<string>('');
  const selectedVideoFileList = ref<FileList | null>(null);
  const selectedVideoPoster = ref<string | undefined>(undefined);
  const videoFile = ref<File | null>(null);
  const keyframes = ref<string[]>([]);
  const selectionFrames = ref<string[]>([]);
  const originalVideoResolution = ref<string[]>([]);
  const originalImageResolution = ref<string[]>([]);
  const originalMediaResolution = ref<string[]>([]);

  // Storytelling Initial State
  const selectedMediaFiles = ref<SelectedFile[]>([]);
  const inProcessMediaURLS = ref<SelectedMediaUrl[]>([]);
  const processedMediaURLS = ref<SelectedMediaUrl[]>([]);
  const selectedMediaIndex = ref<number>(0);

  const setInProcessMediaURLS = (media: SelectedMediaUrl) => {
    inProcessMediaURLS.value.push(media);
    inProcessMediaURLS.value.sort((a, b) => a.originalIndex - b.originalIndex);
  };

  const setProcessedMediaURLS = (media: SelectedMediaUrl) => {
    processedMediaURLS.value.push(media);
    processedMediaURLS.value.sort((a, b) => a.originalIndex - b.originalIndex);
  };

  const setSelectedMediaIndex = (index: number) => {
    selectedMediaIndex.value = index;
  };

  const setMuted = (muted: boolean) => {
    inProcessMediaURLS.value[selectedMediaIndex.value].muted = muted;
    processedMediaURLS.value[selectedMediaIndex.value].muted = muted;
  };

  const resetVideoState = () => {
    inProcessMediaURLS.value[selectedMediaIndex.value].muted = false;
    processedMediaURLS.value[selectedMediaIndex.value].muted = false;

    inProcessMediaURLS.value[selectedMediaIndex.value].selectedFrame = 0;
    processedMediaURLS.value[selectedMediaIndex.value].selectedFrame = 0;

    inProcessMediaURLS.value[selectedMediaIndex.value].trimmerValues = { start: 0, end: 0 };
    processedMediaURLS.value[selectedMediaIndex.value].trimmerValues = { start: 0, end: 0 };

    inProcessMediaURLS.value[selectedMediaIndex.value].poster = '';
    processedMediaURLS.value[selectedMediaIndex.value].poster = '';
  };

  const setVideoName = (name: string) => {
    inProcessMediaURLS.value[selectedMediaIndex.value].name = name;
  };

  const setVideoState = (v: {
    muted: boolean;
    selectedFrame: number;
    trimmerValues: {
      start: number;
      end: number;
    };
  }) => {
    inProcessMediaURLS.value[selectedMediaIndex.value].muted = v.muted;
    processedMediaURLS.value[selectedMediaIndex.value].muted = v.muted;

    inProcessMediaURLS.value[selectedMediaIndex.value].selectedFrame = v.selectedFrame;
    processedMediaURLS.value[selectedMediaIndex.value].selectedFrame = v.selectedFrame;

    inProcessMediaURLS.value[selectedMediaIndex.value].trimmerValues = v.trimmerValues;
    processedMediaURLS.value[selectedMediaIndex.value].trimmerValues = v.trimmerValues;

    if (v.trimmerValues.start !== 0 && v.trimmerValues.end !== 0) {
      inProcessMediaURLS.value[selectedMediaIndex.value].trimmed = true;
      processedMediaURLS.value[selectedMediaIndex.value].trimmed = true;
    }
  };

  const setPoster = async (poster: string) => {
    const jpegPoster = await _convertImgToJpegDataUri(poster);
    inProcessMediaURLS.value[selectedMediaIndex.value].poster = jpegPoster;
    processedMediaURLS.value[selectedMediaIndex.value].poster = jpegPoster;
  };

  const saveInProcessMedia = () => {
    inProcessMediaURLS.value[selectedMediaIndex.value] = { ...processedMediaURLS.value[selectedMediaIndex.value] };
  };

  const saveInProcessMediaURL = () => {
    inProcessMediaURLS.value[selectedMediaIndex.value].url = processedMediaURLS.value[selectedMediaIndex.value].url;
  };

  const setVideoFile = (file: File) => {
    processedMediaURLS.value[selectedMediaIndex.value].file = file;
  };

  const replaceSelectedMediaFiles = (media: SelectedFile) => {
    selectedMediaFiles.value[selectedMediaIndex.value] = {
      ...media,
    };
  };

  const replaceMediaURLS = (media: SelectedMediaUrl) => {
    inProcessMediaURLS.value[selectedMediaIndex.value] = {
      ...media,
    };
    processedMediaURLS.value[selectedMediaIndex.value] = {
      ...media,
    };
  };

  const getProcessedMediaUrl = () => {
    return processedMediaURLS.value?.[selectedMediaIndex.value]?.url;
  };

  const getSelectedMediaFile = () => {
    return selectedMediaFiles.value?.[selectedMediaIndex.value]?.file;
  };

  const setOriginalVideoResolution = (video: HTMLVideoElement) => {
    originalVideoResolution.value.push(`${video.videoWidth} x ${video.videoHeight}`);
  };

  const setOriginalImageResolution = (image: HTMLImageElement) => {
    originalImageResolution.value.push(`${image.naturalWidth} x ${image.naturalHeight}`);
  };

  const setOriginalMediaResolution = (media: HTMLImageElement | HTMLVideoElement) => {
    const resolution =
      media instanceof HTMLImageElement
        ? `${media.naturalWidth} x ${media.naturalHeight}`
        : `${media.videoWidth} x ${media.videoHeight}`;
    originalMediaResolution.value.push(resolution);
  };

  const setMediaObjectProgress = (ltkId: string, mediaObject: UploadProgress) => {
    mediaObjectProgress.value = Object.assign({}, mediaObjectProgress.value, {
      [ltkId]: { ...mediaObject },
    });
  };

  const getMediaObjectProgressByLtkId = (ltkId: string) => {
    return computed(() => mediaObjectProgress.value[ltkId]);
  };

  const getUriByLtkId = (ltkId: string) => {
    return computed(() => mediaUploads.value[ltkId]);
  };

  const removeMedia = (index: number) => {
    if (inProcessMediaURLS.value.length === 1) {
      selectedMediaFiles.value = [];
      inProcessMediaURLS.value = [];
      processedMediaURLS.value = [];
      $reset();
    } else {
      selectedMediaFiles.value.splice(index, 1);
      inProcessMediaURLS.value.splice(index, 1);
      processedMediaURLS.value.splice(index, 1);
    }
  };

  const $reset = () => {
    mediaObjectProgress.value = {};
    mediaUploads.value = {};
    successfulMediaUploads.value = [];
    originalVideoResolution.value = [];
    originalImageResolution.value = [];
    originalMediaResolution.value = [];
    selectedMediaFiles.value = [];
    inProcessMediaURLS.value = [];
    processedMediaURLS.value = [];
    selectedMediaIndex.value = 0;
    resetImage();
    resetVideo();
  };

  const handleVideo = async (file: File) => {
    return URL.createObjectURL(file);
  };

  const setMediaUploads = async (ltkId: string, file: File) => {
    const mediaUpload = file.type.split('/')[0] === 'video' ? file : await handleImage(file);
    mediaUploads.value = Object.assign({}, mediaUploads.value, {
      [ltkId]: [mediaUpload],
    });
  };

  const setMediaUploadsDesktop = async (ltkId: string) => {
    desktopMediaUploads.value = [];
    for (const [index, media] of processedMediaURLS.value.entries()) {
      const mediaAsset = await formatMediaAssetForUpload(media, index);
      if (!mediaAsset) return;
      desktopMediaUploads.value.push(mediaAsset);
    }
    mediaUploads.value = Object.assign({}, mediaUploads.value, {
      [ltkId]: desktopMediaUploads,
    });
  };

  const formatMediaAssetForUpload = async (media: SelectedMediaUrl, index: number) => {
    if (media.hasOwnProperty(FILE)) {
      return media.file;
    }

    if (isDataUrl(media.url)) {
      return media.url;
    }

    if (media.url?.endsWith(MP4)) {
      const videoFile = await _fetchVideoAsFile(media.url);
      processedMediaURLS.value[index].file = videoFile;
      return videoFile;
    }

    const imageUrl = await _convertImgToJpegDataUri(media.url);
    processedMediaURLS.value[index].url = imageUrl;
    return imageUrl;
  };

  const setSuccessfulMediaUploads = (media: string | File | undefined) => {
    if (!media) return;
    successfulMediaUploads.value?.push(media);
  };

  const handleImage = async (file: File) => {
    const reader = new FileReader();
    await new Promise((res, rej) => {
      reader.addEventListener('load', res);
      reader.addEventListener('error', rej);
      reader.readAsDataURL(file);
    });
    return (reader.result as string | null) || undefined;
  };

  const addImageData = async (files: File[]): Promise<FileWithImageData[]> => {
    const filesWithImages = await Promise.all(
      files.map(async (file) => {
        let imageData;
        if (file.type.split('/')[0] === 'video') {
          imageData = (await handleVideo(file)) || '';
        } else {
          imageData = (await handleImage(file)) || '';
        }

        // Explicitly create a new File object, rather than using spread operator to support typing
        const newFile = new File([file], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        const fileWithImageData: FileWithImageData = Object.assign(newFile, { imageData });

        return fileWithImageData;
      }),
    );
    return filesWithImages;
  };
  // Desktop Add Media
  const setSelectedImage = async (image: File) => {
    selectedImageURL.value = await handleImage(image);
    selectedImage.value = image;
  };

  const setVideo = async (file: File) => {
    videoFile.value = file;
    if (selectedVideo.value) {
      URL.revokeObjectURL(selectedVideo.value);
    }
    selectedVideo.value = await handleVideo(file);
  };
  const setRawVideoFile = async (files: FileList) => {
    selectedVideoFileList.value = files;
  };

  const setSelectedVideo = (url: string) => {
    selectedVideo.value = url;
  };
  const setVideoPoster = async (file: File) => {
    if (!file) return;
    posterFile.value = file;
    selectedVideoPoster.value = await handleImage(file);
  };

  const setRawVideoPoster = (src: string) => {
    selectedVideoPoster.value = src;
  };

  const setKeyframes = (frames: string[]) => {
    keyframes.value = frames;
  };

  const setSelectionFrames = (frames: string[]) => {
    selectionFrames.value = frames;
  };

  const resetImage = () => {
    selectedImage.value = null;
    selectedImageURL.value = undefined;
  };

  const resetMediaArrays = () => {
    selectedMediaFiles.value = [];
    inProcessMediaURLS.value = [];
    processedMediaURLS.value = [];
  };

  const resetVideo = () => {
    videoFile.value = null;
    selectedVideoPoster.value = undefined;
    selectedVideo.value = '';
    selectedVideoFileList.value = null;
    keyframes.value = [];
    selectionFrames.value = [];
  };

  const updateVideo = (videoUri: string, imageUri: string) => {
    selectedVideo.value = videoUri;
    selectedVideoPoster.value = imageUri;
  };

  const getImageCount = () => {
    let count = 0;

    for (const media of inProcessMediaURLS.value) {
      if (!media.hasOwnProperty(FILE)) {
        count++;
      }
    }

    return count;
  };

  const getVideoCount = () => {
    let count = 0;

    for (const media of inProcessMediaURLS.value) {
      if (media.hasOwnProperty(FILE)) {
        count++;
      }
    }

    return count;
  };

  return {
    getImageCount,
    getVideoCount,
    inProcessMediaURLS,
    desktopMediaUploads,
    keyframes,
    mediaObjectProgress,
    mediaUploads,
    originalImageResolution,
    originalMediaResolution,
    originalVideoResolution,
    posterFile,
    processedMediaURLS,
    selectedImage,
    selectedImageURL,
    selectedMediaFiles,
    selectedMediaIndex,
    selectedVideo,
    selectedVideoFileList,
    selectedVideoPoster,
    selectionFrames,
    successfulMediaUploads,
    videoFile,
    setVideoName,
    resetVideoState,
    removeMedia,
    setVideoState,
    $reset,
    addImageData,
    getMediaObjectProgressByLtkId,
    getProcessedMediaUrl,
    getSelectedMediaFile,
    getUriByLtkId,
    handleImage,
    handleVideo,
    replaceMediaURLS,
    replaceSelectedMediaFiles,
    resetImage,
    resetMediaArrays,
    resetVideo,
    saveInProcessMedia,
    saveInProcessMediaURL,
    setInProcessMediaURLS,
    setKeyframes,
    setMediaObjectProgress,
    setMediaUploads,
    setMediaUploadsDesktop,
    setMuted,
    setOriginalImageResolution,
    setOriginalMediaResolution,
    setOriginalVideoResolution,
    setPoster,
    setProcessedMediaURLS,
    setRawVideoFile,
    setRawVideoPoster,
    setSelectedImage,
    setSelectedMediaIndex,
    setSelectedVideo,
    setSelectionFrames,
    setSuccessfulMediaUploads,
    setVideo,
    setVideoFile,
    setVideoPoster,
    updateVideo,
  };
});
