
import { computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import { useRouter } from 'vue-router/composables';

import { RsButton, RwModal, RwModalBody, RwModalFooter, RwModalHeader } from '@/lib';
import { ACCOUNT_SETUP } from '@/utils/router/namedRoutes';

export default defineComponent({
  name: 'TosUpdateModal',
  components: { RwModalFooter, RsButton, RwModal, RwModalHeader, RwModalBody },
  setup(props, context) {
    const vm = getCurrentInstance();
    const { $gettext } = vm?.proxy as Vue;
    const $router = useRouter();
    const state = reactive({
      isActive: true,
    });

    const title = computed(() => $gettext('We’ve updated our Terms of Service, Privacy Policy, and Cookie Policy'));
    const okayText = computed(() => $gettext('Okay'));
    const description = computed(() =>
      $gettext(
        'You will need to agree to the updated terms and acknowledge the updated policies to continue using our services.',
      ),
    );

    const handleOkay = () => {
      state.isActive = false;
      $router.push({
        name: ACCOUNT_SETUP,
        query: {
          next_url: window.location.href,
        },
      });
    };

    return {
      handleOkay,
      okayText,
      title,
      description,
      state,
    };
  },
});
