import { RouteConfig } from 'vue-router';
import { PRODUCTS_SEARCH, LTK_PRODUCT_LINK } from '@/utils/router/namedRoutes';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';

const routes: RouteConfig[] = [
  {
    path: '/ltk-product-link',
    name: LTK_PRODUCT_LINK,
    component: () => import(/* webpackChunkName: "ltk-link" */ '../views/ProductLink.vue'),
  },
  {
    path: '/products/search',
    name: PRODUCTS_SEARCH,
    beforeEnter: async (to, from, next) => {
      await validateRoute([], to, from, next);
    },
    component: () => import(/* webpackChunkName: "products-v2" */ '../views/ProductsSearch.vue'),
  },
];

export default routes;
