
import { defineComponent, provide, ref, onMounted, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router/composables';
import { Layout } from '@/layouts/types';
import PublishingHeader from '@/components/PublishingHeader.vue';
import SaveDraftDialog from '@/rs-publish/components/SaveDraftDialog.vue';
import { useSavedDraftsModalInjector } from '@/config/vue-provide-inject-keys';
import { usePublishingAnalytics } from '@/rs-publish/composables';
import { usePublishStore } from '@/store/publish';
import { appMessaging } from '@/utils/messaging';
import { useDraftsStore } from '@/store/drafts';
import { useHashtagsStore } from '@/store/hashtags';
export default defineComponent({
  name: Layout.PUBLISH,
  components: {
    PublishingHeader,
    SaveDraftDialog,
  },
  setup() {
    const vm = getCurrentInstance()?.proxy as Vue;
    const router = useRouter();
    const { publishingEvent } = usePublishingAnalytics(vm.$userEvents, router.currentRoute);
    const isSubmitted = ref(false);
    const isDiscarded = ref(false);
    const publishStore = usePublishStore();
    const draftsStore = useDraftsStore();
    const { draftable } = storeToRefs(publishStore);
    const hashtagsStore = useHashtagsStore();
    const drafts = ref(false);

    const onClose = () => {
      draftsStore.setShouldCloseAfterSaved(false);
      if (draftable.value && draftsStore.isDraftNotSaved()) {
        drafts.value = !drafts.value;
        isSubmitted.value = false;
        draftsStore.setShouldCloseAfterSaved(true);
      } else {
        const messaging = appMessaging();
        messaging.webViewSendMessage('close-webview');
      }
    };

    const handleSubmit = (submitted: boolean) => {
      isSubmitted.value = submitted;
    };

    const handleDiscard = (discarded: boolean) => {
      isDiscarded.value = discarded;
    };

    const checkOkToClose = () => {
      draftsStore.setShouldCloseAfterSaved(false);
      if (draftsStore.isDraftNotSaved()) {
        draftsStore.setDraftNotSaved(true);
        draftsStore.setShouldCloseAfterSaved(true);
      } else {
        draftsStore.setDraftNotSaved(false);
        const messaging = appMessaging();
        messaging.webViewSendMessage('close-webview');
      }
    };

    onMounted(() => {
      publishingEvent('/publishing/enter-flow');
      window.addEventListener('message', (event) => {
        if (event?.data?.type === 'video-clipped') {
          publishingEvent('/rsvideo-trim-and-cover/done', event.data.payload);
        }
        if (event?.data?.type === 'can-i-close') {
          checkOkToClose();
        }
        if (event?.data?.type === 'debug-date') {
          hashtagsStore.setDebugDate(event.data.payload);
        }
      });
    });
    provide(useSavedDraftsModalInjector, { submitted: isSubmitted, discarded: isDiscarded });

    return { drafts, onClose, handleDiscard, handleSubmit };
  },
});
