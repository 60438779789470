import { type LtkPostProduct, type MediaObject, type Post } from '@/types/ltks';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { usePublishStore } from './publish';
import { useHashtagsStore } from './hashtags';
import { compareObjectValues } from '@/utils/compareObjectValues';
import { objectIsEmpty } from '@/utils/objectIsEmpty';
import { PostMatchType, PostProduct, PostProductAction } from '@/types/postProducts';
import { usePostProductsStore } from './postProducts';
import ProductDetailsIndex from '@/store/products/index';
export const useDraftsStore = defineStore('Drafts Service', () => {
  // Initial State
  const selectedDraftPost = ref(<Post | undefined>{});
  const selectedDraftMediaObject = ref(<MediaObject | undefined>{});
  const draftNotSaved = ref<boolean>(false);
  const isSavedDraft = ref<boolean>(false);
  const shouldCloseAfterSaved = ref<boolean>(false);
  const draftProductsIds = ref<string[]>([]);
  const publishStore = usePublishStore();
  const hashtagStore = useHashtagsStore();
  const postProductsStore = usePostProductsStore();
  const mediaStateIsDraft = computed(
    () => selectedDraftMediaObject.value && selectedDraftMediaObject.value.state === 'draft',
  );

  const setSelectedDraftPost = (postId: string) => {
    // Clear draft store before a new draft is loaded.
    $reset();
    // Get and load the selected draft post.
    selectedDraftPost.value = publishStore.getLtkById(postId).value;
    selectedDraftMediaObject.value = publishStore.getMediaObjectById(postId).value as MediaObject;
    if (selectedDraftPost.value) {
      isSavedDraft.value = !objectIsEmpty(selectedDraftPost.value);
      loadSelectedDraftPost(selectedDraftPost.value);
    }
  };

  const loadSelectedDraftPost = async (post: Post) => {
    if (selectedDraftMediaObject.value && selectedDraftMediaObject.value.type === 'video') {
      setVideoFileFromUrl(post.id);
      if (selectedDraftMediaObject.value.thumbnails) {
        publishStore.selectedVideoPoster = selectedDraftMediaObject.value.thumbnails[0].media_cdn_url || undefined;
      }
    } else {
      if (post.cover_photo_url) {
        publishStore.setProcessedImage(post.cover_photo_url);
      }
    }
    publishStore.postId = post.id;

    const { caption, hashtag } = getCaptionHashtagValue(post.caption);
    if (caption) {
      publishStore.setCaptionInput(caption);
    }

    if (hashtag && hashtag?.length > 0) {
      hashtagStore.setHashtagInput(hashtag);
    }

    if (post.post_products?.length > 0) {
      post.post_products.forEach((item) => {
        draftProductsIds.value.push(item.product_reference_id);
      });

      post.post_products.map((item) => {
        if (!item.image_url) {
          const img = ProductDetailsIndex.getCoApiProductImage(item.product_reference_id);
          item.image_url = img;
        }
      });
      const postProducts = formatPostProducts(post.post_products);
      await postProductsStore.setProducts(postProducts as PostProduct[]);
      publishStore.setProducts();
    }
  };

  const setVideoFileFromUrl = (postId: string) => {
    const mediaObject = publishStore.getMediaObjectById(postId);
    if (mediaObject && mediaObject.value?.passthrough_media_cdn_url) {
      publishStore.setSelectedVideo(mediaObject.value.passthrough_media_cdn_url);
    }
  };

  const formatPostProducts = (postProducts: LtkPostProduct[]): PostProduct[] => {
    const postProductArray: PostProduct[] = [];
    postProducts.map((item: LtkPostProduct) => {
      const product = {
        action: PostProductAction.UPDATE,
        favorite_id: '',
        image_url: item.image_url as string,
        link_url: item.link_url,
        match_type: item.match_type as PostMatchType,
        position: item.position,
        product_reference_id: item.product_reference_id,
      };
      postProductArray.push(product);
    });
    return postProductArray as PostProduct[];
  };

  const getCaptionHashtagValue = (postCaption: string) => {
    let hashTagArray;
    let caption = postCaption?.match(/^.*\n\n*/gm)?.[0];
    if (!!caption?.match(/.\n\n/) === false) {
      caption = undefined;
    }
    caption?.trim().replace(/\n/g, '');
    if (caption) {
      hashTagArray = postCaption
        .match(/^\n\n*.*/gm)?.[0]
        .replace(/\n/g, '')
        .split(' ');
    } else {
      hashTagArray = postCaption?.split(' ');
    }
    const hashtag = hashTagArray?.filter((str) => str.startsWith('#'));
    return { caption, hashtag };
  };

  const getEditedDraftPost = (): { [key: string]: string | undefined | PostProduct[] } => {
    return {
      ...(publishStore.getCaptionHashtagsString() ? { caption: publishStore.getCaptionHashtagsString() } : {}),
      ...(publishStore.processedImageURL || publishStore.selectedVideoPoster
        ? { cover_photo_url: publishStore.processedImageURL || publishStore.selectedVideoPoster }
        : {}),
      ...(publishStore.getProductsInput(false) ? { post_products: publishStore.getProductsInput(false) } : {}),
    };
  };

  // This function checks if any edits have been made to the selected draft post.
  const isDirty = (): boolean => {
    const editedDraftPost = getEditedDraftPost();
    const post = {
      ...selectedDraftPost.value,
      ...(selectedDraftPost.value?.post_products
        ? { post_products: formatPostProducts(selectedDraftPost.value?.post_products as LtkPostProduct[]) }
        : {}),
    };
    if (objectIsEmpty(post)) {
      return true;
    }

    return !compareObjectValues(editedDraftPost, post);
  };

  const setDraftNotSaved = (notSaved: boolean) => {
    draftNotSaved.value = notSaved;
  };

  const setShouldCloseAfterSaved = (closeWebView: boolean) => {
    shouldCloseAfterSaved.value = closeWebView;
  };

  const isDraftNotSaved = (): boolean => {
    const post = { ...selectedDraftPost.value };
    if (!post?.id && publishStore.draftable) {
      return true;
    }
    return isSavedDraft.value && isDirty();
  };

  function $reset() {
    publishStore.$reset();
    hashtagStore.$reset();
    postProductsStore.reset();
    draftProductsIds.value = [];
    selectedDraftPost.value = {} as Post;
    isSavedDraft.value = false;
    selectedDraftMediaObject.value = {} as MediaObject;
    draftNotSaved.value = false;
  }

  return {
    draftNotSaved,
    draftProductsIds,
    isSavedDraft,
    mediaStateIsDraft,
    selectedDraftPost,
    shouldCloseAfterSaved,
    isDirty,
    isDraftNotSaved,
    loadSelectedDraftPost,
    formatPostProducts,
    setDraftNotSaved,
    setSelectedDraftPost,
    setShouldCloseAfterSaved,
    selectedDraftMediaObject,
    $reset,
  };
});
