import Vue from 'vue';
import store from '@/store';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import {
  ProductsState,
  ProductReferencesMap,
  ProductImageMap,
  ProductImage,
  CoApiProductsMap,
} from '@/store/products/types';
import productsCoApi from '@/store/products/detailsCoApi';
import { ApiErrorResponse } from '@/utils/api/types';

@Module({ dynamic: true, name: 'products', store })
class ProductDetailsIndex extends VuexModule implements ProductsState {
  public productsCoApiMap: CoApiProductsMap = {};
  public productReferences: ProductReferencesMap = {};
  public productImages: ProductImageMap = {};
  public serverError: ApiErrorResponse | null = null;

  // co api endpoint
  get getCoApiProductLink() {
    return (productId: string) => this.productsCoApiMap[productId]?.product_url;
  }

  get getCoApiProductRetailer() {
    return (productId: string) => this.productsCoApiMap[productId]?.advertiser_id;
  }

  get getCoApiProductName() {
    return (productId: string) => this.productsCoApiMap[productId]?.product_name;
  }

  get getCoApiProductImage() {
    return (productId: string) => this.productsCoApiMap[productId]?.product_image;
  }

  get getCoApiProductCurrency() {
    return (productId: string) => this.productsCoApiMap[productId]?.currency;
  }

  get getCoApiProductPrice() {
    return (productId: string) => this.productsCoApiMap[productId]?.price;
  }

  get getCoApiProductBrand() {
    return (productId: string) => this.productsCoApiMap[productId]?.designer;
  }

  @Action
  public async fetchProductsCoApiDetails(productIds: string[]) {
    const productsDetailsResponse = await productsCoApi.get(productIds);

    try {
      this.FETCH_CO_API_PRODUCTS(productsDetailsResponse.products);
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Mutation
  public FETCH_CO_API_PRODUCTS(products: CoApiProductsMap) {
    Vue.set(this, 'productsCoApiMap', Object.assign({}, this.productsCoApiMap, products));
  }

  // products service endpoint behavior
  get getProductImage() {
    return (productId: string) => {
      const productRef = this.productReferences[productId];
      if (productRef === undefined) {
        return undefined;
      }

      return this.productImages[productRef.retailer_product_image_id];
    };
  }

  @Mutation
  public FETCH_PRODUCT_IMAGES(productImages: ProductImage[]) {
    productImages.forEach((productImage) => {
      Vue.set(this.productImages, productImage.id, productImage);
    });
  }

  @Mutation
  public FETCH_PRODUCT_REFERENCES(productReferences: RetailerProductReference[]) {
    productReferences.forEach((productReference) => {
      Vue.set(this.productReferences, productReference.id, productReference);
    });
  }

  @Mutation
  public SET_SERVER_ERROR(error: ApiErrorResponse | null) {
    Vue.set(this, 'serverError', error);
  }

  @Mutation
  public RESET_STORE() {
    Vue.set(this, 'productsCoApiMap', {});
    Vue.set(this, 'productReferences', {});
    Vue.set(this, 'productImages', {});
    Vue.set(this, 'serverError', null);
  }
}

export default getModule(ProductDetailsIndex);
