import { defineStore } from 'pinia';
import { computed, ref, Ref, unref } from 'vue';
import { Experiment, type ExperimentClient, type Variant, type Variants } from '@amplitude/experiment-js-client';
import {
  ExperimentType,
  ActiveExperimentName,
  ExtractActiveExperimentValues,
  ExperimentVariant,
  ExtractActiveExperimentType,
  ExperimentValue,
} from '@/types/experiments';
import { StorageSerializers, useLocalStorage } from '@vueuse/core';

export const useExperimentsStore = defineStore('experiments', () => {
  const experimentSDK: ExperimentClient = Experiment.initializeWithAmplitudeAnalytics(
    window.rewardStyle.AMPLITUDE_EXPERIMENTS_CLIENT_KEY,
    {
      debug: process.env.NODE_ENV === 'development',
      automaticExposureTracking: false,
    },
  );

  const experimentData: Ref<Variants> = ref({});

  /*
    To avoid unnecessary fetches but allow for overwriting existing data we pass an 'overwrite' parameter.

    If there are no Experiments or if we need to get the latest Experiments we will fetch,
    otherwise if, by user navigation, the experiments store has already been populated we will use
    what is currently there.
  */
  const fetchExperiments = async (overwrite: boolean = false) => {
    if (overwrite || Object.keys(experimentData.value).length === 0) {
      try {
        await experimentSDK.fetch();
        experimentData.value = experimentSDK.all();
      } catch (error) {
        // other errors should fail silently
      }
    }
  };

  /**
   * A composable for using an Amplitude experiment. It is "registered" (i.e. wrapped in a `computed` property) as a [Pinia Getter](https://pinia.vuejs.org/core-concepts/getters.html) (instead of a [Pinia Action](https://pinia.vuejs.org/core-concepts/actions.html)) for ease of use.
   *
   * Unlike Pinia Actions, Getters are **NOT** [stubbed by default](https://pinia.vuejs.org/cookbook/testing.html#Customizing-behavior-of-actions) during unit testing. This allows us to use experiments (i.e. feature flags) during testing without having to set `stubActions` to `false` and define Actions for all the Pinia stores used in the tests.
   *
   */
  const useExperiment = computed(() =>
    /*
      Note: The generic types for this function should be defined "inline" to ensure that the correct types are inferred and strong typing is enforced for the function arguments.

      This is probably due to a limitation with the TypeScript compiler when it comes to type inference when using a Higher Order Function (HOF) with Vue 3's computed() function.
    */
    <N extends ActiveExperimentName>(experimentName: Ref<N> | N, experimentType: Ref<ExtractActiveExperimentType<N>> | ExtractActiveExperimentType<N>, defaultValue?: Ref<ExtractActiveExperimentValues<N> | undefined> | ExtractActiveExperimentValues<N>): ExperimentValue<N> => {
      const experimentVariant = computed<ExperimentVariant<N> | undefined>(
        () => experimentData.value[unref(experimentName)] as ExperimentVariant<N> | undefined,
      );

      const localStorageVariant = computed<ExperimentVariant<N> | undefined>(() => {
        const ls = useLocalStorage<ExtractActiveExperimentValues<N> | null>(unref(experimentName), null, {
          serializer: StorageSerializers.string,
        });

        if (ls.value === null) {
          return undefined;
        }

        return {
          value: ls.value,
        };
      });

      const defaultVariant = computed<ExperimentVariant<N>>(() => {
        const def = unref(defaultValue);
        const type = unref(experimentType);

        const value = def ?? (type === ExperimentType.FLAG ? 'off' : 'control');

        const result: Variant = {
          value,
        };

        return result as ExperimentVariant<N>;
      });

      const variant = computed<ExperimentVariant<N>>(() => {
        const e = experimentVariant.value;
        const l = localStorageVariant.value;
        const d = defaultVariant.value;

        return l ?? e ?? d;
      });

      const isVariant = (target: ExtractActiveExperimentValues<N>): Ref<boolean> =>
        computed<boolean>(() => variant.value.value === target);

      /**
       * Register or track an exposure event for the Experiment.
       *
       * This is useful for tracking how many users are exposed to a particular variant of an Experiment, and it is essential when conducting A/B Tests.
       *
       * Please read the [Understanding A/B Testing with the Amplitude Experiments SDK: Assignment vs. Exposure](https://rewardstyle.atlassian.net/wiki/x/GADS3Q) article for more details on how to use it.
       */
      const exposure = () => {
        experimentSDK.exposure(unref(experimentName));
      };

      return { variant, isVariant, exposure };
    },
  );

  return {
    experimentSDK,
    experimentData,
    fetchExperiments,
    useExperiment,
  };
});
