import { RouteConfig } from 'vue-router';
import { HOMEPAGE } from '@/utils/router/namedRoutes';

import AccountsStore from '@/store/accounts';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';

enum SupportedRegions {
  UNITED_STATES = 'US',
}

enum SupportedLocales {
  EN_US = 'en_US',
}

const routes: RouteConfig[] = [
  {
    path: '/',
    name: HOMEPAGE,
    beforeEnter: async (to, from, next) => {
      const userInfo = AccountsStore.user;
      let rs_lang = ('; ' + document.cookie).split(`; rs_lang=`).pop();
      if (rs_lang) {
        rs_lang = rs_lang.split(';')[0];
      }

      // TODO: Ensure region or country checking is the latest value which can be updated on the Account pages.
      // Check region and language, redirect if not US or not en_US
      if (userInfo.address.country !== SupportedRegions.UNITED_STATES || rs_lang !== SupportedLocales.EN_US) {
        /*
           Vue's router is usually used for navigation within the app, if we try to use
           next(`${window.rewardStyle.LEGACY_HOME_PAGE}/web/products/recent`) it will try to find a matching path within Vue.
           Since there is no matching path the router will treat the url as a 'wildcard' path and redirect to the Analytics page.
           To navigate outside of the Vue app AND prevent the homepage from being visible for a split second we will have to use location.href and then
           'return;' to prevent the router from continuing to look for a `next()` function, not finding one, and then allowing the page navigation to continue
           to the homepage.
          */
        location.href = `${window.rewardStyle.LEGACY_HOME_PAGE}/web/products/recent`;
        return;
      }

      await validateRoute([], to, from, next);
    },
    component: () => import(/* webpackChunkName: "homepage" */ '../views/Homepage.vue'),
  },
];

export default routes;
