const legacyHomePageLink = (path?: string): string => {
  const legacyHomePage = window.rewardStyle.LEGACY_HOME_PAGE;
  if (!path || path.length === 0) {
    return legacyHomePage;
  } else if (path[0] === '/') {
    path = path.slice(1);
  }
  return `${legacyHomePage}/web/${path}`;
};

export default legacyHomePageLink;
