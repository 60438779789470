
import Vue, { computed, defineComponent, getCurrentInstance, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useLayout } from '@/layouts/composables/useLayout';

import { useRouterError } from '@/composables/useRouterError';
import useFullStory, { fullStoryUser } from '@/composables/useFullStory';

import { isLegalRoute, isSetupRoute, isResetPasswordRoute, isAnyApplicationRoute } from '@/utils/router/namedRoutes';

import UserInfoStore from '@/store/userInfo';
import AccountsStore from '@/store/accounts';
import ExchangeRate from '@/store/exchangeRate';

import AppModalDrawer from '@/components/AppModalDrawer.vue';
import ZendeskHelpChat from '@/components/ZendeskHelpChat/ZendeskHelpChat.vue';
import { auth } from '@/utils/auth';

export default defineComponent({
  name: 'App',
  components: {
    AppModalDrawer,
    ZendeskHelpChat,
  },
  setup() {
    const vm = getCurrentInstance();
    const { $gettext, $userEvents } = vm?.proxy as Vue;
    const $route = useRoute();

    const { initFullStory, identifyFullStoryUser } = useFullStory();

    const { layout } = useLayout();

    const { error: routerError } = useRouterError();

    const content = computed(() => ({
      errors: {
        userInfo: $gettext('Error loading information, please try again. If issue persists, please contact support'),
        exchangeRate: $gettext('Error loading exchange rates, prices will display in their original currency'),
        accountsStoreUser: $gettext('Error loading user information from creator account service'),
      },
    }));

    const helpCenterHost = window.rewardStyle.HELP_CENTER_HOST;
    const routeName = computed(() => $route.name);

    /**
     * Flag for showing additional UI once the route (and only the route) has been updated
     */
    const canShowAdditionalUserInterface = ref(false);

    /**
     * Set additional user interface (error alerts, prompts, etc.) once the router is closer to completing the navigation lifecycle during app bootstrap (important) and on navigation updates. Watching the route for changes helps to avoid FLOUC.
     */
    watch($route, (route) => {
      // Do not show on Legal pages
      if (isLegalRoute(route)) {
        return (canShowAdditionalUserInterface.value = false);
      }
      // Do not show when a new user needs to go through Application (Pre and Post)
      if (isAnyApplicationRoute(route)) {
        return (canShowAdditionalUserInterface.value = false);
      }
      // Do not show when a new user needs to go through Account Setup
      if (isSetupRoute(route)) {
        return (canShowAdditionalUserInterface.value = false);
      }
      // Reset password message
      if (isResetPasswordRoute(route)) {
        return (canShowAdditionalUserInterface.value = false);
      }

      return (canShowAdditionalUserInterface.value = true);
    });

    type SnackbarId = null | 'router-error' | 'user-error' | 'exchange-rate-error';

    const snackbar = reactive<{ id: SnackbarId; error: boolean; message: string }>({
      id: null,
      error: false,
      message: '',
    });

    const resetSnackbar = () => {
      snackbar.id = null;
      snackbar.error = false;
      snackbar.message = '';
    };

    const isModalActive = ref(false);

    /**
     * Flag for showing the error snackbar.
     */
    const showSnackbar = computed(() => canShowAdditionalUserInterface.value && !isModalActive.value && snackbar.error);

    watch(
      routerError,
      (error) => {
        if (error) {
          snackbar.id = 'router-error';
          snackbar.error = true;
          snackbar.message = content.value.errors.userInfo;
        } else {
          if (snackbar.id === 'router-error') {
            resetSnackbar();
          }
        }
      },
      { immediate: true },
    );

    watch(
      () => UserInfoStore.serverError,
      (error) => {
        // Prevent showing an error message when the user needs to accept TOS
        if (error && !AccountsStore.user.needs_accept_terms) {
          snackbar.id = 'user-error';
          snackbar.error = true;
          snackbar.message = content.value.errors.userInfo;
        } else {
          if (snackbar.id === 'user-error') {
            resetSnackbar();
          }
        }
      },
      { immediate: true },
    );

    watch(
      () => ExchangeRate.serverError,
      (error) => {
        if (error) {
          snackbar.id = 'exchange-rate-error';
          snackbar.error = true;
          snackbar.message = content.value.errors.exchangeRate;
        } else {
          if (snackbar.id === 'exchange-rate-error') {
            resetSnackbar();
          }
        }
      },
      { immediate: true },
    );

    // FullStory
    const handleFullStory = async () => {
      if (window.rewardStyle.FULLSTORY) {
        initFullStory();

        const idToken = await auth.getIdTokenClaims();

        if (idToken) {
          const user: fullStoryUser = {
            uid: idToken.publisher_id!,
            displayName: idToken.email ?? '',
            email: idToken.email ?? '',
            isEmployee: idToken.employee!,
            accountId: idToken.account_id!,
            accountName: idToken.publisher_name!,
            accountType: idToken.impersonator_id ? 'managedAccount' : 'influencer',
            impersonatorId: idToken.impersonator_id,
          };

          identifyFullStoryUser(user);
        }
      }
    };

    handleFullStory();

    const handleAmplitudeClickEvents = (e: MouseEvent) => {
      // Read the event path for cases where the anchor click originates from a nested element
      const target = e
        .composedPath()
        .filter((elem) => elem instanceof HTMLAnchorElement)
        .shift();

      if (!target) return;

      const anchorElem = target as HTMLAnchorElement;
      const href = anchorElem.href;

      if (href.includes(helpCenterHost)) {
        $userEvents.sendEvent('/click/faq-tech-support', {
          page: $route.path,
          referrer: 'faq',
          'root-referrer': 'global',
        });
      } else if (href.includes('/apply/invite')) {
        $userEvents.sendEvent('/click/refer-creator', {
          page: $route.path,
          referrer: 'refer-creator',
          'root-referrer': 'global',
        });
      }
    };

    // Set on capture phase to avoid interference from internal Vuetify click event directive in some scenarios (VMenu, etc.).
    document.body.addEventListener('click', handleAmplitudeClickEvents, { capture: true });

    onBeforeUnmount(() => {
      document.body.removeEventListener('click', handleAmplitudeClickEvents, { capture: true });
    });

    return {
      layout,
      helpCenterHost,
      isModalActive,
      routerError,
      canShowAdditionalUserInterface,
      snackbar,
      showSnackbar,
      resetSnackbar,
      routeName,
    };
  },
});
