export const presServiceCountries = [
  {
    name: 'United States',
    code: 'us',
  },
  {
    name: 'Australia',
    code: 'au',
  },
  {
    name: 'Brazil',
    code: 'br',
  },
  {
    name: 'Canada',
    code: 'ca',
  },
  {
    name: 'China',
    code: 'cn',
  },
  {
    name: 'Denmark',
    code: 'dk',
  },
  {
    name: 'France',
    code: 'fr',
  },
  {
    name: 'Germany',
    code: 'de',
  },
  {
    name: 'Hong Kong',
    code: 'hk',
  },
  {
    name: 'India',
    code: 'in',
  },
  {
    name: 'Indonesia',
    code: 'id',
  },
  {
    name: 'Ireland',
    code: 'ie',
  },
  {
    name: 'Italy',
    code: 'it',
  },
  {
    name: 'Japan',
    code: 'jp',
  },
  {
    name: 'Korea',
    code: 'kr',
  },
  {
    name: 'Macau',
    code: 'mo',
  },
  {
    name: 'Malaysia',
    code: 'my',
  },
  {
    name: 'Netherlands',
    code: 'nl',
  },
  {
    name: 'New Zealand',
    code: 'nz',
  },
  {
    name: 'Singapore',
    code: 'sg',
  },
  {
    name: 'Spain',
    code: 'es',
  },
  {
    name: 'Sweden',
    code: 'se',
  },
  {
    name: 'Taiwan',
    code: 'tw',
  },
  {
    name: 'Thailand',
    code: 'th',
  },
  {
    name: 'UK',
    code: 'uk',
  },
  {
    name: 'UAE',
    code: 'ae',
  },
  {
    name: 'Vietnam',
    code: 'vn',
  },
];
