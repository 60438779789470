import { NavigationGuard } from 'vue-router';
import { APPLY_HOME, APPLY_STATUS } from '@/utils/router/namedRoutes';
import { useSessionStorage } from '@vueuse/core';
import { SessionStorage } from '@/utils/sessionStorage';
import { auth } from '@/utils/auth';

export const checkApplicationComplete: NavigationGuard = async function (to, from, next) {
  const hasCompletedApplication = useSessionStorage(SessionStorage.RS_APPLICATION_COMPLETE, false);

  if (hasCompletedApplication.value) {
    const isLoggedIn = await auth.isAuthenticated();
    // For ASL: Logged in users should be redirected to application status page, where they can see their application status
    // Non-logged in users should go back to application homepage.
    return next({ name: isLoggedIn ? APPLY_STATUS : APPLY_HOME, query: to.query });
  }

  return next();
};
