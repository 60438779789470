import Vue from 'vue';

type VueGettext = {
  $translations: Vue['$translations'];
  $language: Vue['$language'];
  $gettext: Vue['$gettext'];
  $pgettext: Vue['$pgettext'];
  $ngettext: Vue['$ngettext'];
  $npgettext: Vue['$npgettext'];
  $gettextInterpolate: Vue['$gettextInterpolate'];
};

/**
 * Singleton for Vue 2.x instance with `vue-gettext` global plugin configuration
 *
 * **Note**: This is the same reactivity approach [used internally](https://github.com/Polyconseil/vue-gettext/blob/9ee01eb29f720d4df1ced2df232e2c3021bc1668/src/index.js) by the `vue-gettext` package.
 */
let languageVm: Vue | undefined = undefined;

/**
 * Composable for using the [vue-gettext](https://github.com/Polyconseil/vue-gettext) API from outside of a Vue component instance.
 *
 * Should only be used **after** the main Vue instance has been created.
 *
 * **Note**: This will have to be updated or removed in Vue 3, since there are [breaking Vue Global Instance API changes](https://v3-migration.vuejs.org/breaking-changes/global-api.html).
 */
export function useGettext(): VueGettext {
  languageVm = languageVm ?? new Vue();

  const { $translations, $language, $gettext, $pgettext, $ngettext, $npgettext, $gettextInterpolate } = languageVm;

  if (!$translations && !$language && !$gettext && !$pgettext && !$ngettext && !$npgettext && !$gettextInterpolate) {
    throw new Error('No vue-gettext properties found in Vue instance');
  }

  return {
    $translations,
    $language,
    $gettext,
    $pgettext,
    $ngettext,
    $npgettext,
    $gettextInterpolate,
  };
}
