import _Vue, { PluginObject } from 'vue';
import { Config } from 'amplitude-js';

import { auth } from '@/utils/auth';
import { UserEvents } from '@/utils/UserEvents';
import { MetaCAPI } from '@/utils/UserEvents/MetaCAPI';
import { Amplitude } from '@/utils/UserEvents/Amplitude';
import { UserEventsPublisher } from '@/utils/UserEvents/types';

const { META_CAPI_SERVICE_URL, META_CAPI_PIXEL_ID, AMPLITUDE_API_KEY: amplitudeApiKey } = window.rewardStyle;

const config: Config = {
  includeUtm: true,
  includeReferrer: true,
  includeGclid: true,
  saveParamsReferrerOncePerSession: false,
};

const amplitude = new Amplitude({ apiKey: amplitudeApiKey, config });

class UserEventsPlugin extends UserEvents implements PluginObject<{}> {
  constructor() {
    super();

    this.subscribe(amplitude);

    if (META_CAPI_SERVICE_URL) {
      const metaCapi = new MetaCAPI({ url: META_CAPI_SERVICE_URL, accountIds: [META_CAPI_PIXEL_ID] });
      this.subscribe(metaCapi);
    }
  }

  public install(Vue: typeof _Vue, options?: {}) {
    Vue.prototype.$userEvents = this;
  }
}

/**
 * `UserEventsPlugin` singleton for use outside of the Vue instance.
 */
export const userEvents: UserEventsPlugin = new UserEventsPlugin();

/**
 * Make a call to set up identification for a logged in user.
 * This variable holds the Promise value that tell us that we have sent the user data to Amplitude
 * which is required for Experiments to determine what feature flags the user has access to.
 */
export const hasAmplitudeInitialized = amplitude.setupUserIdentity(auth.getIdTokenClaims());

/**
 * Vue helper function for registering the `UserEventsPlugin`.
 */
export const registerUserEvents = (Vue: typeof _Vue) => {
  Vue.use(userEvents);
};

declare module 'vue/types/vue' {
  interface Vue {
    $userEvents: UserEventsPublisher;
  }
}
