
import RsButton from '@/lib/components/RsButton/RsButton.vue';
import { defineComponent, ref, computed, watchEffect, getCurrentInstance } from 'vue';

export default defineComponent({
  components: { RsButton },
  name: 'LeavePagePopup',
  emits: ['input', 'leave'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const { $gettext } = vm?.proxy as Vue;

    const popupTitle = computed(() => $gettext('Are you sure you want to leave this page?'));
    const popupText = computed(() => $gettext('Changes you made will not be saved.'));
    const popupStay = computed(() => $gettext('Stay on this page'));
    const popupLeave = computed(() => $gettext('Leave page'));
    const popupCloseAria = computed(() => $gettext('Close popup'));

    const dialog = ref(props.value);

    watchEffect(() => {
      dialog.value = props.value;
    });

    function handleStay() {
      closeDialog();
    }

    function handleLeave() {
      closeDialog();
      emit('leave');
    }

    function closeDialog() {
      dialog.value = false;
      emit('input', dialog.value);
    }

    return {
      popupTitle,
      popupText,
      popupStay,
      popupLeave,
      popupCloseAria,
      dialog,
      handleStay,
      handleLeave,
      closeDialog,
    };
  },
});
