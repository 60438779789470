import _Vue from 'vue';
import GetTextPlugin from 'vue-gettext';
import translations from '@/translation.json';
import cookie from 'js-cookie';

const cookieDomain = location.hostname.split('.').splice(-2).join('.');

/**
 * @description Made available in Vue via [vm.$language.available](https://github.com/Polyconseil/vue-gettext#vmlanguage)
 */
export const availableLanguages: SupportedLanguages = {
  en_US: 'English',
  fr_FR: 'French - Français',
  de_DE: 'German - Deutsch',
  'zh-Hans': 'Simplified Chinese - 中文 (简体)',
  'zh-Hant': 'Traditional Chinese - 中文 (繁體)',
  es_ES: 'Spanish - Español',
  it_IT: 'Italian - Italiano',
  pt_BR: 'Portuguese - Português',
  ko_KR: 'Korean - 한국어',
};

/**
 * @description Available languages in their native name (no English label).
 */
const availableNativeLanguages: SupportedLanguages = {
  ...availableLanguages,
  en_US: 'English',
  fr_FR: 'Français',
  de_DE: 'Deutsch',
  'zh-Hans': '中文(简体',
  'zh-Hant': '中文(香港',
  es_ES: 'Español',
  it_IT: 'Italiano',
  pt_BR: 'Português',
  ko_KR: '한국어',
};

/**
 * @description Used to map between our Locales and HubSpotLocales for multiple-language marketing pages.
 * Should be updated as more translations are supported on our HubSpot marketing page or if they change platforms or localization configuration.
 */
const availableHubSpotLanguages: { [L in Locales]: HubSpotLocales } = {
  en_US: 'en',
  fr_FR: 'fr',
  de_DE: 'de',
  'zh-Hans': 'zh-hans',
  'zh-Hant': 'zh-hant',
  es_ES: 'es',
  it_IT: 'en',
  pt_BR: 'pt-br',
  ko_KR: 'ko',
};

/**
 * @description Used to map between our Locales and GoogleMapsLocales for Google Maps API localization.
 * See a list of supported values at: https://developers.google.com/maps/faq#languagesupport
 */
const availableGoogleMapsLanguages: { [L in Locales]: GoogleMapsLocales } = {
  en_US: 'en',
  fr_FR: 'fr',
  de_DE: 'de',
  'zh-Hans': 'zh-CN',
  'zh-Hant': 'zh-TW',
  es_ES: 'es',
  it_IT: 'it',
  pt_BR: 'pt-BR',
  ko_KR: 'ko',
};

/**
 * @description A helper function for displaying native (non-English) format languages.
 * @param lang The Locale language.
 * @returns A "native" language string (display string).
 */
export const getNativeLanguage = (lang: Locales) => availableNativeLanguages[lang];

/**
 * @description A helper function for converting from our locales to a HubSpot-supported language value.
 * @param lang The Locale language.
 * @returns A HubSpot language string.
 */
export const getHubSpotLanguage = (lang: Locales) => availableHubSpotLanguages[lang];

/**
 * @description A helper function for converting from our locales to a Google Maps-supported language value.
 * @param lang The Locale language.
 * @returns A Google Maps language string.
 */
export const getGoogleMapsLanguage = (lang: Locales) => availableGoogleMapsLanguages[lang];

/**
 * @description A HubSpot Query String for Localizing Marketing Page Links with the elusive `hsLang` parameter:
 * - https://knowledge.hubspot.com/website-pages/create-pages-in-multiple-languages
 * - https://community.hubspot.com/t5/CMS-Development/Appending-quot-hsLang-quot-to-all-links-What-is-this/td-p/188569
 * - https://knowledge.hubspot.com/domains-and-urls/edit-a-domain-s-language-setting
 */
export const getMarketingPageQueryString = (lang: Locales) => {
  const params = new URLSearchParams({
    hsLang: getHubSpotLanguage(lang || 'en_US'),
  });

  return `?${params.toString()}`;
};

const languageMap: { [key: string]: Locales } = {
  en: 'en_US',
  fr: 'fr_FR',
  de: 'de_DE',
  zh: 'zh-Hans',
  es: 'es_ES',
  it: 'it_IT',
  pt: 'pt_BR',
  ko: 'ko_KR',
};

/**
 * Sets the default language to start the page with.
 * This is acquired in the following way
 * 1. `rs_lang` cookie. This is a formatted value and no validation needs to happen.
 * 2. browser language. This will be a IETF format, which doesn't match our data format.
 *    so must be manipulated. Replace hyphens with underscores.
 * 3. Confirm that the language has an underscore. If not it is only the language and not the countrey.
 *    Defer to language map to get locale matching locale, and if not found use the default
 * 4. Convert Chinese and Taiwanese to our matching language
 * 5. All else fails, default to en_US, the default language.
 */
const defaultLanguage: Locales = (() => {
  let language = cookie.get('rs_lang') as TokenLocales | undefined;
  if (language === undefined) {
    language = navigator.language.replace('-', '_') as TokenLocales;

    if (language.includes('_') === false) {
      language = languageMap[language] || 'en_US';
    }
  }

  if (language === 'zh_TW') {
    return 'zh-Hant';
  }

  if (language === 'zh_CN') {
    return 'zh-Hans';
  }

  // Validate supported language
  if (availableLanguages[language] === undefined) {
    language = 'en_US';
  }
  return language;
})();

export default (Vue: typeof _Vue) => {
  Vue.use(GetTextPlugin, {
    translations,
    availableLanguages,
    defaultLanguage,
    muteLanguages: ['en', 'en_US'],
    silent: process.env.NODE_ENV !== 'development',
    languageVmMixin: {
      watch: {
        current(newValue: Locales) {
          cookie.set('rs_lang', newValue, { domain: `.${cookieDomain}` });
        },
      },
    },
  });
};
