import { NavigationGuard, Location } from 'vue-router';
import { ACCESS_TOKEN_ERROR_CACHE_KEY, auth } from '@/utils/auth';

export const checkRedirect: NavigationGuard = async function (to, from, next) {
  // Auth is evaluated on a per route basis (required by default)
  const authMeta = typeof to.meta?.auth === 'function' ? to.meta.auth() : to.meta?.auth;

  const isLoggedIn = await auth.isAuthenticated();

  const requiresAuth: boolean | undefined = authMeta?.required ?? true;

  if (requiresAuth === isLoggedIn) {
    return next();
  }

  const redirectLocation: Location | undefined = authMeta?.mismatchedRedirect;

  // Redirect on explicit mismatch
  if (redirectLocation) {
    redirectLocation.query = to.query;
    redirectLocation.hash = to.hash;
    return next(redirectLocation);
  }

  // Unauthenticated user: Hard redirect to Sigil Login
  if (!isLoggedIn) {
    // Fetch potential access token error from sessionStorage so we can pass it to login page
    const auth0AccessTokenError = sessionStorage.getItem(ACCESS_TOKEN_ERROR_CACHE_KEY);

    if (auth0AccessTokenError) {
      // No need to hold on to it any longer
      sessionStorage.removeItem(ACCESS_TOKEN_ERROR_CACHE_KEY);
    }

    return auth.login({
      options: {
        appState: {
          target: to.fullPath,
        },
        authorizationParams: {
          error: auth0AccessTokenError ?? undefined,
        },
      },
      next: to.fullPath,
    });
  }

  return next();
};
