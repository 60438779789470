
import { defineComponent, getCurrentInstance, computed } from 'vue';

export default defineComponent({
  name: 'ApplyToBeCreatorBanner',
  setup(props, context) {
    const vm = getCurrentInstance();
    const { $gettext } = vm?.proxy as Vue;

    const bannerText = computed(() => $gettext('Apply to be an LTK Creator'));
    return { bannerText };
  },
});
