
import { defineComponent } from 'vue';
import { Layout } from '@/layouts/types';
import DefaultLayout from '@/layouts/components/DefaultLayout.vue';

import background from '@/layouts/assets/application/bg.png';
import phones from '@/layouts/assets/application/phones.png';

export default defineComponent({
  name: Layout.REFERRALS,
  components: {
    DefaultLayout,
  },
  props: {
    // TODO: Remove unused `hasPhones` prop and feature
    hasPhones: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    background(): string {
      return background;
    },
    phones(): string {
      return this.$vuetify.breakpoint.smAndDown || this.hasPhones === false ? '' : phones;
    },
    /**
     * return undefined to let vuetify auto detect the aspect ratio.
     * Otherwise use 1 as a dummy value to reset the height of the image container
     */
    phonesAspectRatio(): number | undefined {
      return this.$vuetify.breakpoint.smAndDown ? 5 : undefined;
    },
  },
});
