import { ManifestLine, ConfigFile, IosPatchSystemConfig } from '@/types/iosPatchSystem';

const config = window.rewardStyle;

class IosPatchSystemApi {
  public apiBaseUrl = `${config.IOS_PATCH_SYSTEM_BASE_URL}/publisher/patch/v1/content`;
  public manifestState: ManifestLine[] = [];

  public async getManifest(): Promise<void> {
    if (this.manifestState.length > 0) {
      return;
    }
    const response = await fetch(`${this.apiBaseUrl}/manifest.txt`);
    const textResponse = await response.text();
    this.manifestState = textResponse.split('\n').map((line) => {
      const array = line.split(' ');
      return {
        file: array[0],
        md5sum: array[1],
      };
    });
  }

  public async getConfig(): Promise<ConfigFile> {
    await this.getManifest();
    const configJsonLocation = this.manifestState.find((item) => {
      return item.file.endsWith('/config.json');
    });
    const localResult = localStorage.getItem('iosPatchSystemConfig');

    if (localResult) {
      const iosPatchSystemConfigLocal: IosPatchSystemConfig = JSON.parse(localResult);
      if (iosPatchSystemConfigLocal.configJsonLocation.md5sum === configJsonLocation?.md5sum) {
        return iosPatchSystemConfigLocal.configFile;
      }
    }

    const configFileResponse = await fetch(`${this.apiBaseUrl}/${configJsonLocation?.file}`);
    const configFile = await configFileResponse.json();
    const iosPatchSystemConfig = {
      configFile,
      configJsonLocation,
    };
    localStorage.setItem('iosPatchSystemConfig', JSON.stringify(iosPatchSystemConfig));

    return configFile;
  }

  public resetStore() {
    this.manifestState = [];
  }
}

export default new IosPatchSystemApi();
