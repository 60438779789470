
import { defineComponent, ref, watch, nextTick, onUpdated } from 'vue';
import type { Ref } from 'vue';
import { useTitle } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router/composables';
import { RsButton } from '@/lib';
import { PUBLISH_LTK } from '@/utils/router/namedRoutes';

export default defineComponent({
  name: 'PublishingHeader',
  emits: { close: () => true },
  components: { RsButton },
  setup(props, { emit }) {
    const title = ref(null) as Ref<string | null | undefined>;
    const route = useRoute();
    const router = useRouter();
    const closeable = ref(false);
    const mainRef = ref(null) as Ref<HTMLElement | null>;
    const showDefault = ref(false) as Ref<boolean | null>;

    const handleBack = () => {
      router.go(-1);
    };

    // Will handle closing the webview once a method for doing do had been established.
    const handleClose = () => {
      emit('close');
    };

    // TODO: Explore limitations with this approach when working with i18n and if changing this at runtime is
    onUpdated(() => {
      if (showDefault.value) {
        setTimeout(() => {
          const pageTitle = useTitle();
          title.value = pageTitle.value;
        }, 0);
      }
    });

    watch(
      () => route.name,
      async (next) => {
        closeable.value = next === PUBLISH_LTK;
        await nextTick();
        showDefault.value = false || (mainRef.value && mainRef.value.children?.length === 1);
      },
      { immediate: true },
    );

    return {
      title,
      closeable,
      mainRef,
      showDefault,
      handleBack,
      handleClose,
    };
  },
});
