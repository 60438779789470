import { computed, ref, watchEffect, ComputedRef, Ref } from 'vue';

interface CheckboxProps {
  modelValue: any | null;
  value?: any | null | undefined;
  name: string;
  required?: boolean;
  disabled: boolean;
  trueValue: any;
  falseValue: any;
  rules: Array<(value: any) => string | boolean>;
  id: string;
  size: string;
}

const useCheckbox = (props: Readonly<CheckboxProps>, emit: any) => {
  const checkboxRef = ref(null) as Ref<HTMLElement | null>;
  const isDirty = ref(false);
  const isError = ref(false);
  const errorMessage = ref('') as Ref<string | boolean>;

  const hasValue: ComputedRef<boolean> = computed(() => {
    return props.value !== null;
  });

  const isModelArray: ComputedRef<boolean> = computed(() => {
    return Array.isArray(props.modelValue);
  });

  const isChecked: ComputedRef<boolean> = computed(() => {
    if (isModelArray.value) return props.modelValue.includes(props.value);
    else if (hasValue.value) return props.modelValue === props.value;
    else return props.modelValue === props.trueValue;
  });

  const handleDirty = () => {
    isDirty.value = true;
  };

  const hasErrors = computed(() => {
    if (props.rules.length > 0 && isDirty.value) {
      for (let index = 0, { length } = props.rules; index < length; index++) {
        errorMessage.value = props.rules[index](isChecked.value);
        if (typeof errorMessage.value === 'string' && errorMessage.value !== 'true') {
          const error = {
            isValid: !errorMessage.value,
            errorMessage: errorMessage.value || '',
            fieldValue: isChecked.value,
            index,
          };
          emit('error', error);
          return error;
        }
      }
    }
    const error = {
      isValid: true,
      errorMessage: '',
      fieldValue: isChecked.value,
      index: -1,
    };
    emit('error', error);
    return error;
  });

  const removeItemFromModel = (newModel: any) => {
    const index = newModel.indexOf(props.value);
    if (index !== -1) newModel.splice(index, 1);
  };

  const handleArrayCheckbox = (event: Event) => {
    const newModel = props.modelValue;
    if (Array.isArray(props.modelValue) && !isChecked.value) {
      newModel.push(props.value);
      emit('checked', event);
    } else {
      removeItemFromModel(newModel);
      emit('unchecked', event);
    }
    emit('update:modelValue', newModel);
  };

  const handleSingleSelectCheckbox = (event: Event) => {
    if (isChecked.value) {
      emit('update:modelValue', null);
      emit('unchecked', event);
    } else {
      emit('update:modelValue', props.value);
      emit('checked', event);
    }
  };

  const handleSimpleCheckbox = (event: Event) => {
    if (isChecked.value) {
      emit('update:modelValue', props.falseValue);
      emit('unchecked', event);
    } else {
      emit('update:modelValue', props.trueValue);
      emit('checked', event);
    }
  };

  const onChecked = (event: Event) => {
    handleDirty();
    emit('click', event);
    if (!props.disabled) {
      if (isModelArray.value) handleArrayCheckbox(event);
      else if (hasValue.value) handleSingleSelectCheckbox(event);
      else handleSimpleCheckbox(event);
    }
  };

  watchEffect(
    () => {
      if (checkboxRef.value instanceof HTMLElement && !isDirty.value)
        checkboxRef.value.addEventListener('blur', handleDirty, { passive: true });
      checkboxRef.value?.removeEventListener('blur', handleDirty);

      isError.value = !hasErrors.value.isValid;
    },
    { flush: 'post' },
  );

  return {
    checkboxRef,
    isChecked,
    onChecked,
    isDirty,
    isError,
    errorMessage,
  };
};

export default useCheckbox;
