import { Ref, ref } from 'vue';

/**
 * Declare as uninitialized due to composable being used by the router before the Composition API Plugin is registered with Vue.
 */
let error: Ref<Error | null> | undefined;

/**
 *
 * @description Composable for storing router errors related to the Navigation Guards.
 */
export function useRouterError() {
  // Initialize a single instance of the error for all consumers
  if (error === undefined) error = ref<Error | null>(null);
  return { error };
}
