
import { defineComponent, nextTick, ref, watch, onUnmounted } from 'vue';
import { RwFocusTrap } from '@/lib/components/RwFocusTrap';
import { scrollLock } from '@/lib/utils/scrollLock';
import { uniqueId } from '@/utils/uniqueId';
import { Portal } from '@linusborg/vue-simple-portal';
export default defineComponent({
  name: 'RsSlideUpCard',
  components: { RwFocusTrap, Portal },
  emits: ['update:modelValue', 'back-event', 'close-card', 'opened', 'closed'],
  props: {
    title: {
      type: String,
      default: 'title',
    },
    portalTo: {
      type: String,
      default: '#modal-portal',
    },
    portalDisabled: {
      type: Boolean,
      default: false,
    },
    headerIcon: {
      type: String,
    },
    showCloseOption: {
      type: Boolean,
      default: true,
    },
    showBackOption: {
      type: Boolean,
      default: true,
    },
    showHeaderWithTitle: {
      type: Boolean,
      default: true,
    },
    allowOutsideClick: {
      type: Boolean,
      default: true,
    },
    allowEscClose: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: true,
    },
    trapActive: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  setup(props, content) {
    const isOpen = ref(props.modelValue);
    const modalRef = ref<HTMLElement | null>(null);

    const handleBack = () => {
      isOpen.value = false;
      content.emit('back-event');
    };

    const handleClose = () => {
      isOpen.value = false;
      content.emit('close-card');
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        isOpen.value = newValue;
      },
    );

    const id = uniqueId();

    watch(
      isOpen,
      (newValue) => {
        content.emit('update:modelValue', newValue);
        scrollLock(id, newValue);
      },
      { immediate: true },
    );

    onUnmounted(() => {
      // Ensure that if this component is removed, the scroll lock is released.
      // Watcher may not fire a final time depending how how the card is removed
      scrollLock(id, false);
    });

    const getClasses = () => {
      let classes = [];
      if (!props.showHeaderWithTitle) {
        classes.push('show-header-false');
      }
      if (props.fullHeight) {
        classes.push('full-height');
      } else {
        classes.push('fit-content');
      }
      return classes;
    };

    const dispatchEvents = async (state: string) => {
      await nextTick();
      window.dispatchEvent(new Event('resize'));
      if (state === 'enter') {
        content.emit('opened');
      }
      if (state === 'leave') {
        content.emit('closed');
      }
    };

    return { dispatchEvents, getClasses, handleBack, handleClose, isOpen, modalRef };
  },
});
