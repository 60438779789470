
import { computed, defineComponent } from 'vue';
export default defineComponent({
  name: 'RwOverlay',
  props: {
    active: Boolean,
    fixed: Boolean,
    blur: Boolean,
    transparent: Boolean,
  },
  emits: ['click'],
  setup(props, { emit }) {
    const overlayClasses = computed(() => {
      return {
        'is--fixed': props.fixed,
        'is--blur': props.blur,
        'is--transparent': props.transparent,
      };
    });

    const onClick = () => {
      emit('click');
    };

    return {
      overlayClasses,
      onClick,
    };
  },
});
