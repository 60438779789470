import { auth } from '@/utils/auth';
import { processResponse, Api } from '@/utils/api';
import { MultipleProductReviewsResponse } from './types';

const config = window.rewardStyle;

class ProductReviewsApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/pub/v1/product_reviews/`;

  private get accountID() {
    return auth.getIdTokenClaims().then((claims) => claims?.account_id?.toString());
  }

  public async get(productIds: string[]) {
    const userAccountId = await this.accountID;

    const productIdsSet = new Set(
      productIds.map((id) => {
        const [rawId, type] = id.split(':');
        return `ids[]=${userAccountId}_${rawId}`;
      }),
    );

    const productIdsParams = [...productIdsSet].join('&');
    const reviewsResponse = await this.sendRequest('GET', `?${productIdsParams}`);

    return await processResponse<MultipleProductReviewsResponse>(reviewsResponse);
  }
}

export default new ProductReviewsApi();
