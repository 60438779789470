import {
  UserEventsPublisher,
  UserEventType,
  UserEvent,
  ExtractEventData,
  Nullable,
  UserPropertyParams,
  ExtractedExtendedEventData,
} from './types';
import { UserEventsSubscriber } from '@/utils/UserEvents/userEventSubscriber';

export class UserEvents implements UserEventsPublisher {
  listeners: UserEventsSubscriber[] = [];

  subscribe(listener: UserEventsSubscriber) {
    this.listeners.push(listener);
  }

  unsubscribe(listener: UserEventsSubscriber) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  async addUserAttributes(attributes: Partial<UserPropertyParams>) {
    await Promise.all(this.listeners.map((l) => l.addUserAttributes(attributes)));
  }

  /**
   * This function is heavily coupled to Amplitude, considering the first two parameters
   * are for Amplitude and the third one for every other subscriber.
   *
   * Todo: Create a plan on how to deprecate this function and
   *   develop a new one that has a better interface to support multiple subscribers
   */
  async sendEvent<T extends UserEventType>(
    type: T,
    data?: Nullable<ExtractEventData<UserEvent, T>>,
    additionalData?: Partial<ExtractedExtendedEventData<UserEvent, T>>,
  ) {
    // Subscribers should handle their own error handling and logging,
    // because we don't want to wait for this call to finish
    await Promise.all(this.listeners.map((l) => l.sendEvent(type, data, additionalData)));
  }
}
