import { ProductImage } from '@/store/products/types';
import { processResponse, Api } from '@/utils/api';

export interface MultipleProductImagesResponse {
  retailer_product_images: ProductImage[];
}

const config = window.rewardStyle;
class RetailerProductImagesApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/pub/v1/products/retailer_product_images/`;

  public async get(imageIds: string[]) {
    const imageIdsSet = new Set(imageIds.map((id) => `ids[]=${id}`));
    const imageIdsParams = [...imageIdsSet].join('&');
    const imagesResponse = await this.sendRequest('GET', `?${imageIdsParams}`);

    return await processResponse<MultipleProductImagesResponse>(imagesResponse);
  }
}

export default new RetailerProductImagesApi();
