import { processResponse, Api } from '@/utils/api';
import type {
  Application,
  ReferralInfo,
  CountryResponse,
  SocialPlaformResponse,
  QuestionsResponse,
} from '@/types/application';
import type { CreateInstagramAuthURLInternalResponse, InstagramAuthURLConfigurationId } from '@/types/social/instagram';

const config = window.rewardStyle;

class ApplicationsApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api`;

  private async get<T>(path: string): Promise<T> {
    const response = await this.sendRequest('GET', `/rs/v1${path}`);
    return processResponse<T>(response);
  }

  private async post<T>(path: string, body: unknown): Promise<T> {
    const url = path.includes('referrals') ? `/auth/rs/v1${path}` : `/rs/v1${path}`;
    const response = await this.sendRequest('POST', url, { body: JSON.stringify(body) });
    return processResponse<T>(response);
  }

  // Used in useCountries()
  public async getCountries() {
    const response = await this.get<CountryResponse>('/countries/');
    return response.countries;
  }

  // Used in useSocialPlatforms()
  public async getPlatforms() {
    const response = await this.get<SocialPlaformResponse>('/platforms/');
    return response.platforms;
  }

  /**
   * Creates a new Instagram Auth URL for initiating the [Facebook Login Flow (Business Login for Instagram)](https://developers.facebook.com/docs/instagram/business-login-for-instagram).
   */
  public async createInstagramAuthUrl(redirectUrl: string, configurationId: InstagramAuthURLConfigurationId) {
    return await this.get<CreateInstagramAuthURLInternalResponse>(
      `/verifications/instagram/url?redirect_url=${encodeURIComponent(
        redirectUrl,
      )}&configuration_id=${encodeURIComponent(configurationId)}`,
    );
  }

  // TODO: [FILL YOUR SHOP] Implement our internal version of the [MIS /v1/auth/{accountID}/status API call](http://qa-meta-integration-service.ltklocal/swagger/#/auth/ServiceGetAuthStatus).
  public async getInstagramAuthStatus(accountId: string) {}

  // Used in useExperienceQuestions()
  public async getQuestions() {
    const response = await this.get<QuestionsResponse>('/applications/questions');
    return response;
  }

  /**
   * NOTE! This function returns only the status code.
   * This endpoint does not have a payload.
   *
   * @param email
   */
  public async getEmailExists(email: string) {
    const response = await this.sendRequest('GET', `/creator-account-service-public/v1/email-exists/${email}`);
    return response.status;
  }

  public async submitApplication(body: Application) {
    const response = await this.post('/applications/', body);
    return response;
  }

  public async getReferral(refID: string) {
    return await this.get<ReferralInfo>(`/referrals/${refID}?includes[]=applicants`);
  }

  public async submitReferral(body: unknown) {
    return await this.post('/referrals/', body);
  }
}

export default new ApplicationsApi();
