import Vue from 'vue';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  FoldersServiceMeta,
  MultipleFolderResponse,
  FolderMap,
  Folder,
  FoldersState,
  FolderList,
  FolderListName,
} from '@/store/folders/types';
import foldersApi, { SearchParams } from '@/store/folders/api';
import favoritesStore from '@/store/favorites/index';
import { ApiErrorResponse } from '@/utils/api/types';
import { FolderSortOptions } from './constants';
import { MyFolderSnackbarEvent } from '@/rs-folders/utils/types';
import { MyFoldersSnackbarIconOptions, MyFoldersSnackbarThemeOptions } from '@/rs-folders/utils/constants';
import { DeleteMyFolderFavoriteParams } from '../favorites/types';

@Module({ dynamic: true, name: 'folders', store, namespaced: true })
class Folders extends VuexModule implements FoldersState {
  public folderMap: FolderMap = {};
  public folderQuery: string = '';
  public serverError: ApiErrorResponse | null = null;
  public myFolderSnackbarEvent: MyFolderSnackbarEvent = {
    show: false,
    icon: MyFoldersSnackbarIconOptions.DEFAULT,
    theme: MyFoldersSnackbarThemeOptions.DEFAULT,
    message: null,
    url: null,
  };
  public folderSortParameter: FolderSortOptions = FolderSortOptions.NAME_ASCENDING;
  public folderNav: FolderList = {
    folderIds: [],
    meta: null,
  };
  public folderTable: FolderList = {
    folderIds: [],
    meta: null,
  };
  public searchedFolders: FolderList = {
    folderIds: [],
    meta: null,
  };

  get getFolderById() {
    return (folderId: string) => this.folderMap[folderId];
  }

  get getFolderNameById() {
    return (folderId: string) => this.folderMap[folderId]?.name;
  }

  get hasExactFolderName() {
    return (folderName: string) =>
      Boolean(
        this.folderTable.folderIds.find(
          (folderId) => this.folderMap[folderId]?.name.toLowerCase() === folderName.toLowerCase(),
        ),
      );
  }

  get navHasMorePages() {
    return this.folderNav.meta !== null && this.folderNav.meta.last_id !== null;
  }

  get tableHasMorePages() {
    return this.folderTable.meta !== null && this.folderTable.meta.last_id !== null;
  }

  @Action
  public async sortFoldersTable(value: FolderSortOptions) {
    this.UPDATE_FOLDER_SORT_PARAMETER(value);
    await this.fetchFolderPage({ forceInitialFetch: true });
  }

  @Action
  public resetQueryAndSort() {
    if (this.folderQuery !== '') {
      this.UPDATE_SEARCH_QUERY('');
    }
    if (this.folderSortParameter !== FolderSortOptions.NAME_ASCENDING) {
      this.UPDATE_FOLDER_SORT_PARAMETER(FolderSortOptions.NAME_ASCENDING);
    }
  }

  @Action
  public async fetchFolderId(folderId: string) {
    try {
      const folder = await foldersApi.getOne(folderId || '');

      this.ADD_FOLDER(folder.folder);
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async fetchFoldersById({
    folderIds,
    sortOrder = FolderSortOptions.NAME_ASCENDING,
  }: {
    folderIds: string[];
    sortOrder: FolderSortOptions;
  }) {
    try {
      const res = await foldersApi.getMultiple({ folderIds, sortOrder });
      res.folders.forEach((folder) => {
        this.ADD_FOLDER(folder);
      });
      return res.folders;
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
      return [];
    }
  }

  @Action
  public async fetchAllFolders() {
    while (this.folderTable.meta?.last_id !== null) {
      await this.fetchFolderPage();
    }
  }

  /*
    Separate value for folders in the side nav because
      their filtering handled differently than
      those in the folders table.
  */
  @Action
  public async fetchFoldersNav() {
    if (this.folderNav.folderIds.length > 0 && !this.navHasMorePages) {
      return;
    }
    const lastId = this.folderNav.meta ? this.folderNav.meta.last_id : null;
    const removeUncategorizedFolder = false;
    const removeReviewedFolder = false;
    const folders = await foldersApi.get(
      lastId,
      FolderSortOptions.NAME_ASCENDING,
      removeUncategorizedFolder,
      removeReviewedFolder,
    );
    this.SET_FOLDER_MAP_AND_NAV(folders);
  }

  @Action
  public async updateNavigationFolderCounts({
    addedFolderIds,
    deletedFolderIds,
  }: {
    addedFolderIds: string[];
    deletedFolderIds: string[];
  }) {
    for (const folderId of addedFolderIds) {
      this.INCREASE_FOLDER_NAV_FAVORITE_COUNT(folderId);
    }

    for (const folderId of deletedFolderIds) {
      this.DECREASE_FOLDER_NAV_FAVORITE_COUNT(folderId);
    }
  }

  /*
    For generic fetching of folders without a search query.
    Used to preload folder data into the store
  */
  @Action
  public async fetchFolderPage({
    ignoreLastId = false,
    forceInitialFetch = false,
  }: {
    ignoreLastId?: boolean;
    forceInitialFetch?: boolean;
  } = {}) {
    const lastId = !ignoreLastId && this.folderTable.meta && !forceInitialFetch ? this.folderTable.meta.last_id : null;
    const removeUncategorizedFolder = false;
    const removeReviewedFolder = false;
    const folders = await foldersApi.get(
      lastId,
      this.folderSortParameter,
      removeUncategorizedFolder,
      removeReviewedFolder,
    );

    /*
      Fetches data for the most_recent_favorite_id of each folder,
      which is then stored and used to fetch product images
    */
    await favoritesStore.fetchFolderFavorites({ folders });

    lastId === null ? this.FETCH_FIRST_FOLDERS(folders) : this.FETCH_FOLDER(folders);
  }

  @Action
  public async fetchProductsFolderPage(ignoreLastId: boolean = false) {
    const lastId = !ignoreLastId && this.folderTable.meta ? this.folderTable.meta.last_id : null;
    const removeUncategorizedFolder = false;
    const folders = await foldersApi.get(lastId, this.folderSortParameter, removeUncategorizedFolder);
    await favoritesStore.fetchFolderFavorites({ folders });

    const folderCommitType = lastId === null ? this.FETCH_FIRST_FOLDERS : this.FETCH_FOLDER;
    folderCommitType(folders);
  }

  @Action
  public async addFolder(folderName: string) {
    try {
      const response = await foldersApi.create(folderName);
      this.SET_SERVER_ERROR(null);
      this.ADD_FOLDER_TO_LIST({ newFolder: response.folder, listName: 'folderNav' });
      this.ADD_FOLDER_TO_LIST({ newFolder: response.folder, listName: 'folderTable' });
      await favoritesStore.addFolderFavorite(response.folder.id);
      await favoritesStore.updateFavoriteFolders();
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async searchProductFolders(query: string) {
    if (query === '') {
      return await this.fetchProductsFolderPage(true);
    }

    const folders = await foldersApi.search({ query });
    await favoritesStore.fetchFolderFavorites({ folders });

    this.FETCH_FIRST_FOLDERS(folders);
  }

  @Action
  public async searchFolders(query?: string) {
    if (query === undefined || query === '') {
      this.UPDATE_SEARCH_QUERY('');
      return;
    }

    const meta = this.searchedFolders.meta;
    const data: SearchParams = {
      query: query,
    };

    if (meta?.next_url === null && this.folderQuery === query) {
      return;
    }

    if (meta?.next_url !== undefined && this.folderQuery === query) {
      data.limit = meta.limit;
      data.offset = this.searchedFolders.folderIds.length;
    }

    try {
      const removeReviewedFolder: boolean = true;
      const removeUncategorizedFolder: boolean = false;
      const folders = await foldersApi.search(data, removeReviewedFolder, removeUncategorizedFolder);
      await favoritesStore.fetchFolderFavorites({ folders, fetchProducts: false });

      if (data.offset) {
        this.SEARCH_FOLDER(folders);
      } else {
        this.SEARCH_FIRST_FOLDERS(folders);
        this.UPDATE_SEARCH_QUERY(query);
      }
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async patchFolder(folder: Folder) {
    try {
      const response = await foldersApi.patch(folder);
      this.SET_SERVER_ERROR(null);
      this.ADD_FOLDER(response.folder);

      /*
        If folder's name was changed update its index in the folder nav,
      */
      if (folder.name !== response.folder.name) {
        this.DELETE_FOLDER_FROM_FOLDER_NAV(folder.id);
        this.ADD_FOLDER_TO_LIST({ newFolder: response.folder, listName: 'folderNav' });
      }
      return response.folder;
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async deleteFolder(folderId: string) {
    try {
      const response = await foldersApi.delete(folderId);
      this.SET_SERVER_ERROR(null);
      this.DELETE_FOLDER_FROM_FOLDER_TABLE(folderId);
      this.DELETE_FOLDER_FROM_FOLDER_MAP(folderId);
      this.DELETE_FOLDER_FROM_FOLDER_NAV(folderId);
      return response;
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async createFolder(folderName: string) {
    try {
      const response = await foldersApi.create(folderName);
      this.SET_SERVER_ERROR(null);
      this.ADD_FOLDER_TO_LIST({ newFolder: response.folder, listName: 'folderNav' });
      this.ADD_FOLDER_TO_LIST({ newFolder: response.folder, listName: 'folderTable' });
      await favoritesStore.addFolderFavorite(response.folder.id);
      await favoritesStore.updateFavoriteFolders();
      return response.folder;
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Mutation
  public FETCH_FIRST_FOLDERS(folderResponse: MultipleFolderResponse) {
    const folderIds: string[] = [];

    folderResponse.folders.forEach((folder) => {
      folderIds.push(folder.id);
      Vue.set(this.folderMap, folder.id, folder);
    });

    this.folderTable.folderIds.splice(0, this.folderTable.folderIds.length, ...folderIds);

    Vue.set(this.folderTable, 'meta', folderResponse.meta);
  }

  @Mutation
  public FETCH_FOLDER(folderResponse: MultipleFolderResponse) {
    const folderIds = new Set(this.folderTable.folderIds);

    folderResponse.folders.forEach((folder) => {
      folderIds.add(folder.id);
      Vue.set(this.folderMap, folder.id, folder);
    });

    Vue.set(this.folderTable, 'folderIds', [...folderIds]);
    Vue.set(this.folderTable, 'meta', folderResponse.meta);
  }

  @Mutation
  public SEARCH_FIRST_FOLDERS(folderResponse: MultipleFolderResponse) {
    const folderIds: string[] = [];

    folderResponse.folders.forEach((folder) => {
      folderIds.push(folder.id);
      Vue.set(this.folderMap, folder.id, folder);
    });

    this.searchedFolders.folderIds.splice(0, this.searchedFolders.folderIds.length, ...folderIds);
    Vue.set(this.searchedFolders, 'meta', folderResponse.meta);
  }

  @Mutation
  public SEARCH_FOLDER(folderResponse: MultipleFolderResponse) {
    const folderIds = new Set(this.searchedFolders.folderIds);

    folderResponse.folders.forEach((folder) => {
      folderIds.add(folder.id);
      Vue.set(this.folderMap, folder.id, folder);
    });

    Vue.set(this.searchedFolders, 'folderIds', [...folderIds]);
    Vue.set(this.searchedFolders, 'meta', folderResponse.meta);
  }

  @Mutation
  public ADD_FOLDER(folder: Folder) {
    Vue.set(this.folderMap, folder.id, folder);
  }

  @Mutation
  public ADD_FOLDER_TO_LIST({ newFolder, listName }: { newFolder: Folder; listName: FolderListName }) {
    Vue.set(this.folderMap, newFolder.id, newFolder);

    const newFolderIndex = this[listName].folderIds
      .map((id) => this.folderMap[id]?.name as string)
      .findIndex((name) => newFolder.name.localeCompare(name) <= 0);

    // checks to see if the added folder index is 0 or above
    // if it is then splice it into the list of available folders in the nav
    // if the folder index is *not* 0 or above, then it moves onto the else if
    // it checks if the pagination is complete, and if so to add the folder to the end of the list.
    // if the pagination is *not* complete then nothing happens
    // it will be called again upon the next pagination, until the folder is
    // inserted into the correct alphabetical position.

    const meta = this[listName].meta;

    if (newFolderIndex !== -1) {
      this[listName].folderIds.splice(newFolderIndex, 0, newFolder.id);
    } else if (meta !== null && meta.last_id === null) {
      this[listName].folderIds.push(newFolder.id);
    }
  }

  @Mutation
  public INCREASE_FOLDER_NAV_FAVORITE_COUNT(folderId: string) {
    const navItem = this.folderMap[folderId];
    if (!navItem) {
      return;
    }
    navItem.favorite_count++;
  }

  @Mutation
  public DECREASE_FOLDER_NAV_FAVORITE_COUNT(folderId: string) {
    const navItem = this.folderMap[folderId];
    if (!navItem) {
      return;
    }
    navItem.favorite_count--;
  }

  @Mutation
  public DELETE_FOLDER_FROM_FOLDER_MAP(folderId: string) {
    const newFolderMap = Object.assign({}, this.folderMap);
    delete newFolderMap[folderId];
    Vue.set(this, 'folderMap', newFolderMap);
  }

  @Mutation
  public DELETE_FOLDER_FROM_FOLDER_TABLE(folderId: string) {
    const folderIds = this.folderTable.folderIds.filter((id) => id !== folderId);
    Vue.set(this.folderTable, 'folderIds', folderIds);
  }

  @Mutation
  public DELETE_FOLDER_FROM_FOLDER_NAV(folderId: string) {
    const folderIds = this.folderNav.folderIds.filter((id) => id !== folderId);
    Vue.set(this.folderNav, 'folderIds', folderIds);
  }

  @Mutation
  public UPDATE_SEARCH_QUERY(query?: string) {
    this.folderQuery = query ?? '';
  }

  @Mutation
  public UPDATE_FOLDER_SORT_PARAMETER(newFolderSortParameter: FolderSortOptions) {
    this.folderSortParameter = newFolderSortParameter;
  }

  @Mutation
  public SET_SERVER_ERROR(error: ApiErrorResponse | null) {
    Vue.set(this, 'serverError', error);
  }

  @Mutation
  public SET_FOLDER_MAP_AND_NAV(folderResponse: MultipleFolderResponse) {
    const existingIDs = [...this.folderNav.folderIds];
    folderResponse.folders.forEach((folder) => {
      if (existingIDs.indexOf(folder.id) === -1) {
        existingIDs.push(folder.id);
      }
      Vue.set(this.folderMap, folder.id, folder);
    });

    Vue.set(this.folderNav, 'folderIds', existingIDs);
    Vue.set(this.folderNav, 'meta', folderResponse.meta);
  }

  @Mutation
  public SET_FOLDER_SNACKBAR_EVENT(newSnackbarEvent: MyFolderSnackbarEvent) {
    this.myFolderSnackbarEvent = newSnackbarEvent;
  }

  @Mutation
  public RESET_STORE() {
    Vue.set(this, 'folderMap', {});
    Vue.set(this, 'folderQuery', '');
    Vue.set(this, 'serverError', null);
    Vue.set(this, 'folderSortParameter', FolderSortOptions.NAME_ASCENDING);
    Vue.set(this, 'folderNav', { folderIds: [], meta: null });
    Vue.set(this, 'folderTable', { folderIds: [], meta: null });
    Vue.set(this, 'searchedFolders', { folderIds: [], meta: null });
  }
}

// Subscriptions for actions / mutations in other stores.
store.subscribeAction(async (action) => {
  switch (action.type) {
    case 'favorites/deleteFavorite':
      const deletePayload: DeleteMyFolderFavoriteParams = action.payload;
      await getModule(Folders).updateNavigationFolderCounts({
        addedFolderIds: [],
        deletedFolderIds: deletePayload.deleted_folder_ids ?? [],
      });
      return;
  }
});

export default getModule(Folders);
