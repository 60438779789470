export enum ApplicationStatus {
  NEW = 'new',
  PENDING = 'pending',
  APPROVED = 'approve',
  DECLINED = 'declined',
}

export interface ApplicantStatus {
  applicant_id: string;
  resolution_id: ApplicationStatus | string;
  application_created: string;
  can_reapply: boolean;
}

export interface ApplicationStatusResponse {
  applicant_status: ApplicantStatus;
}
