// object with id as key and value as newValue boolean
let componentLockMap: { [key: string]: boolean } = {};

// Computes wether there is at least 1 component with a scroll lock at the time the function is invoked
function hasGlobalLock() {
  return Object.values(componentLockMap).some((componentHasLock) => componentHasLock) === true;
}

export const scrollLock = (id: string, newValue: boolean) => {
  // Determine if at least 1 component already has the global lock active
  const wasPreviouslyLocked = hasGlobalLock();

  // Update the global lock state
  componentLockMap[id] = newValue;

  // Determine if at least 1 component now has the global lock active
  const isCurrentlyLocked = hasGlobalLock();
  // either going from locked to unlocked, or unlocked to locked
  const isLockTransition = isCurrentlyLocked !== wasPreviouslyLocked;

  // State not transitioning, either already locked or unlocked
  // so nothing needs to happen, do not add/remove scroll lock
  if (isLockTransition === false) {
    return;
  }

  if (newValue) {
    // only when all values are false and the newvalue boolean is true, then add the class
    document.body.classList.add('is--scroll-locked');
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.left = '0';
    document.body.style.right = '0';
  } else {
    // only when all values are false then remove the class
    const scrollY = document.body.style.top;
    document.body.classList.remove('is--scroll-locked');
    document.body.removeAttribute('style');
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    // If all components have unlocked, reset the map
    componentLockMap = {};
  }
};
