
import Vue, { defineComponent, computed, watch, ref, PropType, getCurrentInstance } from 'vue';
import { AlertStyles } from './alertStyles';

export default defineComponent({
  name: 'RsAlertBar',
  emits: ['click', 'input'],
  props: {
    value: {
      type: Boolean,
      required: true,
      default: true,
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
    setAssertive: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    alertStyle: {
      type: String as PropType<AlertStyles>,
      required: false,
      default: AlertStyles.WHITE,
    },
  },
  setup(props, { emit, slots }) {
    const vm = getCurrentInstance();
    const { $gettext } = vm?.proxy as Vue;

    const ariaLive = ref<'off' | 'assertive' | 'polite'>(props.setAssertive ? 'assertive' : 'polite');
    const showAlert = ref<boolean>(props.value);

    const disableAlert = (e: Event) => {
      showAlert.value = false;
      emit('input', false);
      emit('click', e);
    };
    const closeMessage = computed(() => $gettext('Close alert'));

    watch(
      () => props.value,
      (next) => {
        showAlert.value = next;
      },
    );

    return {
      ariaLive,
      showAlert,
      disableAlert,
      closeMessage,
      slots,
    };
  },
});
