import { ExchangeRate, ExchangeRatesState, ExchangeRateResponse } from '@/store/exchangeRate/types';
import { ApiError } from '@/utils/api';
import Vue from 'vue';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import ExchangeRateApi from '@/store/exchangeRate/api';

@Module({ dynamic: true, name: 'ExchangeRates', store })
class ExchangeRateStore extends VuexModule implements ExchangeRatesState {
  public exchangeRates: ExchangeRate = {
    AED: '1',
    ARS: '1',
    AUD: '1',
    BBD: '1',
    BGN: '1',
    BRL: '1',
    CAD: '1',
    CHF: '1',
    CLP: '1',
    CNY: '1',
    COP: '1',
    CRC: '1',
    CZK: '1',
    DKK: '1',
    DZD: '1',
    EGP: '1',
    EUR: '1',
    GBP: '1',
    GTQ: '1',
    HKD: '1',
    HNL: '1',
    HRK: '1',
    HUF: '1',
    IDR: '1',
    ILS: '1',
    INR: '1',
    IRR: '1',
    JPY: '1',
    KHR: '1',
    KRW: '1',
    KWD: '1',
    LBP: '1',
    LVL: '1',
    MAD: '1',
    MMK: '1',
    MNT: '1',
    MOP: '1',
    MXN: '1',
    MYR: '1',
    NOK: '1',
    NZD: '1',
    PEN: '1',
    PHP: '1',
    PKR: '1',
    PLN: '1',
    QAR: '1',
    RON: '1',
    RUB: '1',
    SAR: '1',
    SEK: '1',
    SGD: '1',
    THB: '1',
    TRY: '1',
    TWD: '1',
    UAH: '1',
    USD: '1',
    VEF: '1',
    VND: '1',
    ZAR: '1',
  };

  public hasFetchedExchangeRates = false;

  public serverError: ApiError | null = null;

  /**
   * @description Fetch exchange rates
   * @param {Object} options - Options object
   * @param {boolean} options.overwrite - Determines whether the action fetches and overwrites the data. Set to false (cache data from first action call) by default
   */
  @Action
  public async fetchExchangeRates({ overwrite = false }) {
    const canOverwrite = overwrite || !this.hasFetchedExchangeRates;
    try {
      if (canOverwrite) {
        const response = await ExchangeRateApi.get();
        this.UPDATE_EXCHANGE_RATES(response);
        this.EXCHANGE_RATE_SERVER_ERROR(null);
        this.HAS_FETCHED_EXCHANGE_RATES(true);
      }
    } catch (error) {
      this.EXCHANGE_RATE_SERVER_ERROR(error as ApiError | null);
    }
  }

  @Mutation
  public UPDATE_EXCHANGE_RATES(exchangeRateResponse: ExchangeRateResponse) {
    Vue.set(this, 'exchangeRates', exchangeRateResponse.exchange_rates);
  }

  @Mutation
  public HAS_FETCHED_EXCHANGE_RATES(hasFetchedExchangeRates: boolean) {
    this.hasFetchedExchangeRates = hasFetchedExchangeRates;
  }

  @Mutation
  public EXCHANGE_RATE_SERVER_ERROR(error: ApiError | null) {
    this.serverError = error;
  }
}

export default getModule(ExchangeRateStore);
