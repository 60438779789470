import { auth } from '@/utils/auth';

export default class Api {
  public apiBaseUrl = 'https://localhost/';

  public async sendRequest(method: string, path: string, options: RequestInit = {}) {
    const token = await auth.getAccessToken();

    const { mode = 'cors', headers = { 'Content-Type': 'application/json' }, ...requestOptions } = options;

    return await fetch(
      `${this.apiBaseUrl}${path}`,
      Object.assign(
        {
          method,
          mode,
          headers: token ? Object.assign({ Authorization: `Bearer ${token}` }, headers) : headers,
        },
        requestOptions,
      ),
    );
  }
}
