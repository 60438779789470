
import { defineComponent, getCurrentInstance, computed } from 'vue';

import { GlobalElementId } from '@/utils/globalElementIds';
import { RsButton } from '@/lib';

export default defineComponent({
  name: 'SiteHeaderApplication',
  components: { RsButton },
  setup(props, context) {
    const vm = getCurrentInstance();
    const { $gettext } = vm?.proxy as Vue;

    const loginURL = window.rewardStyle.SIGIL_URL;
    const loginText = computed(() => $gettext('Log in'));

    return {
      GlobalElementId,
      loginURL,
      loginText,
    };
  },
});
