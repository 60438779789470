
import { defineComponent, computed, watch, watchEffect } from 'vue';
import { useSlider } from './useSlider';
import { toTime } from '@/lib/utils/toSeconds';
export default defineComponent({
  name: 'LtkSliderKnob',
  props: {
    target: {
      type: HTMLDivElement,
      required: false,
    },
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 0.1,
    },
    useWheel: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'circle',
    },
    start: {
      type: Boolean,
      default: false,
    },
    end: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    event: 'update:modelValue',
    prop: 'modelValue',
  },
  emits: ['update:modelValue', 'change', 'move'],
  setup(props, { emit }) {
    const {
      init,
      computedX,
      x,
      minX,
      maxX,
      dragStartX,
      handleWidth,
      handleHeight,
      handleOffset,
      unit,
      pctComplete,
      isHover,
      isDragging,
      handle,
      hasFocus,
      result,
    } = useSlider(props, emit);

    watch(
      () => result.value,
      (next) => {
        emit('update:modelValue', next);
      },
      {
        immediate: true,
      },
    );

    const timeValue = computed(() => {
      return toTime(props.modelValue);
    });

    watchEffect(() => {
      emit('change', {
        init: init.value,
        computedX: computedX.value,
        x: x.value,
        minX: minX.value,
        maxX: maxX.value,
        dragStartX: dragStartX.value,
        handleWidth: handleWidth.value,
        handleHeight: handleHeight.value,
        handleOffset: handleOffset.value,
        unit: unit.value,
        pctComplete: pctComplete.value,
        isHover: isHover.value,
        isDragging: isDragging.value,
      });
    });

    const thumbClass = computed(() => {
      return {
        'is--dragging': isDragging.value,
        'is--focused': hasFocus.value,
        'is--start': props.start,
        'is--end': props.end,
        [`is--${props.theme}`]: props.theme,
      };
    });

    const thumbPositioning = computed(() => {
      if (props.start) {
        return {
          left: computedX.value - handleWidth.value + 'px',
          transition: isDragging.value ? 'none' : 'left .23s cubic-bezier(0.4, 0, 0.2, 1)',
        };
      }
      if (props.end) {
        return {
          right: maxX.value - computedX.value - handleWidth.value + 'px',
          left: 'auto',
          transition: isDragging.value ? 'none' : 'right .23s cubic-bezier(0.4, 0, 0.2, 1)',
        };
      }
      return {
        left: computedX.value - handleWidth.value / 2 + 'px',
        transition: isDragging.value ? 'none' : 'left .23s cubic-bezier(0.4, 0, 0.2, 1)',
      };
    });

    const thumbStyle = computed(() => {
      return {
        ...thumbPositioning.value,
        transition: 'transform .23s ease-in-out',
        position: 'absolute',
        cursor: isDragging.value ? 'grab' : 'pointer',
        // set resting z-index based on knob type
        zIndex: isDragging.value ? 1000 : 1,
      };
    });
    return { handle, thumbStyle, thumbClass, thumbPositioning, timeValue };
  },
});
