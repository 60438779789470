import { Folder, SingleFolderResponse, MultipleFolderResponse } from '@/store/folders/types';
import { processResponse, Api } from '@/utils/api';
import { FolderSortOptions } from '@/store/folders/constants';

export interface SearchParams {
  query: string;
  limit?: number;
  offset?: number;
}

const removeUncategorized = (results: MultipleFolderResponse) =>
  results.folders.filter((folder: Folder) => folder.name.toLowerCase() !== 'uncategorized');

const removeReviewedProducts = (results: MultipleFolderResponse) =>
  results.folders.filter((folder: Folder) => folder.name.toLowerCase() !== 'reviewed products');

const renameLTK = (results: MultipleFolderResponse) => {
  return results.folders.map(renameLTKFolder);
};

const renameLTKFolder = (folder: Folder) => {
  if (folder.name && folder.name === 'Published to LIKEtoKNOW.IT') {
    folder.name = 'Published to LTK';
  }
  return folder;
};

const config = window.rewardStyle;

class FoldersApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/pub/v1/folders/`;

  public async get(
    lastId: string | null = null,
    sort: FolderSortOptions = FolderSortOptions.NAME_ASCENDING,
    removeUncategorizedFolder: boolean = true,
    removeReviewedFolder: boolean = true,
  ) {
    const paginationOrder = sort === FolderSortOptions.NONE ? 'before_id' : `after_id`;
    const paginationParam = lastId !== null ? `&${paginationOrder}=${lastId}` : '';
    const sortParm = sort === FolderSortOptions.NONE ? '' : `sort=${sort}&`;
    const response = await this.sendRequest('GET', `?${sortParm}limit=30${paginationParam}`);
    const results = await processResponse<MultipleFolderResponse>(response);

    if (removeUncategorizedFolder) {
      results.folders = removeUncategorized(results);
    }
    if (removeReviewedFolder) {
      results.folders = removeReviewedProducts(results);
    }
    results.folders = renameLTK(results);

    return results;
  }

  public async getOne(id: string) {
    const response = await this.sendRequest('GET', id);
    const results = await processResponse<SingleFolderResponse>(response);

    results.folder = renameLTKFolder(results.folder);

    return results;
  }

  public async getMultiple({ folderIds, sortOrder }: { folderIds: string[]; sortOrder: FolderSortOptions }) {
    const folderIdParams = folderIds.map((id) => `ids[]=${id}`).join('&');
    const response = await this.sendRequest('GET', `?${folderIdParams}&sort=${sortOrder}`);
    const results = await processResponse<MultipleFolderResponse>(response);
    results.folders = removeReviewedProducts(results);
    results.folders = renameLTK(results);

    return results;
  }

  public async create(name: string) {
    const body = JSON.stringify({ name });
    const createResponse = await this.sendRequest('POST', '', { body });

    return await processResponse<SingleFolderResponse>(createResponse);
  }

  public async patch(folder: Folder) {
    const body = JSON.stringify(folder);
    const patchResponse = await this.sendRequest('PATCH', '', { body });

    return await processResponse<SingleFolderResponse>(patchResponse);
  }

  public async delete(folderId: string) {
    const deleteResponse = await this.sendRequest('DELETE', folderId);
    /*
      We return only a 204 for a successful deletion, so we just check
      for that specific status code to indicate the call was successful.
    */
    if (deleteResponse.status !== 204) {
      return await processResponse<{}>(deleteResponse);
    }
    return true;
  }

  public async search(
    { query, limit = 30, offset = 0 }: SearchParams,
    removeReviewedFolder: boolean = true,
    removeUncategorizedFolder: boolean = true,
  ) {
    const params = new URLSearchParams();
    params.set('query', query);
    if (limit) {
      params.set('limit', limit.toString());
    }
    if (offset) {
      params.set('offset', offset.toString());
    }
    const response = await this.sendRequest('GET', `search/?${params.toString()}`);
    const results = await processResponse<MultipleFolderResponse>(response);

    if (removeUncategorizedFolder) {
      results.folders = removeUncategorized(results);
    }
    results.folders = renameLTK(results);
    if (removeReviewedFolder) {
      results.folders = removeReviewedProducts(results);
    }

    return results;
  }
}

export default new FoldersApi();
