export enum UserStatus {
  SHOW_WALK_ME = 'Show Walk Me',
  SHOW_FIRST_ACCESS = 'Show First Access',
  NEW_ONBOARD_PAY_INFO = 'New Onboard Pay Info',
  NEW_ONBOARD_PASSWORD = 'New Onboard Password',
  DISPLAY_ACCESS = 'Display Access',
  LTK_CONSULTANT = 'LTK Consultant',
  ACCOUNT_REP = 'Account Rep',
  PROMO_SPONSOR = 'Promo Sponsor',
  CONTRIBUTOR_INVITED = 'Contributor Invited',
  REGISTERED = 'Registered',
  CONSULTANT = 'Consultant',
  CMS_ADMIN = 'CMS Admin',
  DISABLED = 'Disabled',
  CMS_USER = 'CMS User',
  COMPLETE = 'Complete',
  APPROVED = 'Approved',
  REQUIRES_PASSWORD_CHANGE = 'Requires Password Change',
  ACCOUNT_ADMIN = 'Account Admin',
}

/**
 * @description Utility function for decoding the user status bitmask into a list of status roles. Details available at: https://rewardstyle.atlassian.net/wiki/spaces/CE/pages/2374238250/How+To+Decode+a+Creator+User+s+status+Field
 */
export function decodeUserStatus(bitmask: number): UserStatus[] {
  const decodedData: UserStatus[] = [];

  if (bitmask - 524288 >= 0) {
    decodedData.push(UserStatus.SHOW_WALK_ME);
    bitmask = bitmask - 524288;
  }

  if (bitmask - 262144 >= 0) {
    decodedData.push(UserStatus.SHOW_FIRST_ACCESS);
    bitmask = bitmask - 262144;
  }

  if (bitmask - 131072 >= 0) {
    decodedData.push(UserStatus.NEW_ONBOARD_PAY_INFO);
    bitmask = bitmask - 131072;
  }

  if (bitmask - 65536 >= 0) {
    decodedData.push(UserStatus.NEW_ONBOARD_PASSWORD);
    bitmask = bitmask - 65536;
  }

  if (bitmask - 32768 >= 0) {
    decodedData.push(UserStatus.DISPLAY_ACCESS);
    bitmask = bitmask - 32768;
  }

  if (bitmask - 16384 >= 0) {
    decodedData.push(UserStatus.LTK_CONSULTANT);
    bitmask = bitmask - 16384;
  }

  if (bitmask - 8192 >= 0) {
    decodedData.push(UserStatus.ACCOUNT_REP);
    bitmask = bitmask - 8192;
  }

  if (bitmask - 4096 >= 0) {
    decodedData.push(UserStatus.ACCOUNT_REP);
    bitmask = bitmask - 4096;
  }

  if (bitmask - 2048 >= 0) {
    decodedData.push(UserStatus.PROMO_SPONSOR);
    bitmask = bitmask - 2048;
  }

  if (bitmask - 1024 >= 0) {
    decodedData.push(UserStatus.CONTRIBUTOR_INVITED);
    bitmask = bitmask - 1024;
  }

  if (bitmask - 512 >= 0) {
    decodedData.push(UserStatus.PROMO_SPONSOR);
    bitmask = bitmask - 512;
  }

  if (bitmask - 256 >= 0) {
    decodedData.push(UserStatus.REGISTERED);
    bitmask = bitmask - 256;
  }

  if (bitmask - 128 >= 0) {
    decodedData.push(UserStatus.CONSULTANT);
    bitmask = bitmask - 128;
  }

  if (bitmask - 64 >= 0) {
    decodedData.push(UserStatus.CMS_ADMIN);
    bitmask = bitmask - 64;
  }

  if (bitmask - 32 >= 0) {
    decodedData.push(UserStatus.DISABLED);
    bitmask = bitmask - 32;
  }

  if (bitmask - 16 >= 0) {
    decodedData.push(UserStatus.CMS_USER);
    bitmask = bitmask - 16;
  }

  if (bitmask - 8 >= 0) {
    decodedData.push(UserStatus.COMPLETE);
    bitmask = bitmask - 8;
  }

  if (bitmask - 4 >= 0) {
    decodedData.push(UserStatus.APPROVED);
    bitmask = bitmask - 4;
  }

  if (bitmask - 2 >= 0) {
    decodedData.push(UserStatus.REQUIRES_PASSWORD_CHANGE);
    bitmask = bitmask - 2;
  }

  if (bitmask - 1 >= 0) {
    decodedData.push(UserStatus.ACCOUNT_ADMIN);
    bitmask = bitmask - 1;
  }

  return decodedData;
}
