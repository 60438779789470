
import { computed, defineComponent, PropType } from 'vue';
import { StyleValue } from 'vue/types/jsx';

export default defineComponent({
  name: 'RwModalFooter',
  props: {
    align: {
      type: String,
      default: 'center',
    },
    justify: {
      type: String,
      default: 'flex-end',
    },
    direction: {
      type: String as PropType<'initial' | 'column' | 'column-reverse' | 'row' | 'row-reverse'>,
      default: 'initial',
    },
    padded: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const alignmentStyle = computed((): StyleValue => {
      return {
        alignItems: props.align,
        justifyContent: props.justify,
        flexDirection: props.direction,
      };
    });

    const classes = computed(() => {
      return { 'is--padded': props.padded };
    });

    return { alignmentStyle, classes };
  },
});
