
import { computed, defineComponent, getCurrentInstance, ref, watch, useSlots } from 'vue';
import { RsButton } from '@/lib';
import { uniqueId } from '@/utils/uniqueId';

export default defineComponent({
  name: 'RsToggleOptions',
  components: {
    RsButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    falsyLabel: {
      type: String,
      required: false,
    },
    truthyLabel: {
      type: String,
      required: false,
    },
  },
  emits: {
    input: (v: boolean) => true,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const slots = useSlots();
    const labelID = `rs-toggle-options-label-${uniqueId()}`;

    const { $gettext } = vm?.proxy as Vue;

    const innerValue = ref<boolean>(props.value ?? '');

    const labels = computed(() => ({
      truthy: props.truthyLabel ?? $gettext('Yes'),
      falsy: props.falsyLabel ?? $gettext('No'),
    }));

    const hasLabelSlot = computed(() => !!slots.label);
    const toggleValue = () => (!props.disabled ? changeValue(!innerValue.value) : false);

    const changeValue = (v: boolean) => {
      if (innerValue.value !== v) {
        innerValue.value = v;
        emit('input', v);
      }
    };

    watch(
      () => props.value,
      (newValue) => changeValue(newValue),
    );

    return {
      innerValue,
      changeValue,
      toggleValue,
      labels,
      labelID,
      hasLabelSlot,
    };
  },
});
