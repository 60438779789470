
import Vue, { computed, defineComponent, getCurrentInstance, PropType, reactive, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { RwCheckbox, RsTip } from '@/lib';
import SlotTranslate from '@/components/SlotTranslate';
import useLinks from '@/composables/useLinks';
import AccountsService from '@/store/accounts';
import { RsPhoneFieldState, useSmsOptInFeature } from '@/composables/useSmsOptInFeature';

export default defineComponent({
  name: 'RsPhoneFieldOptInCheckbox',
  emits: ['input'],
  components: { RwCheckbox, RsTip, SlotTranslate },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    isApplication: {
      type: Boolean,
      required: false,
      default: false,
    },
    country: {
      type: String as PropType<IntlTelInputIso2CountryCode | string>,
      required: false,
      default: 'us',
    },
    phoneField: {
      type: Object as PropType<RsPhoneFieldState>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const { $language, $userEvents, $gettext } = vm?.proxy as Vue;
    const $route = useRoute();
    const { siteLinks } = useLinks($language);

    const { optInCheckBoxContent, showOptInWhatsAppFeature, showOptInSmsFeature } = useSmsOptInFeature({
      phoneField: computed(() => props.phoneField),
      $language,
      $gettext,
    });

    const state = reactive({
      isChecked: props.value,
    });

    const onChecked = (checked: boolean) => {
      emit('input', state.isChecked);

      if (checked) {
        $userEvents.sendEvent('/sms-terms/accept', {
          page: $route.path,
          referrer: $route.name,
          'root-referrer': 'sms-checkbox',
          service: showOptInWhatsAppFeature.value ? 'WhatsApp' : 'SMS',
        });
      } else if (AccountsService.user.opt_into_sms && !checked) {
        // If user was previously opted in and unchecks the box send this event
        $userEvents.sendEvent('/sms-terms/unsubscribe', {
          page: $route.path,
          referrer: $route.name,
          'root-referrer': 'sms-checkbox',
        });
      }
    };

    watch(
      () => props.value,
      (next) => {
        if (next !== state.isChecked) state.isChecked = next;
      },
    );

    return {
      state,
      siteLinks,
      onChecked,
      optInCheckBoxContent,
      showOptInWhatsAppFeature,
      showOptInSmsFeature,
    };
  },
});
