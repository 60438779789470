import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';

import { ApplicationStatus } from '@/types/postApplication';
import PostApplicationApi from '@/services/postApplicationApi';
import { isProcessResponseError, ProcessResponseError } from '@/utils/api';

interface GetStatusParams {
  // User's email address
  email: string;
  // Determines whether the action fetches and overwrites the data.
  // Set to undefined (cache data from first action call) by default
  overwrite?: boolean;
}

export const usePostApplicationStore = defineStore('post-application', () => {
  // Application resolution. Do not use this to mutate state from a component!
  const resolution = ref<ApplicationStatus | string | undefined>(undefined);
  // Can the user reapply to be a creator. Do not use this to mutate state from a component!
  const canReapply = ref<boolean>(false);
  const hasFetchedStatus = ref<boolean>(false);

  // Application status. Returns DECLINED for any manual decline resolution.
  const status = computed(() => {
    if (!resolution.value) return undefined;

    return (<any>Object).values(ApplicationStatus).includes(resolution.value)
      ? resolution.value
      : ApplicationStatus.DECLINED;
  });

  const errorApplicationStatus = ref<ProcessResponseError | null>(null);

  const getStatus = async ({ email, overwrite }: GetStatusParams): Promise<void> => {
    errorApplicationStatus.value = null;
    // Don't repeatedly fetch the application status
    const canOverwrite = overwrite || !hasFetchedStatus.value;

    try {
      if (canOverwrite) {
        const response = await PostApplicationApi.getStatus(email);
        hasFetchedStatus.value = true;

        if (response) {
          const { resolution_id, can_reapply } = response.applicant_status;
          resolution.value = resolution_id;
          canReapply.value = can_reapply;
        } else {
          resolution.value = undefined;
          canReapply.value = false;
        }
      }
    } catch (error) {
      if (isProcessResponseError(error)) {
        // Store the error to be displayed at a later time,
        // as we don't want to halt the flow if the request fails
        errorApplicationStatus.value = error;
      } else {
        throw error;
      }
    }
  };

  return {
    status,
    canReapply,
    resolution,
    errorApplicationStatus,
    getStatus,
  };
});
