export function compareObjectValues(objA: Obj, objB: Obj): boolean {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  for (const key of keysA) {
    const valueA = objA[key];
    const valueB = objB[key];

    if (Array.isArray(valueA) && Array.isArray(valueB)) {
      const arrayA = Array.from(valueA);
      const arrayB = Array.from(valueB);
      if (!arraysAreEqual(arrayA, arrayB)) {
        return false;
      }
    } else if (valueA !== valueB) {
      return false;
    }
  }

  return true;
}

function arraysAreEqual<T>(arrA: T[], arrB: T[]): boolean {
  if (arrA.length !== arrB.length) {
    return false;
  }
  for (let i = 0; i < arrA.length; i++) {
    const arrayA = { ...arrA[i] };
    const arrayB = { ...arrB[i] };
    if (JSON.stringify(arrayA) !== JSON.stringify(arrayB)) {
      return false;
    }
  }
  return true;
}

interface Obj {
  [key: string]: string | unknown;
}
