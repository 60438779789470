import Vue from 'vue';

import { PiniaVuePlugin, createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

Vue.use(PiniaVuePlugin);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import store from './store';
import { runwayInstall } from '@rewardstyle/runway-components-vue';

import registerPlugins from '@/plugins';
registerPlugins(Vue);

import router from '@/router';

import App from '@/App.vue';
import '@rewardstyle/runway-components-vue/dist/v2.7/style.css';

Vue.use(runwayInstall, { scheme: 'light', theme: 'creator' });
new Vue({
  pinia,
  router,
  store,
  render: (h) => h(App),
}).$mount('#rewardstyle');
