export interface ProfilesStoreState {
  profile: Profile | null;
  profileError: Error | null;
  hasFetchedProfile: boolean;
}

export enum Shape {
  APPLE_ROUND = 'Apple / round',
  ATHLETIC_FIT = 'Athletic / fit',
  CURVY = 'Curvy',
  DIAMOND = 'Diamond',
  HOURGLASS = 'Hourglass',
  MID_SIZE = 'Mid-size',
  PEAR_TRIANGLE = 'Pear / triangle',
  PETITE = 'Petite',
  PLUS_SIZE = 'Plus-size',
  RECTANGLE = 'Rectangle',
  SPOON = 'Spoon',
  TALL = 'Tall',
}

export interface BodyShape {
  type: 'static' | 'dynamic';
  value: Shape | string;
}

export interface Classification {
  height?: number;
  body_shapes?: BodyShape[];
}

export interface Profile {
  id: string;
  searchable: boolean;
  rs_account_id: number;
  display_name: string;
  bio: string | null;
  full_name: string | null;
  instagram_name: string | null;
  blog_name: string | null;
  blog_url: string | null;
  image_mime_type?: 'image/jpeg' | string | null;
  bg_image_mime_type?: 'image/jpeg' | string | null;
  avatar_url: string | null;
  avatar_upload_url: string | null;
  has_uploaded_avatar_image: boolean;
  bg_image_url: string | null;
  bg_upload_url: string | null;
  has_uploaded_bg_image: boolean;
  classification?: Classification | null;
  created_at?: Date;
}

export interface ProfileUser {
  id: string;
  profile_id: string;
  rs_user_id: number;
  sigil_id: string | null;
  status: string;
}

export interface CreateProfileRequest {
  rs_account_id: number;
  display_name: string;
  image_mime_type: 'image/jpeg' | string;
  bg_image_mime_type: string | undefined;
  bio?: string;
  full_name?: string;
  instagram_name?: string;
  blog_name?: string;
  blog_url?: string;
  classification: Classification;
}

export type UpdateProfileRequest = Partial<Profile>;

export interface LinkProfileRequest {
  rs_user_id: number;
}

export interface UploadImageRequest {
  image: File | Blob;
  signedUploadUrl: string;
}

export interface ProfilesResponse {
  profiles: Profile[];
}

export interface ProfileResponse {
  profile: Profile;
}

export interface ProfileUsersResponse {
  profile_users: ProfileUser[];
}

export interface ProfileUserResponse {
  profile_user: ProfileUser;
}

export class UploadImageError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UploadImageError';
  }
}
