import type { RouteConfig } from 'vue-router';
import { Layout } from '@/layouts/types';
import { ExperimentName, ExperimentType } from '@/types/experiments';
import type { ExperimentMeta } from '@/types/experiments';
import { checkAndroid } from '@/router/navigation-guards/beforeEnter/checkAndroid';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';
import {
  EDIT,
  PUBLISH_BASE,
  PUBLISH_LTK,
  PUBLISH_LTK_ID,
  CAPTION_HASHTAG,
  BULK_UPLOAD,
  DOWNLOAD_APP,
} from '@/utils/router/namedRoutes';

const publishingExperimentMeta: ExperimentMeta = {
  name: ExperimentName.PUBLISHING,
  type: ExperimentType.FLAG,
  allowed: ['on'],
};

const routes: RouteConfig[] = [
  {
    name: PUBLISH_BASE,
    path: '/publish',
    redirect: { name: PUBLISH_LTK },
  },
  {
    name: PUBLISH_LTK,
    path: '/publish/ltk',
    component: () => import(/* webpackChunkName: "publish" */ '../views/Publish.vue'),
    meta: {
      layout: Layout.PUBLISH,
      experiment: publishingExperimentMeta,
    },
    beforeEnter: checkAndroid,
  },
  {
    name: BULK_UPLOAD,
    path: '/publish/ltk/bulk-media-upload',
    component: () => import(/* webpackChunkName: "publish" */ '../views/BulkUpload.vue'),
  },
  {
    name: PUBLISH_LTK_ID,
    path: '/publish/ltk/:id',
    component: () => import(/* webpackChunkName: "publish" */ '../views/Publish.vue'),
    meta: {
      layout: Layout.PUBLISH,
      experiment: publishingExperimentMeta,
    },
    beforeEnter: checkAndroid,
  },
  {
    name: EDIT,
    path: '/publish/ltk/edit/picture',
    component: () => import(/* webpackChunkName: "publish" */ '../views/Edit.vue'),
    meta: {
      layout: Layout.PUBLISH,
      experiment: publishingExperimentMeta,
    },
    beforeEnter: checkAndroid,
  },
  {
    name: CAPTION_HASHTAG,
    path: '/publish/ltk/edit/caption',
    component: () => import(/* webpackChunkName: "publish" */ '../views/CaptionHashtag.vue'),
    meta: {
      layout: Layout.PUBLISH,
      experiment: publishingExperimentMeta,
    },
    beforeEnter: checkAndroid,
  },
];
export default routes;
