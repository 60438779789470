
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { CSSProperties } from 'vue/types/jsx';

const __default__ = defineComponent({
  name: 'RsImageCropper',
  emits: {
    'cropper-reset': () => true,
    'crop-end': (dataUrl: string) => true,
  },
  props: {
    containerStyles: Object,
    src: {
      type: String,
      default: '',
    },
    alt: String,
    imgStyle: { type: Object as PropType<CSSProperties>, default: () => {} },
    options: {
      type: Object as PropType<Cropper.Options>,
    },
    borderRadius: {
      type: String,
      default: '100%',
    },
  },
  setup(props, { emit }) {
    const inputEl = ref<HTMLImageElement | null>(null);
    const cropper = ref<null | Cropper>(null);

    // Emit the cropped data when crop movement ends
    function handleCropEnd() {
      emit('crop-end', cropper?.value?.getCroppedCanvas().toDataURL() || '');
    }

    // Handle 'ready' event for CropperJS, so we pick up the selected area when
    // the utility is loaded and user doesn't change it
    function handleCropReady() {
      emit('crop-end', cropper?.value?.getCroppedCanvas().toDataURL() || '');
    }

    onMounted(() => {
      const options = { ...props.options, cropend: handleCropEnd, ready: handleCropReady };
      cropper.value = new Cropper(inputEl.value as HTMLImageElement, options);
    });

    watch(
      () => props.src,
      (newSrc: string) => {
        !newSrc ? cropper.value?.clear() : cropper.value?.replace(newSrc);
      },
    );

    return {
      cropper,
      inputEl,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "4a39749a": (_vm.borderRadius)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__