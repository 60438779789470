
import { computed, defineComponent, getCurrentInstance, onMounted, ref, reactive, watch, nextTick, Ref } from 'vue';

import { RsButton, RwModal, RwModalBody, RsAlertBar } from '@/lib';
const RsPhoneField = () => import(/* webpackChunkName: "phone-field" */ '@/components/RsPhoneField/RsPhoneField.vue');
import RsPhoneFieldOptInCheckbox from '@/components/RsPhoneFieldOptInCheckbox.vue';

import { countryStates } from '@/utils/geography';
import useCountries from '@/rs-account/composables/useCountries';
import { required, email, validate, RsFormComponent } from '@/utils/validation';
import cloneJSON from '@/utils/cloneJSON';

import AccountsStore from '@/store/accounts';
import type { AccountUser } from '@/store/accounts/types';

import { useSmsOptInFeature, RsPhoneFieldState } from '@/composables/useSmsOptInFeature';

export default defineComponent({
  name: 'ContributorUpdateModal',
  components: {
    RsButton,
    RwModal,
    RwModalBody,
    RsPhoneField,
    RsPhoneFieldOptInCheckbox,
    RsAlertBar,
  },
  setup(props, context) {
    const vm = getCurrentInstance();
    const { $gettext, $language } = vm?.proxy as Vue;

    const contributorUpdateModalWrapper = ref<HTMLElement | null>(null);
    const contributorForm = ref<RsFormComponent | null>(null);

    /**
     * @public Public API - Used for unit testing form validation
     */
    const isValid = ref<boolean>(false);

    const state = reactive({
      generalError: false,
    });

    const userDetails = ref<AccountUser>(cloneJSON(AccountsStore.user));

    const labels = computed(() => ({
      name: $gettext('Full name') + '*',
      email: $gettext('Email') + '*',
      phone: $gettext('Phone'),
      opt_into_sms: $gettext('I would like to sign up to receive SMS marketing messages and updates.'),
      state: $gettext('State') + ' / ' + $gettext('Province') + ' / ' + $gettext('Region') + '*',
      country: $gettext('Country of residency') + '*',
    }));

    const requiredRule = computed(() => required({ errorMessage: $gettext('This field is required') }));
    const emailRule = computed(() => email({ errorMessage: $gettext('Email must be valid') }));

    const countryCode = computed(
      () =>
        (userDetails.value.address.country
          ? userDetails.value.address.country?.toLowerCase()
          : 'us') as IntlTelInputIso2CountryCode,
    );

    const phoneField = ref<RsPhoneFieldState>({
      country: countryCode.value,
      format: {
        countryCode: 'us',
        e164Value: '',
        isE164ValueValid: false,
      },
    });

    const handlePhoneFieldFormat = (event: RsPhoneFieldFormatEvent) => {
      Object.assign(phoneField.value.format, event);

      // If phone number country has changed, update and unsubscribe opt-in
      if (AccountsStore.user.contact.country.toLowerCase() !== event.countryCode && event.countryCode) {
        userDetails.value.contact.country = event.countryCode;
        userDetails.value.contact.is_subscribed_broadcast = false;
      }
    };

    const { showOptInSmsFeature, showOptInWhatsAppFeature } = useSmsOptInFeature({ phoneField, $language, $gettext });

    const handlePhoneFieldChange = (phone: string) => {
      // Opt out user from SMS when their phone number changes
      if (showOptInSmsFeature.value && phone !== AccountsStore.user.contact.phone) {
        userDetails.value.contact.is_subscribed_broadcast = false;
      }
    };

    const { countryOptions, getCountriesResponse } = useCountries();

    const stateOptions = computed(() => {
      return countryStates[userDetails.value.address.country || 'us'];
    });

    const validateForm = () => {
      return validate((contributorForm.value as RsFormComponent | null) ?? undefined);
    };

    const onSubmit = async () => {
      // Clear error alerts on new submit attempts
      state.generalError = false;

      // Update phone number with valid E.164 format value.
      userDetails.value.contact.phone = phoneField.value.format.e164Value;

      if (isValid.value) {
        // Submit updates
        await AccountsStore.patchUser({
          name: userDetails.value.name,
          address: userDetails.value.address,
          contact: userDetails.value.contact,
        });

        if (AccountsStore.userError) {
          state.generalError = true;
          await nextTick(); // Wait for the error alert to be rendered before attempting to scroll to element in page
          contributorUpdateModalWrapper.value?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    /**
     * Public API - hasInit: Promise for tracking view init state for unit testing.
     */
    const hasInit = ref(
      new Promise<void>((resolve, reject) => {
        onMounted(async function initializeForm() {
          try {
            await Promise.all([AccountsStore.fetchAccountUser({}), getCountriesResponse()]);
          } catch (e) {
            // TODO: Failed to load countries
          } finally {
            await nextTick();
            resolve();
          }
        });
      }),
    );

    watch(
      () => AccountsStore.user,
      (newUser) => {
        userDetails.value = cloneJSON(newUser);
      },
      { deep: true },
    );

    return {
      contributorUpdateModalWrapper,
      contributorForm,
      isValid,
      userDetails,
      countryOptions,
      labels,
      phoneField,
      handlePhoneFieldFormat,
      showOptInSmsFeature,
      showOptInWhatsAppFeature,
      handlePhoneFieldChange,
      state,
      stateOptions,
      requiredRule,
      emailRule,
      validateForm,
      onSubmit,
      hasInit,
    };
  },
});
