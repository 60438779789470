export type VuetifyFieldValidationRuleCallback = (value: string) => boolean | string;
export type VuetifyFieldValidationRules = VuetifyFieldValidationRuleCallback[];

export enum ToggleButtonLabelPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum UploadImagePosition {
  TOPLEFT = 'TOPLEFT',
  TOPRIGHT = 'TOPRIGHT',
  BOTTOMLEFT = 'BOTTOMLEFT',
  BOTTOMRIGHT = 'BOTTOMRIGHT',
}
