import Vue from 'vue';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { ApiError } from '@/utils/api';
import productReviewsApi from '@/store/productReviews/api';
import { ProductReviewsMap, ProductReview, ProductReviewsState } from '@/store/productReviews/types';

@Module({ dynamic: true, name: 'productReviews', store })
class ProductReviews extends VuexModule implements ProductReviewsState {
  public productReviewsMap: ProductReviewsMap = {};
  public productReviewsError: ApiError | null = null;

  @Action
  public async fetchProductReviews(productIds: string[]) {
    try {
      const productReviewsResponse = await productReviewsApi.get(productIds);
      this.SET_PRODUCT_REVIEWS(productReviewsResponse.product_reviews);
    } catch (error) {
      this.HANDLE_PRODUCT_REVIEWS_ERROR(error as ApiError);
    }
  }

  @Mutation
  public SET_PRODUCT_REVIEWS(productReviews: ProductReview[] = []) {
    productReviews.forEach((productReview) => {
      Vue.set(this.productReviewsMap, productReview.product_id, productReview);
    });
  }

  @Mutation
  public HANDLE_PRODUCT_REVIEWS_ERROR(error: ApiError | null) {
    this.productReviewsError = error;
  }

  @Mutation
  public RESET_STORE() {
    Vue.set(this, 'productReviewsMap', {});
    Vue.set(this, 'productReviewsError', null);
  }
}

export default getModule(ProductReviews);
