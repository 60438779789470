import { NavigationGuard } from 'vue-router';
import { DOWNLOAD_APP } from '@/utils/router/namedRoutes';
export const checkMobile: NavigationGuard = async function (to, from, next) {
  const ua = navigator.userAgent;
  const mobileDeviceRegex =
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
      ua,
    );
  if (!mobileDeviceRegex) {
    return next();
  }

  return next({ name: DOWNLOAD_APP });
};
