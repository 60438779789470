var render, staticRenderFns
import script from "./RsButton.vue?vue&type=script&lang=ts&"
export * from "./RsButton.vue?vue&type=script&lang=ts&"
import style0 from "./RsButton.vue?vue&type=style&index=0&id=5b159da8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports