import { ref, watch } from 'vue';
import { Route } from 'vue-router';
import { isAnyApplicationRoute, isLegalRoute, isSetupRoute } from '@/utils/router/namedRoutes';

export type ComponentName = 'TOS_UPDATE_MODAL' | 'CONTRIBUTOR_UPDATE_MODAL';
export type RouteCheck = (route: Readonly<Route>) => boolean;

const checkTosUpdateModal: RouteCheck = (route) => {
  if (isLegalRoute(route) || isAnyApplicationRoute(route) || isSetupRoute(route)) {
    return false;
  }
  return true;
};

const checkContributorUpdateModal: RouteCheck = (route) => {
  if (isLegalRoute(route) || isAnyApplicationRoute(route) || isSetupRoute(route)) {
    return false;
  }
  return true;
};

/**
 * Registry of router check functions for application components.
 */
export const checklist: Readonly<Record<ComponentName, RouteCheck>> = {
  TOS_UPDATE_MODAL: checkTosUpdateModal,
  CONTRIBUTOR_UPDATE_MODAL: checkContributorUpdateModal,
};

/**
 * Composable for route-dependent display logic for embedded components, i.e. components nested directly under App.vue.The composable will wait until the router updates to run route checks in order to avoid FLOUC scenarios.
 */
export const useRouteChecks = (route: Route, name: ComponentName) => {
  const routeCheck = ref<RouteCheck | undefined>(undefined);
  const doesRoutePassChecks = ref(false);

  watch(route, function onRouteChange(newRoute) {
    routeCheck.value = checklist[name];
    doesRoutePassChecks.value = routeCheck.value(newRoute);
  });

  return {
    ROUTE_CHECK_FUNCTION: routeCheck,
    doesRoutePassChecks,
  };
};
