import { Api, processResponse } from '@/utils/api';
import {
  CreateProfileRequest,
  LinkProfileRequest,
  ProfileResponse,
  ProfilesResponse,
  ProfileUserResponse,
  ProfileUsersResponse,
  UpdateProfileRequest,
  UploadImageRequest,
  UploadImageError,
} from '@/store/profiles/types';

const config = window.rewardStyle;

class ProfilesApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/pub/v2/`;

  public async getProfilesForAccount(accountId: number) {
    const response = await this.sendRequest('GET', `profiles/?rs_account_id=${accountId}`);
    return processResponse<ProfilesResponse>(response);
  }

  public async getProfilesForUser(userId: number) {
    const response = await this.sendRequest('GET', `profiles/?rs_user_id=${userId}`);
    return processResponse<ProfilesResponse>(response);
  }

  public async getProfilesForDisplayName(displayName: string) {
    const response = await this.sendRequest('GET', `profiles/?display_names[]=${encodeURIComponent(displayName)}`);
    return processResponse<ProfilesResponse>(response);
  }

  public async createProfile(profile: CreateProfileRequest) {
    const body = JSON.stringify(profile);

    const response = await this.sendRequest('POST', `profiles`, {
      body,
    });

    return processResponse<ProfileResponse>(response);
  }

  public async getProfileUsers(profileId: string) {
    const response = await this.sendRequest('GET', `profiles/${profileId}/users`);
    return processResponse<ProfileUsersResponse>(response);
  }

  public async linkUser(userId: number, profileId: string) {
    const user: LinkProfileRequest = { rs_user_id: userId };
    const body = JSON.stringify(user);

    const response = await this.sendRequest('POST', `profiles/${profileId}/users`, {
      body,
    });

    return processResponse<ProfileUserResponse>(response);
  }

  public async updateProfile(profile: UpdateProfileRequest) {
    if (!profile.id) throw new Error('Profile Id is required for updating a Profile');

    const { id: profileId, ...updatedProfile } = profile;

    const body = JSON.stringify(updatedProfile);

    const response = await this.sendRequest('PUT', `profiles/${profileId}`, {
      body,
    });

    return processResponse<ProfileResponse>(response);
  }

  /**
   * Uploads a Profile avatar image to a CDN.
   *
   * @param image - an avatar request object
   * @param image.image - an image File
   * @param image.signedUploadUrl - the signed URL found on a Profile's `avatar_upload_url` property
   *
   * @throws {UploadImageError} - Will throw an Error when avatar image upload fails
   */
  public async uploadImage(image: UploadImageRequest, name: String) {
    try {
      const response = await fetch(image.signedUploadUrl, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'content-type': image.image.type,
          'X-Amz-Acl': 'public-read',
        },
        body: image.image,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response;
    } catch (error) {
      throw new UploadImageError(`Error uploading ${name} Image to the LTK Profile: ${(error as Error)?.message}`);
    }
  }
}

export default new ProfilesApi();
