/**
 * Ideally flag key and value are the same. We should follow that pattern in the future.
 *
 * @deprecated Do NOT use for new feature flag values. Please use the experiments Pinia store and types instead.
 */
export enum FeatureFlags {
  ENABLE_DEMO_FEATURE = 'ENABLE_DEMO_FEATURE',
  LTK_SHOPS = 'LTK_SHOPS',
  WIDGETS_LOOKBOOK = 'WIDGETS_LOOKBOOK',
  WIDGETS_MONEYSPOT = 'WIDGETS_MONEYSPOT',
}

/**
 * @deprecated Do NOT use for new feature flag values. Please use the experiments Pinia store and types instead.
 */
export const isFlagActive = (flag: FeatureFlags): boolean => {
  const value = localStorage.getItem(flag);
  return value === 'true';
};

/**
 * @deprecated Do NOT use for new feature flag values. Please use the experiments Pinia store and types instead.
 */
export const fetchAllFlags = () => {
  interface FlagValueS {
    [key: string]: boolean;
  }
  const items: FlagValueS = {};

  // flagName is readable flag name, not the actual local storage key.
  for (const flagName in FeatureFlags) {
    const flagKey = (FeatureFlags as any)[flagName];
    const flagValue = localStorage.getItem(flagKey);
    items[flagName] = !!flagValue;
  }
  return items;
};
