
import Vue from 'vue';

export default Vue.extend({
  props: {
    to: {
      type: String,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    show: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
    },
  },
});
