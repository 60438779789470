
import { defineComponent, watch, ref, PropType } from 'vue';
import { ToggleButtonLabelPosition } from '@/components/constants';
import { uniqueId } from '@/utils/uniqueId';

export default defineComponent({
  name: 'ToggleButton',
  emits: ['click', 'input'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    dataTestid: {
      type: String,
      required: false,
      default: 'toggle-button',
    },
    label: {
      type: String,
      required: true,
    },
    labelPosition: {
      type: String as PropType<ToggleButtonLabelPosition>,
      required: false,
      default: ToggleButtonLabelPosition.RIGHT,
    },
  },
  setup(props, { emit }) {
    const isChecked = ref<boolean>(props.value);
    const labelID = uniqueId();

    const toggleButtonClicked = (e: Event) => {
      if (props.disabled) {
        return;
      }

      isChecked.value = !isChecked.value;
      emit('input', isChecked.value);
      emit('click', e);
    };

    watch(
      () => props.value,
      (next) => {
        isChecked.value = next;
      },
    );

    return { isChecked, labelID, toggleButtonClicked, ToggleButtonLabelPosition };
  },
});
