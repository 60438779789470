export class AuthenticationError extends Error {
  public name = 'AuthenticationError';
  public message: string;
  public status: number;
  public error: string;

  public constructor(error: LoginError) {
    super(error.developer_message);
    this.message = error.developer_message;
    this.status = error.status;
    this.error = error.error;
  }
}
