import { Api, processResponse } from '@/utils/api';
import { ApplicationStatusResponse } from '@/types/postApplication';

const config = window.rewardStyle;

class PostApplicationApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api`;

  /**
   * Retrieves the user's application status
   *
   * @param email
   * @returns {Promise<ApplicationStatusResponse | false>} ApplicationStatusResponse if successful, or FALSE if there is no application
   */
  public async getStatus(email: string): Promise<ApplicationStatusResponse | false> {
    // This can result in a 404 in text/plain when there's no application
    const response = await this.sendRequest('GET', `/rs/v1/applicants/status/?email=${encodeURIComponent(email)}`);

    if (response.status === 404) {
      return false;
    }

    return processResponse<ApplicationStatusResponse>(response);
  }
}

export default new PostApplicationApi();
