import {
  APPLY_HOME,
  APPLY_ABOUT_YOU,
  APPLY_SOCIAL_CHANNELS,
  APPLY_EXPERIENCE,
  APPLY_DONE,
  APPLY_STATUS,
  APPLY_CONGRATS,
  EMAIL_CHECK,
  EXISTING_ACCOUNT,
  VERIFY_EMAIL,
  type ApplicationPages,
} from '@/utils/router/namedRoutes';

export type ApplicationGridImageSet = {
  img: string;
  imgCredit: string;
  horizontalGrid: boolean;
  verticalGrid: boolean;
  hideApplyBanner: boolean;
  showImageOnMobile: boolean;
};

type ApplicationGridImageSets = {
  [Property in ApplicationPages]: ApplicationGridImageSet;
};

// NOTE: Adding new keys means adding new route in namedRoutes
// and updateing the ApplicationSignupPages type above
export const applicationSignupImageSet: ApplicationGridImageSets = {
  [EMAIL_CHECK]: {
    img: 'creator_image_signup.jpeg',
    imgCredit: 'LTK Creator @rocky_barnes',
    horizontalGrid: false,
    verticalGrid: false,
    hideApplyBanner: false,
    showImageOnMobile: true,
  },
  [EXISTING_ACCOUNT]: {
    img: 'creator_image_signup.jpeg',
    imgCredit: 'LTK Creator @rocky_barnes',
    horizontalGrid: false,
    verticalGrid: false,
    hideApplyBanner: false,
    showImageOnMobile: true,
  },
  [VERIFY_EMAIL]: {
    img: 'creator_image_signup.jpeg',
    imgCredit: 'LTK Creator @rocky_barnes',
    horizontalGrid: false,
    verticalGrid: false,
    hideApplyBanner: false,
    showImageOnMobile: true,
  },
  [APPLY_HOME]: {
    img: 'creator_image_home.png',
    imgCredit: 'LTK Creator @9to5chic',
    horizontalGrid: true,
    verticalGrid: false,
    hideApplyBanner: true,
    showImageOnMobile: false, // confusingly home has it's own style so we need this false for it's own styles to take hold
  },
  [APPLY_ABOUT_YOU]: {
    img: 'creator_image_step_1.png',
    imgCredit: 'LTK Creator @candacemread',
    horizontalGrid: false,
    verticalGrid: false,
    hideApplyBanner: false,
    showImageOnMobile: false,
  },
  [APPLY_SOCIAL_CHANNELS]: {
    img: 'creator_image_step_2.png',
    imgCredit: 'LTK Creator @mckennableu',
    horizontalGrid: false,
    verticalGrid: false,
    hideApplyBanner: false,
    showImageOnMobile: false,
  },
  [APPLY_EXPERIENCE]: {
    img: 'creator_image_step_3.jpeg',
    imgCredit: 'LTK Creator @bychloewen',
    horizontalGrid: false,
    verticalGrid: false,
    hideApplyBanner: false,
    showImageOnMobile: false,
  },
  [APPLY_DONE]: {
    img: 'creator_image_done_status.png',
    imgCredit: 'LTK Creator @alexmichaelmay',
    horizontalGrid: false,
    verticalGrid: true,
    hideApplyBanner: true,
    showImageOnMobile: false,
  },
  [APPLY_STATUS]: {
    img: 'creator_image_done_status.png',
    imgCredit: 'LTK Creator @alexmichaelmay',
    horizontalGrid: false,
    verticalGrid: false,
    hideApplyBanner: true,
    showImageOnMobile: true,
  },
  [APPLY_CONGRATS]: {
    img: 'creator_image_status_approved.png',
    imgCredit: '',
    horizontalGrid: false,
    verticalGrid: false,
    hideApplyBanner: true,
    showImageOnMobile: true,
  },
};
