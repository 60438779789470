import * as fullStory from '@fullstory/browser';

export type fullStoryUser = {
  uid: number | null;
  displayName: string;
  email: string;
  isEmployee: boolean;
  accountId: number | null;
  accountName: string;
  accountType: string;
  impersonatorId?: string;
};

const useFullStory = () => {
  const fullStoryConfiguration = window.rewardStyle.FULLSTORY;

  const initFullStory = () => {
    if (fullStoryConfiguration) {
      fullStory.init(fullStoryConfiguration);
    } else {
      throw new Error('Fullstory not configured, did not initialize');
    }
  };

  const identifyFullStoryUser = (user: fullStoryUser) => {
    if (fullStoryConfiguration) {
      fullStory.identify(user.email, user);
    }
  };

  const shutdownFullStory = () => {
    if (fullStoryConfiguration) {
      // shuts down fullstory
      fullStory.shutdown();
    }
  };

  const restartFullStory = () => {
    if (fullStoryConfiguration) {
      fullStory.restart();
    }
  };

  return { initFullStory, identifyFullStoryUser, shutdownFullStory, restartFullStory };
};

export default useFullStory;
