export enum Currency {
  AED = 'AED',
  ARS = 'ARS',
  AUD = 'AUD',
  BBD = 'BBD',
  BGN = 'BGN',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CZK = 'CZK',
  DKK = 'DKK',
  DZD = 'DZD',
  EGP = 'EGP',
  EUR = 'EUR',
  GBP = 'GBP',
  GTQ = 'GTQ',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IRR = 'IRR',
  JPY = 'JPY',
  KHR = 'KHR',
  KRW = 'KRW',
  KWD = 'KWD',
  LBP = 'LBP',
  LVL = 'LVL',
  MAD = 'MAD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MXN = 'MXN',
  MYR = 'MYR',
  NOK = 'NOK',
  NZD = 'NZD',
  PEN = 'PEN',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  QAR = 'QAR',
  RON = 'RON',
  RUB = 'RUB',
  SAR = 'SAR',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TRY = 'TRY',
  TWD = 'TWD',
  UAH = 'UAH',
  USD = 'USD',
  VEF = 'VEF',
  VND = 'VND',
  ZAR = 'ZAR',
}

export enum CurrencyLabels {
  AED = 'AED (د.إ - United Arab Emirates - درهم إماراتي)',
  ARS = 'ARS ($ - Argentina - Argentina)',
  AUD = 'AUD ($ - Australia - Australia)',
  BBD = 'BBD ($ - Barbados - Barbados)',
  BGN = 'BGN (лв - Bulgaria - България)',
  BRL = 'BRL (R$ - Brazil - Brasil)',
  CAD = 'CAD ($ - Canada - Canada)',
  CHF = 'CHF (chf - Switzerland - die Schweiz)',
  CLP = 'CLP ($ - Chile - República de Chile)',
  CNY = 'CNY (¥ - Mainland China - 中国大陆)',
  COP = 'COP ($ - Colombia - Republic of Colombia)',
  CRC = 'CRC (₡ - Costa Rica - Costa Rica)',
  CZK = 'CZK (Kč - Czech Republic - Česká republika)',
  DKK = 'DKK (kr - Denmark - Danmark)',
  DZD = "DZD (دج - Algeria - People's Democratic Republic of Algeria)",
  EGP = 'EGP (ج.م - Egypt - جمهورية مصر العربية)',
  EUR = 'EUR (€ - Europe - Europe)',
  GBP = 'GBP (£ - UK - United Kingdom)',
  GTQ = 'GTQ (Q - Guatemala - República de Guatemala)',
  HKD = 'HKD (HK$ - Hong Kong, SAR of China - 中国香港)',
  HNL = 'HNL (L - Honduras - Honduras)',
  HRK = 'HRK (kn - Croatia - Hrvatska)',
  HUF = 'HUF (Ft - Hungary - Magyarország)',
  IDR = 'IDR (Rp - Indonesia - Republik Indonesia)',
  ILS = 'ILS (₪ - Israel - ɪzreɪəl)',
  INR = 'INR (र - India - इंडिया)',
  IRR = 'IRR (﷼ - Iran - Islamic Republic of Iran)',
  JPY = 'JPY (¥ - Japan - 日本)',
  KHR = 'KHR (៛ - Cambodia - Kingdom of Cambodia)',
  KRW = 'KRW (₩ - Korea (South) - 대한민국)',
  KWD = 'KWD (KD - Kuwait - State of Kuwait)',
  LBP = 'LBP (ل.ل - Lebanon - Lebanese Republic)',
  LVL = 'LVL (Ls - Latvia - Latvija)',
  MAD = 'MAD (MAD - Morocco - Kingdom of Morocco)',
  MMK = 'MMK (K - Myanmar - Burma)',
  MNT = 'MNT (₮ - Mongolia - Монгол улс)',
  MOP = 'MOP ($ - Macau, China - 澳門)',
  MXN = 'MXN ($ - Mexico - México)',
  MYR = 'MYR (rm - Malaysia - Malaysia)',
  NOK = 'NOK (kr - Norway - Norge)',
  NZD = 'NZD ($ - New Zealand - New Zealand)',
  PEN = 'PEN (S/. - Peru - Republic of Peru)',
  PHP = 'PHP (₱ - Philippines - Pilipinas)',
  PKR = 'PKR (₨ - Pakistan - اسلامی جمہوریۂ پاکستان)',
  PLN = 'PLN (pl - Poland - Polska)',
  QAR = 'QAR (ر.ق - Qatar - دولة قطر)',
  RON = 'RON (lei - Romania - Roumania)',
  RUB = 'RUB (руб - Russia - Российская Федерация)',
  SAR = 'SAR (﷼ - Saudi Arabia - المملكة العربية السعودية)',
  SEK = 'SEK (kr - Sweden - Konungariket Sverige)',
  SGD = 'SGD (S$ - Singapore - 新加坡共和国)',
  THB = 'THB (฿ - Thailand - ราชอาณาจักรไทย)',
  TRY = 'TRY (TL - Turkey - Türkiye)',
  TWD = 'TWD (NT$ - Taiwan - 臺灣)',
  UAH = 'UAH (₴ - Ukraine - Україна)',
  USD = 'USD ($ - United States - United States)',
  VEF = 'VEF (BS - Venezuela - Bolivarian Republic of Venezuela)',
  VND = 'VND (₫ - Vietnam - Socialist Republic of Vietnam)',
  ZAR = 'ZAR (R - South Africa - South Africa)',
}

export type CurrencyIdMap = Record<Currency, number>;

export const currencyIdMap: CurrencyIdMap = {
  USD: 1,
  CNY: 2,
  KRW: 3,
  TWD: 4,
  THB: 5,
  MXN: 6,
  ARS: 7,
  AUD: 8,
  BRL: 9,
  GBP: 10,
  EUR: 11,
  JPY: 12,
  RUB: 13,
  PHP: 14,
  SEK: 15,
  CAD: 16,
  SGD: 17,
  DKK: 18,
  MYR: 19,
  CHF: 20,
  NOK: 21,
  PLN: 22,
  BGN: 23,
  CZK: 24,
  HUF: 25,
  LVL: 26,
  RON: 27,
  UAH: 28,
  HRK: 29,
  TRY: 30,
  HKD: 31,
  ZAR: 32,
  INR: 33,
  CLP: 34,
  NZD: 35,
  IDR: 36,
  VEF: 37,
  COP: 38,
  PEN: 39,
  GTQ: 40,
  HNL: 41,
  CRC: 42,
  MOP: 43,
  MNT: 44,
  MMK: 45,
  VND: 46,
  KHR: 47,
  SAR: 48,
  PKR: 49,
  QAR: 50,
  EGP: 51,
  AED: 52,
  ILS: 53,
  BBD: 55,
  KWD: 56,
  LBP: 57,
  MAD: 58,
  DZD: 59,
  IRR: 60,
};

/**
 * @function Utility function for formatting a currency value.
 * @param value A numeric or string amount.
 * @param currency A Currency enum value.
 * @param languageCode A string with a BCP 47 language tag. Accepts values with the underscore symbol. Defaults to 'en-US' when argument is falsy.
 * @returns A formatted currency string.
 */
export function formatCurrency(value: number | string, currency: Currency, languageCode: string) {
  languageCode = languageCode ? languageCode.replace('_', '-') : 'en-US';

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat(languageCode, { style: 'currency', currency }).format(value);
}
