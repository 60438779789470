import { ExtractedExtendedEventData, ExtractEventData, UserEvent, UserEventType } from '@/utils/UserEvents/types';
import { UserEventsSubscriber } from '../userEventSubscriber';

interface MetaCAPIOptions {
  // Service API URL
  url: string;
  // Pixel IDs for which we want to send events to
  // If the amount of account Ids exceeds two we should consider batching events
  accountIds: number[];
}

export class MetaCAPI extends UserEventsSubscriber {
  private readonly url: string;
  private readonly accountIds: number[] = [];
  private readonly eventIdStorageKey = 'meta-capi-event-id';

  constructor({ url, accountIds }: MetaCAPIOptions) {
    super();

    this.url = url;
    this.accountIds = accountIds;

    /**
     * This property exists so we can deduplicate browser (Pixel) and server (CAPI) events.
     * The problem is, the code for Pixel is set up via Google Tag Manager and server events
     * are set up in this application. GTM needs to be able to pick up the same event ID when it,
     * sends those events so we put it in sessionStorage and use the same one throughout the
     * user session.
     */
    sessionStorage.setItem(this.eventIdStorageKey, this.generateEventId());
  }

  public async ping(): Promise<Response> {
    return await fetch(this.url + '/ping');
  }

  private generateEventId(): string {
    return `meta-capi-${Math.random().toString(36).slice(2)}`;
  }

  public async sendEvent<T extends UserEventType>(
    _type: T,
    _data?: ExtractEventData<UserEvent, T>,
    additionalData?: ExtractedExtendedEventData<UserEvent, T>,
  ): Promise<void | null> {
    // Only send events that contain eventName property
    if (additionalData?.meta && additionalData?.meta.eventName) {
      try {
        await Promise.all(
          this.accountIds.map((a) =>
            fetch(this.url + '/events', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Meta-Pixel-Id': a.toString(),
              },
              body: JSON.stringify({
                event_name: additionalData.meta!.eventName,
                event_time: Math.floor(Date.now() / 1000), // Epoch time
                event_source_url: window.location.href,
                event_id: sessionStorage.getItem(this.eventIdStorageKey) ?? '',
                action_source: 'website',
              }),
            }),
          ),
        );
      } catch (e) {
        // Todo: log this, but don't cause failures
      }
    } else {
      return null;
    }
  }
}
