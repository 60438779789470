import type { RouteConfig } from 'vue-router';
import { ExperimentName, ExperimentType } from '@/types/experiments';
import type { ExperimentMeta } from '@/types/experiments';
import { DOWNLOAD_APP, DESKTOP_PUBLISH, DESKTOP_DRAFTS, DESKTOP_SCHEDULED } from '@/utils/router/namedRoutes';
import { checkMobile } from '@/router/navigation-guards/beforeEnter/checkMobile';
const experimentMeta: ExperimentMeta = {
  name: ExperimentName.DESKTOP_PUBLISH,
  type: ExperimentType.FLAG,
  allowed: ['on'],
};

const routes: RouteConfig[] = [
  {
    path: '/desktop-publish',
    component: () => import(/* webpackChunkName: "desktop-publish" */ '../views/DesktopPublishShell.vue'),
    meta: {
      experiment: experimentMeta,
    },
    beforeEnter: checkMobile,
    children: [
      {
        name: DESKTOP_PUBLISH,
        path: '',
        component: () => import(/* webpackChunkName: "desktop-publish" */ '../views/DesktopPublish.vue'),
        meta: {
          experiment: experimentMeta,
        },
      },
      {
        name: DESKTOP_DRAFTS,
        path: 'drafts',
        component: () => import(/* webpackChunkName: "desktop-publish" */ '../views/DesktopDrafts.vue'),
        meta: {
          experiment: experimentMeta,
        },
      },
      {
        name: DESKTOP_SCHEDULED,
        path: 'scheduled',
        component: () => import(/* webpackChunkName: "desktop-publish" */ '../views/DesktopScheduled.vue'),
        meta: {
          experiment: experimentMeta,
        },
      },
    ],
  },
  {
    name: DOWNLOAD_APP,
    path: '/download-app',
    component: () => import(/* webpackChunkName: "publish" */ '../views/DownloadApp.vue'),
    meta: {
      experiment: experimentMeta,
    },
  },
];

export default routes;
