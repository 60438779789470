
import { defineComponent, computed, getCurrentInstance } from 'vue';
import useLinks from '@/composables/useLinks';
import { getNativeLanguage } from '@/plugins/gettext';
import { isIOS } from '@vueuse/core';

export default defineComponent({
  name: 'SiteFooter',
  setup(props, context) {
    const vm = getCurrentInstance();
    const { $language, $gettext } = vm?.proxy as Vue;
    const { siteLinks, socialLinks } = useLinks($language);
    const isIOSDeviceClass = computed(() => (isIOS ? 'ios-device' : ''));

    const date = new Date().getFullYear();

    const titles = computed(() => ({
      followUs: $gettext('Follow us'),
      learnMore: $gettext('Learn more'),
      legal: $gettext('Legal'),
      support: $gettext('Support'),
    }));

    const language = computed(() => getNativeLanguage(($language.current as Locales) || 'en_US'));

    return {
      titles,
      siteLinks,
      socialLinks,
      language,
      date,
      isIOSDeviceClass,
    };
  },
});
