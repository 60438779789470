
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watch } from 'vue';
import { RwDialog, RsButton, RsButtonGroup } from '@/lib';
import { usePublishStore } from '@/store/publish';
import { storeToRefs } from 'pinia';
import { objectHasNoValues } from '@/utils/objectHasNoValues';
import { useHashtagsStore } from '@/store/hashtags';
import { usePostProductsStore } from '@/store/postProducts';
import { appMessaging } from '@/utils/messaging';
import { useDraftsStore } from '@/store/drafts';
import { usePublishingAnalytics } from '../composables';
import { useRouter } from 'vue-router/composables';

export default defineComponent({
  name: 'SaveDraftDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: { submit: (isSubmitted: boolean) => isSubmitted, discard: (isDiscarded: boolean) => isDiscarded },
  components: {
    RwDialog,
    RsButton,
    RsButtonGroup,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const { $gettext, $userEvents } = vm?.proxy as Vue;
    const router = useRouter();
    const messaging = appMessaging();
    const dialogBodyText = computed(() => $gettext('Do you want to save your changes?'));
    const discardText = computed(() => $gettext('Discard'));
    const saveDraftText = computed(() => $gettext('Save draft'));
    const postProductsStore = usePostProductsStore();
    const state = reactive({
      active: false,
    });

    const publishStore = usePublishStore();
    const hashtagStore = useHashtagsStore();
    const { ltkPostRequest, draftable } = storeToRefs(usePublishStore());
    const draftsStore = useDraftsStore();
    const { draftNotSaved, shouldCloseAfterSaved } = storeToRefs(useDraftsStore());
    const { publishingEvent } = usePublishingAnalytics($userEvents, router.currentRoute);

    watch(
      () => props.value,
      () => {
        if (!draftsStore.isDirty()) {
          return false;
        }
        const { accepted_copyright_terms, ...restObject } = ltkPostRequest.value || {};
        if (!objectHasNoValues(restObject) || draftable.value) {
          state.active = !state.active;
        }
      },
    );

    watch(
      () => draftNotSaved.value,
      () => {
        if (draftNotSaved.value === true) {
          state.active = !state.active;
          emit('discard', false);
          emit('submit', false);
        }
      },
    );

    const onDiscard = () => {
      state.active = !state.active;
      if (draftNotSaved.value === false || shouldCloseAfterSaved.value === true) {
        resetStores();
        messaging.webViewSendMessage('close-webview');
        publishingEvent('/publishing/exit');
      } else {
        emit('discard', true);
      }
    };

    const onSaveDraft = () => {
      state.active = !state.active;
      emit('submit', true);
    };

    const resetStores = () => {
      publishStore.$reset();
      postProductsStore.reset();
      hashtagStore.$reset();
      draftsStore.$reset();
    };

    return {
      dialogBodyText,
      discardText,
      saveDraftText,
      state,
      onDiscard,
      onSaveDraft,
    };
  },
});
