
import { defineComponent, PropType, toRefs, onMounted, watch, computed } from 'vue';
import { useExperimentsStore } from '@/store/experiments';
import {
  ExperimentType,
  ExperimentVariant,
  ActiveExperimentName,
  ExtractActiveExperimentType,
  ExtractActiveExperimentValues,
} from '@/types/experiments';

// TODO: Rewrite as a generic component in Vue 3 to support stricter type checking based on the `useExperiment()` function signature. See https://vuejs.org/api/sfc-script-setup.html#generics for reference.
export default defineComponent({
  name: 'SplitTest',
  props: {
    name: {
      type: String as PropType<ActiveExperimentName>,
      required: true,
    },
    type: {
      type: String as PropType<ExtractActiveExperimentType<ActiveExperimentName>>,
      default: ExperimentType.VARIANT,
    },
    defaultValue: {
      type: [String, Object] as PropType<
        ExtractActiveExperimentValues<ActiveExperimentName> | ExperimentVariant<ActiveExperimentName> | undefined
      >,
      default: undefined,
    },
  },
  setup(props) {
    const { name, type } = toRefs(props);

    /**
     * Unwrap the default value if it's an `ExperimentVariant` object.
     */
    const defaultValue = computed<ExtractActiveExperimentValues<ActiveExperimentName> | undefined>(() =>
      typeof props.defaultValue === 'object' && props.defaultValue !== null
        ? props.defaultValue.value
        : props.defaultValue,
    );

    const { useExperiment } = useExperimentsStore();

    const { variant, exposure } = useExperiment(name, type, defaultValue);

    onMounted(exposure);

    watch(
      () => name.value,
      () => exposure(),
    );

    return { variant };
  },
});
