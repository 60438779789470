
import { defineComponent, watch, reactive, toRefs, PropType, computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router/composables';

import { Route } from 'vue-router';
import { NavigationItem } from '@/lib/models/NavigationItem';

export default defineComponent({
  name: 'RsSidebarItem',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    children: {
      type: Array as PropType<NavigationItem[]>,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
    spyOn: {
      type: String,
    },
    level: {
      type: [Number, String],
      default: 1,
    },
  },
  emits: ['update:active'],
  setup(props, { emit, slots }) {
    const $route = useRoute();

    const state = reactive({
      exactActive: false,
      shouldActivate: false,
    });
    const hasSlots = (name: string) => !!slots[name];

    const hasMatchedChildren = (route: Route, children: NavigationItem[]): boolean => {
      // TODO: Turn this into its own composable.
      const matchedChildren = children?.map((child) => {
        const rootPath = route?.path?.split('#')[0] ?? route?.path;
        const rootChildPath = child.to?.split('#')[0] ?? child.to;
        return rootPath === rootChildPath;
      });
      return children && matchedChildren.includes(true);
    };

    const spyMatched = (item: NavigationItem, activeId: string | undefined) => {
      if (item.to && activeId && props.spyOn) {
        const route = $route;
        const routeUrl = route.path?.split('#')[0];
        const pageUrl = item.to.split('#')[0];
        const hash = item.to.split('#')[1];
        if (routeUrl === pageUrl && hash === activeId) {
          return true;
        }
      }
    };

    watchEffect(
      () => {
        state.exactActive = $route?.path === props.to?.split('#')[0];
        if (hasMatchedChildren($route, props.children)) {
          state.shouldActivate = true;
        } else if (props.autoClose) {
          state.shouldActivate = false;
        }
      },
      { flush: 'post' },
    );

    watch(
      () => props.active,
      (next) => {
        if (next) {
          state.shouldActivate = props.active;
        }
      },
    );

    watch(
      () => state.shouldActivate,
      (next) => {
        emit('update:active', next);
      },
    );

    const togglePanel = () => {
      state.shouldActivate = !state.shouldActivate;
    };

    const tabLevel = computed(() => {
      return {
        [`is--${props.level}`]: true,
      };
    });

    const classes = computed(() => {
      return {
        'is--dark': props.dark,
        'is--active': props.active,
        'is--exact-active': state.exactActive && props.spyOn === 'top',
      };
    });

    return { ...toRefs(state), classes, togglePanel, spyMatched, hasSlots, tabLevel };
  },
});
