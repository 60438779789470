
import { defineComponent, computed } from 'vue';
import uuid from '../../utils/uuid';
export default defineComponent({
  name: 'RsButtonGroup',
  props: {
    id: {
      type: String,
      default: () => 'rs-button-group' + uuid(),
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        'is--stacked': props.stacked,
        'is--grouped': props.grouped,
        'is--full': props.full,
      };
    });
    return {
      classes,
    };
  },
});
