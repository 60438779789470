import type { InjectionKey, Ref } from 'vue';
import type { Mode } from '@/rs-account-setup/composables/useAccountSetupWizard';

export interface LeavePagePopupLayoutProvider {
  enable: () => boolean;
  disable: () => boolean;
}

export interface AccountSetupWizardProvider {
  mode: Readonly<Ref<Mode>>;
  next: () => Promise<void>;
}

export interface ApplicationWizardProvider {
  next: () => Promise<void>;
}

export interface UseSavedDraftsModalProvider {
  submitted: Ref<boolean>;
  discarded: Ref<boolean>;
}

export interface LeaveEditPostPagePopupProvider {
  enable: () => boolean;
  disable: () => boolean;
}

export const leavePagePopupLayoutInjector: InjectionKey<LeavePagePopupLayoutProvider> = Symbol('LeavePagePopupLayout');

export const FetchAccountUserDataInjector: InjectionKey<Promise<unknown>> = Symbol('FetchAccountUserData');

export const accountSetupWizardInjector: InjectionKey<AccountSetupWizardProvider> = Symbol('AccountSetupWizard');

export const applicationWizardInjector: InjectionKey<ApplicationWizardProvider> = Symbol('ApplicationWizard');

export const useSavedDraftsModalInjector: InjectionKey<UseSavedDraftsModalProvider> = Symbol('SavedDrafts');

export const LeaveEditPostPagePopupInjector: InjectionKey<LeaveEditPostPagePopupProvider> = Symbol('DeletePostModal');
