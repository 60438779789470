import { NavigationGuard } from 'vue-router';
import {
  HOME,
  RESET_PASSWORD,
  ACCOUNT_SETUP,
  isLegalRoute,
  isSetupRoute,
  isAnyApplicationRoute,
} from '@/utils/router/namedRoutes';
import AccountsStore from '@/store/accounts';
import UserInfoStore from '@/store/userInfo';
import { UserStatus } from '@/utils/decodeUserStatus';
import { redirectToAccountSetup } from '@/rs-account-setup/utils/redirectToAccountSetup';
import { auth } from '@/utils/auth';

export const checkUserStatus: NavigationGuard = async function (to, from, next) {
  // Allow skipping status checks in routes like /logout
  const requiresStatusCheck: boolean | undefined = to.meta?.statusCheck?.required ?? true;

  if (!requiresStatusCheck) {
    return next();
  }

  /**
   * Check Authorization
   */

  // Unauthorized user: Hard redirect to Login
  if (UserInfoStore.unauthorized) {
    return auth.login({
      options: {
        appState: {
          target: 'https://' + window.location.host + '/home',
        },
      },
      next: 'https://' + window.location.host + '/home',
    });
  }

  /**
   * Check Blacklist Status - FOR AGENTS
   */

  // Blacklisted User: Log out and Redirect to Collabs
  if (AccountsStore.user.is_blacklisted) {
    await auth.logout();

    const { COLLABORATIONS_PAGE } = window.rewardStyle;
    return (window.location.href = COLLABORATIONS_PAGE);
  }

  /**
   * Check Status: Account Setup, Terms of Service, Password Change, etc.
   */

  // Legal pages: Must be accessible despite any and all (authorized) user states
  if (isLegalRoute(to)) {
    return next();
  }

  const requiresAccountSetup = redirectToAccountSetup(to, {
    isOwner: AccountsStore.user.is_owner,
    needsToAcceptTerms: AccountsStore.user.needs_accept_terms,
    hasCompleteStatus: AccountsStore.userStatusRoles.includes(UserStatus.COMPLETE),
  });

  if (requiresAccountSetup) {
    return next({ name: ACCOUNT_SETUP });
  }

  const requiresPasswordChange =
    !isAnyApplicationRoute(to) &&
    !isSetupRoute(to) &&
    UserInfoStore.userInfo.require_password_change &&
    to.name !== RESET_PASSWORD; // Excluding RESET_PASSWORD so we don't get stuck in an infinite loop

  const redirectPasswordChange = !UserInfoStore.userInfo.require_password_change && to.name === RESET_PASSWORD;

  if (requiresPasswordChange) {
    return next({ name: RESET_PASSWORD });
  } else if (redirectPasswordChange) {
    return next({ name: HOME });
  }

  return next();
};
