
import { defineComponent, ref, computed, getCurrentInstance } from 'vue';
import Cookie from 'js-cookie';
import { computedAsync, isIOS } from '@vueuse/core';
import { RsButton } from '@/lib';

import SiteListNavItem from '@/components/SiteListNavItem.vue';
import GlobalLanguageSelectLink from '@/components/GlobalLanguageSelectLink.vue';

import useLinks from '@/composables/useLinks';
import DownloadBadges from './DownloadBadges.vue';
import AccountsStore from '@/store/accounts/index';
import legacyHomePageLink from '@/utils/legacyHomePageLink';
import useNavigationLinks from '@/composables/useNavigationLinks';
import { UserStatus } from '@/utils/decodeUserStatus';
import { auth } from '@/utils/auth';
import { RsSidebar, RsSidebarItem } from '@/lib';
import { GlobalElementId } from '@/utils/globalElementIds';
import { VuetifyBreakpointCode } from '@/lib/models/NavigationItem';
import { ExperimentName, ExperimentType } from '@/types/experiments';
import SplitTest from './SplitTest.vue';
import { usePublishingAnalytics } from '@/rs-publish/composables';

const { LEGACY_HOME_PAGE } = window.rewardStyle;
export default defineComponent({
  name: 'SiteHeader',
  components: {
    DownloadBadges,
    SiteListNavItem,
    GlobalLanguageSelectLink,
    RsButton,
    RsSidebar,
    RsSidebarItem,
    SplitTest,
  },
  setup(props, context) {
    const vm = getCurrentInstance();
    const { $vuetify, $gettext, $language, $userEvents, $route } = vm?.proxy as Vue;
    const { accountLinks, siteNavigation } = useNavigationLinks($gettext);
    const { socialLinks } = useLinks($language);
    const { publishingEvent } = usePublishingAnalytics($userEvents, $route);
    const isNavOpen = ref(false);

    const logOutLink = ref(legacyHomePageLink('/logout'));

    const displayEmail = computedAsync(async () => {
      const emailFallback = AccountsStore.user.email;
      const idTokenClaims = await auth.getIdTokenClaims();
      return idTokenClaims?.email ?? emailFallback;
    });

    const accountLabel = computed(() => $gettext('My Account'));

    const logout = () => {
      $userEvents.sendEvent('/auth/logout/success', {
        page: $route.path,
        referrer: 'logout',
        'root-referrer': 'global',
      });

      auth.logout({
        logoutParams: {
          returnTo: 'https://' + window.location.host + '/home',
        },
        // Todo: Delete this redirect interception once we remove rewardstyle-v2 as an auth proxy
        //   Right now this only exists to clear out sigil cookies and users only need to do it once
        openUrl(target) {
          window.location.replace(Cookie.get('sigil_id_token') !== undefined ? legacyHomePageLink('/logout') : target);
        },
      });
    };

    /**
     * @description Determines whether the viewport is at a specific breakpoint.
     */
    const isAtBreakpoint = (value: VuetifyBreakpointCode): boolean => {
      const breakpoints = $vuetify.breakpoint;
      return breakpoints[value];
    };

    // Note: An approved applicant is APPROVED. So this is seperating applicants from creators
    const isApprovedUser = computed(() => AccountsStore.userStatusRoles.includes(UserStatus.APPROVED));

    const trackEvent = () => {
      publishingEvent('/publishing/enter-flow');
    };

    return {
      GlobalElementId,
      legacyHomePage: LEGACY_HOME_PAGE,
      logOutLink,
      logout,
      isAtBreakpoint,
      isApprovedUser,
      displayEmail,
      accountLabel,
      isNavOpen,
      accountLinks,
      siteNavigation,
      socialLinks,
      isIOS,
      ExperimentName,
      ExperimentType,
      trackEvent,
    };
  },
});
