import { Component, computed, ref } from 'vue';
import { Layout } from '@/layouts/types';
import EmptyLayout from '@/layouts/components/EmptyLayout.vue';
import DefaultLayout from '@/layouts/components/DefaultLayout.vue';
import SignupLayout from '../components/application/SignupLayout.vue';
import ApplicationLayout from '@/layouts/components/application/ApplicationLayout.vue';
import ReferralsLayout from '@/layouts/components/application/ReferralsLayout.vue';
import Publish from '@/layouts/components/PublishingLayout.vue';

import { useRoute, useRouter } from 'vue-router/composables';

export const layouts: Readonly<Record<Layout, Component>> = {
  [Layout.EMPTY]: EmptyLayout,
  [Layout.DEFAULT]: DefaultLayout,
  [Layout.SIGNUP]: SignupLayout,
  [Layout.APPLICATION]: ApplicationLayout,
  [Layout.REFERRALS]: ReferralsLayout,
  [Layout.PUBLISH]: Publish,
};

export function useLayout() {
  const $router = useRouter();

  const isReady = ref(false);

  $router.onReady(() => {
    // Wait until the app has been bootstrapped in order to avoid FLOUC
    isReady.value = true;
  });

  const $route = useRoute();

  const layout = computed<Component>(() => {
    const currentLayout = $route.meta?.layout;

    if (!isReady.value) {
      return layouts[Layout.EMPTY];
    }

    if (!currentLayout) {
      return layouts[Layout.DEFAULT];
    }

    return layouts[currentLayout];
  });

  return {
    layout,
  };
}
