import 'vue-moment';
import _Vue from 'vue';
import registerUiLibrary from './rs-ui-library';
import registerGettext from './gettext';
import registerScrollLoader from './vue-scroll-loader';
import registerGoogleTagManager from './google-tag-manager';
import registerClipboard from '@rscollabs/rs-core-library/src/plugins/clipboard';
import registerMoment from '@rscollabs/rs-core-library/src/plugins/moment';
import registerDatadog from '@/plugins/datadog';
import registerPortalVue from '@/plugins/portal-vue';
import { registerUserEvents } from '@/plugins/user-events';
import { registerSnowplow } from '@/plugins/snowplow';

export default (Vue: typeof _Vue) => {
  registerDatadog(Vue);
  registerUserEvents(Vue);
  registerUiLibrary(Vue);
  registerClipboard(Vue);
  registerGettext(Vue);
  registerMoment(Vue);
  registerScrollLoader(Vue);
  registerGoogleTagManager(Vue);
  registerPortalVue(Vue);
  registerSnowplow(Vue);
};
