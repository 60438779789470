/**
 * Enumeration of all Vue components under `@/layouts`.
 * The values are intended to be the kebab-cased names of each layout component.
 */
export enum Layout {
  EMPTY = 'empty-layout',
  DEFAULT = 'default-layout',
  SIGNUP = 'signup-layout',
  APPLICATION = 'application-layout',
  REFERRALS = 'application-referrals',
  PUBLISH = 'publish-layout',
}
