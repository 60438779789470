import { RouteConfig } from 'vue-router';
import { LEGAL, SMS_TERMS, PRIVACY, TOS } from '@/utils/router/namedRoutes';

const routes: RouteConfig[] = [
  {
    path: '/legal',
    name: LEGAL,
    component: () => import(/* webpackChunkName: "tos" */ '../views/TosLoader.vue'),
    meta: {
      auth: {
        required: false,
      },
      useExchangeRate: false,
    },
    children: [
      {
        path: '/sms-terms',
        name: SMS_TERMS,
        component: () => import(/* webpackChunkName: "sms-tos" */ '../components/SmsTos.vue'),
        meta: {
          useExchangeRate: false,
        },
      },
      {
        path: '/privacy',
        name: PRIVACY,
        beforeEnter: (to, from, next) => {
          window.location.replace(`${window.rewardStyle.MARKETING_PAGE}/privacy`);
        },
        meta: {
          auth: {
            required: false,
          },
          useExchangeRate: false,
        },
      },
      {
        path: '/terms',
        name: TOS,
        component: () => import(/* webpackChunkName: "tos" */ '../components/Tos.vue'),
        meta: {
          useExchangeRate: false,
        },
      },
    ],
  },
];

export default routes;
