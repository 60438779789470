import { computed } from 'vue';

import { ILanguageComponent } from 'vue/types/vue';
import { getMarketingPageQueryString } from '@/plugins/gettext';
import { useAsyncState } from '@vueuse/core';
import { auth } from '@/utils/auth';

const useLinks = (lang: ILanguageComponent) => {
  const { state: isAuthenticated } = useAsyncState(auth.isAuthenticated(), false);

  const consumerPlatformPage = window.rewardStyle.CONSUMER_PLATFORM_PAGE;
  const marketingPage = window.rewardStyle.MARKETING_PAGE;
  const helpCenter = `https://${window.rewardStyle.HELP_CENTER_HOST}/hc`;

  const marketingPageQueryString = computed(() => getMarketingPageQueryString(lang.current as Locales));

  const socialLinks = {
    appStore: `https://ltk.app.link/fLHsPHg3MR`,
    playStore: `https://ltk.app.link/1LZaa5FqXZ`,
    radioLink: `${marketingPage}/more-to-say`,
    facebook: `https://www.facebook.com/shop.ltk/`,
    youtube: `https://www.youtube.com/channel/UCw_WZUDMPB0TDpWXtbowKKA/featured`,
    instagram: {
      shopltk: `https://www.instagram.com/shop.LTK`,
      home: `https://www.instagram.com/ltk.home`,
      family: `https://www.instagram.com/ltk.family`,
      europe: `https://www.instagram.com/ltk.europe`,
      brasil: `https://www.instagram.com/ltk.brasil`,
      australia: `https://www.instagram.com/ltk.australia`,
    },
  };

  const siteLinks = computed(() => {
    return {
      ltkLink: consumerPlatformPage,
      becomeCreator: `${marketingPage}/en/creator`,
      brandPlatform: `${marketingPage}/brands${marketingPageQueryString.value}`,
      newsroom: `${marketingPage}/press${marketingPageQueryString.value}`,
      company: `${marketingPage}/company${marketingPageQueryString.value}`,
      career: `${marketingPage}/careers${marketingPageQueryString.value}`,
      terms: isAuthenticated.value
        ? '/terms'
        : `${marketingPage}/ltk-terms-of-service${marketingPageQueryString.value}`,
      privacy: `${marketingPage}/privacy`,
      sms: '/sms-terms',
      companySms: `${marketingPage}/sms-terms`,
      cookie: `${marketingPage}/ltk-cookie-policy${marketingPageQueryString.value}`,
      doNotSell: `${marketingPage}/ltk-do-not-sell${marketingPageQueryString.value}`,
      patents: `${consumerPlatformPage}/legal/virtual-patent`,
      help: helpCenter,
      helpNewRequest: `${helpCenter}/requests/new`,
      contactUs: '/account/contact-us',
      changeLanguage: '/account/account-details#personal-info',
      brandApply: `${marketingPage}/brands${marketingPageQueryString.value}`,
    };
  });

  return { socialLinks, siteLinks };
};

export default useLinks;
