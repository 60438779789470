import { UserInfoResponse } from '@/store/userInfo/types';
import { processResponse, Api } from '@/utils/api';

const config = window.rewardStyle;
const keyNotAuthorized = 'Key not authorised';

class UserInfoApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/co-api/v1/get_user_info`;
  public header: string;
  public unauthorized: boolean;

  public async get() {
    const response = await this.sendRequest('GET', '');
    this.header = response.headers.get('www-authenticate') || '';

    const cloneResponse = response.clone();
    const jsonResponse = await cloneResponse.json();
    this.unauthorized = response.status == 401 || jsonResponse.error === keyNotAuthorized;
    return processResponse<UserInfoResponse>(response);
  }
}

export default new UserInfoApi();
