
import Vue, { defineComponent, computed, getCurrentInstance } from 'vue';
import uuid from '../../utils/uuid';
export default defineComponent({
  name: 'RsBreadcrumbItem',
  props: {
    id: {
      type: String,
      default: () => 'rp-breadcrumb-item-' + uuid(),
    },
    to: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    lastChild: {
      type: Boolean,
      default: false,
      // Need for aria-current=page
      // last item in loop should have logic to determin lastChild
      // ex: `:lastChild="index === breadcrumbs.length - 1 ? true : false"`
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const { $gettext } = vm?.proxy as Vue;

    const rootClass = 'rs--breadcrumb-item';

    const themeSelector = computed(() => {
      if (props.theme === 'default') {
        return props.primary ? 'primary' : 'default';
      } else {
        return props.theme;
      }
    });

    const themeClass = computed(() => {
      return `${rootClass}__${themeSelector.value}`;
    });

    const classes = computed(() => {
      return {
        'is--dark': props.dark,
        'is--active': props.active,
      };
    });

    const linkLabel = computed(() => $gettext(props.label));

    return {
      rootClass,
      themeClass,
      classes,
      linkLabel,
    };
  },
});
