import {
  type LtkPost,
  type CreateLtkRequest,
  type PublishLtkRequest,
  type DeleteLtkResponse,
  type Ltks,
  type Params,
} from '@/types/ltks';
import { Api, processResponse } from '@/utils/api';

const config = window.rewardStyle;

class LtksApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/ltk/v3/`;

  headers = {
    'Content-Type': 'application/json',
    'x-data-storytelling': 'true',
    'User-Agent': process.env.VUE_APP_VERSION
      ? `RSTHECOM-V2/${process.env.VUE_APP_VERSION} ${navigator.userAgent}`
      : `RSTHECOM-V2 ${navigator.userAgent}`,
  };

  public createLtk = async (body: CreateLtkRequest): Promise<LtkPost> => {
    const response = await this.sendRequest('POST', 'posts', {
      body: JSON.stringify(body),
      headers: this.headers,
    });
    return processResponse<LtkPost>(response);
  };

  public publishLtk = async (id: string | null, body: PublishLtkRequest) => {
    const response = await this.sendRequest('PATCH', `posts/${id}`, {
      body: JSON.stringify(body),
      headers: this.headers,
    });
    return processResponse<LtkPost>(response);
  };

  public getLtks = async (queryParams: Params) => {
    const sideLoads = this.formatSideLoads([
      ['cover_photos'],
      ['media_objects'],
      ['products'],
      ['product_media_objects'],
    ]);

    const params = new URLSearchParams(this.queryPairs(queryParams));
    const response = await this.sendRequest('GET', `posts/?${params}${sideLoads}`, {
      headers: this.headers,
    });
    return processResponse<Ltks>(response);
  };

  public getScheduledLtks = async (queryParams: Params, order: 'asc' | 'desc') => {
    const sideLoads = this.formatSideLoads([
      ['cover_photos'],
      ['media_objects'],
      ['products'],
      ['product_media_objects'],
      ['schedules'],
    ]);

    const params = new URLSearchParams(this.queryPairs(queryParams));
    const response = await this.sendRequest('GET', `posts/?${params}${sideLoads}&order=${order}`, {
      headers: this.headers,
    });
    return processResponse<Ltks>(response);
  };

  public getLtk = async (postId: string, queryParams?: Params) => {
    const sideLoads = this.formatSideLoads([
      ['cover_photos'],
      ['media_objects'],
      ['products'],
      ['product_media_objects'],
    ]);
    const params = new URLSearchParams(this.queryPairs(queryParams));
    const response = await this.sendRequest('GET', `posts/${postId}?${params}${sideLoads}`, {
      headers: this.headers,
    });
    return processResponse<LtkPost>(response);
  };

  public deleteLtk = async (id: string | null) => {
    const response = await this.sendRequest('DELETE', `posts/${id}`, {
      headers: this.headers,
    });
    return processResponse<DeleteLtkResponse>(response);
  };

  private queryPairs = (parameters: any) =>
    Object.keys(parameters ?? {})
      .filter((key) => parameters[key as keyof typeof parameters] !== undefined)
      .map((key) => [key, parameters[key as keyof typeof parameters]]);

  private formatSideLoads = (sideLoads: any[]) => {
    return `&includes[]=${sideLoads.join('&includes[]=')}`;
  };
}

export default new LtksApi();
