import { ref } from 'vue';
import ApplicationApi from '@/services/applicationApi';

export default function useCountries() {
  const countryOptions = ref<SelectInputItem[]>([]);

  async function getCountriesResponse() {
    const countriesLocalStorage = localStorage.getItem('ltk-countries');

    if (countriesLocalStorage) {
      countryOptions.value = JSON.parse(countriesLocalStorage);
    } else {
      const countries = await ApplicationApi.getCountries();

      const newCountryOptions = countries
        .map((country) => ({ value: country.id.toUpperCase(), text: country.name }))
        .filter((country) => country.value);

      countryOptions.value.splice(0, countryOptions.value.length, ...newCountryOptions);

      // Cache in localStorage
      localStorage.setItem('ltk-countries', JSON.stringify(countryOptions.value));
    }
  }

  return {
    countryOptions,
    getCountriesResponse,
  };
}
