import Vue from 'vue';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { ApiErrorResponse } from '@/utils/api/types';
// Favorites
import {
  DeleteMyFolderFavoriteParams,
  FavoritesState,
  Favorite,
  FavoritesServiceMeta,
  FavoritesMap,
  FolderFavoritesMap,
  FolderFavorites,
  UpdateMyFavoriteParams,
} from '@/store/favorites/types';
import favoritesApi, { SearchParams, SortParams } from '@/store/favorites/api';
// Folders
import { MultipleFolderResponse, FolderPreview } from '@/store/folders/types';
import foldersStore from '@/store/folders';
// Products
import productsStore from '@/store/products';
import retailerProductImagesApi from '@/store/products/retailerProductImagesApi';
import { ProductImage } from '@/store/products/types';
import { FavoriteSortOptions } from '@/store/favorites/constants';
import { FolderSortOptions } from '../folders/constants';
import productReviewsStore from '../productReviews';

export const emptyFolderFavoritesFactory: () => FolderFavorites = () => ({ ids: [], meta: null, query: '' });
export const allSavedFolderId = 'all-saved';

@Module({ dynamic: true, name: 'favorites', store, namespaced: true })
class Favorites extends VuexModule implements FavoritesState {
  public favorite: Favorite | null = null;
  public favoriteMap: FavoritesMap = {};
  public folderFavoritesMap: FolderFavoritesMap = {};
  public folderPreviewMap: FolderFavoritesMap = {};
  public favoritesMetaLastId: string | null = null;
  public serverError: ApiErrorResponse | null = null;
  public favoriteSortParameter: FavoriteSortOptions = FavoriteSortOptions.DATE_TIME_CREATED;
  public recentFavoriteIds: string[] = [];
  public recentFavoritesMetaLastId: string | null = null;
  public favoriteQuery: string = '';
  public searchedFavoriteIds: string[] = [];
  public searchedFavoriteIdsHasMorePages: boolean = false;

  get getFavoriteProductId() {
    return (favoriteId: string) => this.favoriteMap[favoriteId]?.product_id;
  }
  // Folder ids are sorted by the date they were favorited.
  // Use fetchAndReorderFavoriteFolderIds to sort to different order.
  get getFavoriteFoldersIds() {
    return (favoriteId: string) => this.favoriteMap[favoriteId]?.folder_ids;
  }

  get getSingleFolderFavoritesObject() {
    return (folderId: string) => {
      const folderFavoriteObject = this.folderFavoritesMap[folderId.toLowerCase()] ?? emptyFolderFavoritesFactory();
      return folderFavoriteObject;
    };
  }

  get hasMoreRecentFavorites() {
    return !!this.recentFavoritesMetaLastId;
  }

  get hasMoreSearchedFavorites() {
    return this.searchedFavoriteIdsHasMorePages;
  }

  @Action
  public async resetQueryAndSort() {
    if (this.favoriteQuery !== '') {
      this.UPDATE_SEARCH_QUERY('');
    }
    if (this.favoriteSortParameter !== FavoriteSortOptions.DATE_TIME_CREATED) {
      this.UPDATE_FAVORITE_SORT_PARAMETER(FavoriteSortOptions.DATE_TIME_CREATED);
    }
  }

  @Action
  public async resetFolderFavorites(folderId: string) {
    this.RESET_FOLDERS_FAV_MAP(folderId);
  }

  @Action
  public async fetchAllSaved() {
    const pageSize = 21;
    if (this.favoriteQuery !== '') {
      await this.search(pageSize);
    } else {
      await this.fetchRecentFavoriteIds(pageSize);
    }
  }

  @Action
  public async fetchFavoritesList({
    isAllSaved = false,
    folderId,
    query,
  }: {
    isAllSaved: Boolean;
    folderId: string;
    query: string;
  }) {
    if (isAllSaved) {
      await this.fetchAllSaved();
    } else {
      await this.fetchFolderFavoritesPage({
        query: query,
        folderIds: [folderId],
        overwriteBool: false,
        sorting: true,
        allSaved: false,
      });
    }
  }

  @Action
  public async fetchFavorite(productId: string) {
    let results;

    try {
      results = await favoritesApi.get(productId);
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
      return;
    }

    const [favorite] = results.favorites;
    if (favorite === undefined) {
      this.FETCH_FAVORITE(null);
      return;
    }

    this.FETCH_FAVORITE(favorite);
  }

  @Action
  public async fetchUserMostRecentFavorite() {
    try {
      const results = await favoritesApi.fetchMultipleUserRecentFavorites({ limit: 1 });
      if (results.favorites.length > 0) {
        const userRecentFavorite = results.favorites[0];
        await productsStore.fetchProductsCoApiDetails([userRecentFavorite.product_id]);
        await productReviewsStore.fetchProductReviews([userRecentFavorite.product_id]);
        return userRecentFavorite.id;
      } else {
        return '';
      }
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
      return '';
    }
  }

  // Favorited folders are sorted by the date they were favorited on initial load.
  // This function sorts folders and fetches them from the backend.
  @Action
  public async fetchAndReorderFavoriteFolderIds(favoriteId: string) {
    const folderIds = this.favoriteMap[favoriteId]?.folder_ids;

    if (!folderIds || folderIds.length == 0) {
      // Nothing to fetch.
      return;
    }

    const folders = await foldersStore.fetchFoldersById({
      folderIds: folderIds,
      sortOrder: FolderSortOptions.NAME_ASCENDING,
    });

    this.UPDATE_FAVORITE_FOLDER_IDS({ favoriteId, folderIds: folders.map((folder) => folder.id) });
  }

  @Action
  public async fetchMultipleRecentFavorites(limit: number) {
    try {
      const results = await favoritesApi.fetchMultipleUserRecentFavorites({ limit: limit });
      if (results.favorites.length > 0) {
        await this.appendFavorites(results.favorites);
        const recentFavoriteProductIds = results.favorites.map((favorite) => {
          const [rawId, type] = favorite.product_id.split(':');
          return rawId;
        });

        const { retailer_product_images } = await retailerProductImagesApi.get(recentFavoriteProductIds);
        await this.appendProductImages(retailer_product_images);
        const favoriteIds = results.favorites.map((favorite) => favorite.id);
        return favoriteIds;
      }
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
      return [];
    }
  }

  @Action
  public async fetchFolderFavorites({
    folders,
    fetchProducts = true,
  }: {
    folders: MultipleFolderResponse;
    fetchProducts?: boolean;
  }) {
    const folderFavorites = await favoritesApi.fetchFolderRecentFavorites(folders.folders);

    if (folderFavorites === undefined) {
      return;
    }

    if (fetchProducts) {
      const productIds = folderFavorites.favorites.map((favorite) => favorite.product_id);
      await productsStore.fetchProductsCoApiDetails(productIds);
      await productReviewsStore.fetchProductReviews(productIds);
    }

    try {
      this.FETCH_FAVORITES(folderFavorites.favorites);
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async addNewFavorite(productId: string) {
    const { favorite } = await favoritesApi.create({ productId, folderIds: [] });
    this.FETCH_FAVORITE(favorite);
    foldersStore.updateNavigationFolderCounts({
      addedFolderIds: favorite.folder_ids,
      deletedFolderIds: [],
    });
    return favorite;
  }

  @Action
  public async removeFavorite() {
    const { favorite } = this;
    if (favorite === null) {
      return;
    }
    await favoritesApi.delete(favorite.id);
    this.FETCH_FAVORITE(null);
  }

  @Action
  public async deleteMyFolderFavorite(favorite_id: string) {
    const deleted_folder_ids = this.favoriteMap[favorite_id]?.folder_ids ?? [];
    await this.deleteFavorite({ favorite_id, deleted_folder_ids });
  }

  @Action
  public async deleteFavorite({ favorite_id, deleted_folder_ids }: DeleteMyFolderFavoriteParams) {
    try {
      await favoritesApi.delete(favorite_id);
      await foldersStore.fetchFoldersById({
        folderIds: deleted_folder_ids,
        sortOrder: FolderSortOptions.NAME_ASCENDING,
      });
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async addFolderFavorite(folderId: string) {
    this.ADD_FOLDER_FAVORITES(folderId);
  }

  @Action
  public async removeFolderFavorite(folderId: string) {
    this.REMOVE_FOLDER_FAVORITES(folderId);
  }

  @Action
  public async updateFavoriteFolders() {
    const { favorite } = this;

    if (favorite === null) {
      return;
    }

    await favoritesApi.update(favorite);
  }

  @Action
  public async updateFavorite({ id, added_folder_ids, deleted_folder_ids }: UpdateMyFavoriteParams) {
    const favorite = this.favoriteMap[id];
    if (!favorite) {
      // Nothing to update if did not find the folder.
      return;
    }

    // 1. Add new folder id's to the list where we have all existing folder ids
    // 2. Remove folder id's that the user removed
    // 3. As a result we have list of the current folder ids.
    const folders = [...favorite.folder_ids, ...added_folder_ids].filter(
      (folderId) => !deleted_folder_ids.includes(folderId),
    );
    const response = await favoritesApi.update({ id, folder_ids: folders } as Favorite);

    // a new set is made, so that all ids are unique between the
    // response folder ids and folders, that are combined to account
    // for the uncategorized folder. Soley relying on one or the other
    // will miss the uncategorized folder increment/decrement in edge cases.
    const allFolderIds = [...new Set([...response.favorite.folder_ids, ...folders, ...deleted_folder_ids])];

    const refetchedFolders = await foldersStore.fetchFoldersById({
      folderIds: allFolderIds,
      sortOrder: FolderSortOptions.NAME_ASCENDING,
    });

    try {
      response.favorite.folder_ids = refetchedFolders
        .map((folder) => folder.id)
        .filter((id) => response.favorite.folder_ids.includes(id));
      this.UPDATE_FAVORITE(response.favorite);
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  /**
   * Central method to fetch and set favorites within folders.
   * This action should not be called directly from the application, and instead should be proxied
   * through other actions to form the correct api data payload and feed name. Pagination should be
   * consistent enough that it can be handled uniformly in a single method.
   */
  @Action
  async fetchFolderFavoritesPage({
    query,
    folderIds,
    overwriteBool,
    sorting = false,
    allSaved = false,
  }: {
    query: string;
    folderIds: string[];
    overwriteBool?: boolean;
    sorting?: boolean;
    allSaved?: boolean;
  }) {
    const storeId = allSaved ? allSavedFolderId : folderIds[0];
    const folderFavorites = this.getSingleFolderFavoritesObject(storeId);
    const meta = folderFavorites.meta;

    /*
      Skip reserved IDs, e.g. "all-saved" that are generated client side but do not actually exist in the backend.
    */
    const reservedFolderIds = ['all-saved'];
    const filteredFolderIds = folderIds.filter((id) => reservedFolderIds.indexOf(id) < 0);
    if (filteredFolderIds.length === 0) {
      return;
    }

    const data: SearchParams = {
      query: query,
      folderIds: filteredFolderIds,
    };

    let overwrite: boolean;
    if (overwriteBool) {
      overwrite = overwriteBool;
    } else {
      overwrite = folderFavorites.query !== query;
    }

    if (meta?.next_url === null && folderFavorites.query === query && !sorting) {
      return;
    }

    if (meta?.next_url !== undefined && folderFavorites.query === query && !overwrite) {
      data.beforeId = meta.last_id;
      data.limit = meta.limit;
      data.offset = folderFavorites.ids.length;
    }

    data.sort = this.favoriteSortParameter;

    try {
      const response = await favoritesApi.search(data);
      if (response.favorites.length > 0) {
        const productIds = response.favorites.map((favorite) => favorite.product_id);
        await productsStore.fetchProductsCoApiDetails(productIds);
        await productReviewsStore.fetchProductReviews(productIds);
      }

      this.FETCH_FAVORITES(response.favorites);
      const favoritesIds = response.favorites.map(({ id }) => id);
      this.SET_FOLDER_FAVORITES({
        folderIdParam: storeId,
        ids: favoritesIds,
        meta: response.meta,
        query: query,
        overwrite: overwrite,
      });
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async search(limit: number) {
    const data: SearchParams = {
      query: this.favoriteQuery,
      limit: limit,
      offset: this.searchedFavoriteIds.length,
    };

    const response = await favoritesApi.search(data);

    const favoriteIds = response.favorites.map(({ id }) => id);

    if (favoriteIds.length > 0) {
      const productIds = response.favorites.map((favorite) => favorite.product_id);
      await productsStore.fetchProductsCoApiDetails(productIds);
      await productReviewsStore.fetchProductReviews(productIds);
    }

    this.FETCH_FAVORITES(response.favorites);

    if (data.query != this.favoriteQuery) {
      // There are multiple network calls before we end up here,
      // and there is a chance that the query has already changed
      // and the next requests are on the way.
      // If that's the case, do not set data, but results and wait
      // for the next one.
      return;
    }

    this.SET_SEARCHED_FAVORITES({ favoriteIds: favoriteIds, meta: response.meta });
  }

  @Action
  public async appendProductImages(productImages: ProductImage[]) {
    productsStore.FETCH_PRODUCT_IMAGES(productImages);
  }

  @Action
  public async appendFavorites(favorites: Favorite[]) {
    this.FETCH_FAVORITES(favorites);
  }

  @Action
  public async appendPreviews(folderPreview: FolderPreview) {
    this.SET_FOLDER_PREVIEWS(folderPreview);
  }

  /*
    Sorting is only currently available from the backend without any querying.
    On sort the folder's products will be fetched anew to ensure that newly added items are included.
  */
  @Action
  public async sortProducts({
    newFavoriteSortParameter,
    currentFolderId,
    allSaved,
  }: {
    newFavoriteSortParameter: FavoriteSortOptions;
    currentFolderId: string;
    allSaved: boolean;
  }) {
    this.UPDATE_FAVORITE_SORT_PARAMETER(newFavoriteSortParameter);
    const storeId = allSaved ? allSavedFolderId : currentFolderId;
    /*
      The All Saved Products folder is generated client side, so we should not send the id ('all-saved') with requests.
    */
    let validFolderIds;
    if (allSaved) {
      validFolderIds = Object.keys(foldersStore.folderMap).filter((id) => id !== 'all-saved');
    } else {
      validFolderIds = [currentFolderId];
    }
    const data: SortParams = {
      folderIds: validFolderIds,
      sort: this.favoriteSortParameter,
    };

    try {
      const response = await favoritesApi.sort(data);
      if (response.favorites.length > 0) {
        const productIds = response.favorites.map((favorite) => favorite.product_id);
        await productsStore.fetchProductsCoApiDetails(productIds);
        await productReviewsStore.fetchProductReviews(productIds);
      }

      this.FETCH_FAVORITES(response.favorites);
      const favoritesIds = response.favorites.map(({ id }) => id);
      this.SET_FOLDER_FAVORITES({
        folderIdParam: storeId,
        ids: favoritesIds,
        meta: response.meta,
        overwrite: true,
      });
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Action
  public async fetchRecentFavoriteIds(limit: number) {
    try {
      const response = await favoritesApi.fetchMultipleUserRecentFavorites({
        limit: limit,
        beforeId: this.recentFavoritesMetaLastId,
      });

      const favoriteIds = response.favorites.map(({ id }) => id);

      if (favoriteIds.length > 0) {
        const productIds = response.favorites.map((favorite) => favorite.product_id);
        await productsStore.fetchProductsCoApiDetails(productIds);
        await productReviewsStore.fetchProductReviews(productIds);
      }

      this.SET_RECENT_FAVORITES({ favoriteIds: favoriteIds, meta: response.meta });
      this.FETCH_FAVORITES(response.favorites);
    } catch (error) {
      this.SET_SERVER_ERROR(error as ApiErrorResponse);
    }
  }

  @Mutation
  public UPDATE_SEARCH_QUERY(query: string) {
    // First, reset the previous search results.
    Vue.set(this, 'searchedFavoriteIds', []);
    Vue.set(this, 'searchedFavoriteIdsHasMorePages', false);

    // Then set a new query.
    this.favoriteQuery = query;
  }

  @Mutation
  public UPDATE_FAVORITE_FOLDER_IDS({ favoriteId, folderIds }: { favoriteId: string; folderIds: string[] }) {
    const favorite = this.favoriteMap[favoriteId];
    if (!favorite) {
      throw Error(`Did not find favorite with the id ${favoriteId}`);
    }

    Vue.set(this.favoriteMap, favorite.id, { ...favorite, folder_ids: folderIds });
  }

  @Mutation
  public FETCH_FAVORITE(favorite: Favorite | null) {
    Vue.set(this, 'favorite', favorite);
    if (favorite !== null) {
      Vue.set(this.favoriteMap, favorite.id, favorite);
    }
  }

  @Mutation
  public FETCH_FAVORITES(favorites: Favorite[]) {
    Object.values(favorites).forEach((favorite) => {
      Vue.set(this.favoriteMap, favorite.id, favorite);
    });
  }

  @Mutation
  public SET_RECENT_FAVORITES({ favoriteIds, meta }: { favoriteIds: string[]; meta: FavoritesServiceMeta }) {
    Vue.set(this, 'recentFavoriteIds', [...this.recentFavoriteIds, ...favoriteIds]);
    Vue.set(this, 'recentFavoritesMetaLastId', meta?.last_id ?? null);
  }

  @Mutation
  public REMOVE_RECENT_FAVORITES() {
    Vue.set(this, 'recentFavoriteIds', []);
    Vue.set(this, 'recentFavoritesMetaLastId', null);
  }

  @Mutation
  public SET_SEARCHED_FAVORITES({ favoriteIds, meta }: { favoriteIds: string[]; meta: FavoritesServiceMeta }) {
    Vue.set(this, 'searchedFavoriteIds', [...this.searchedFavoriteIds, ...favoriteIds]);
    Vue.set(this, 'searchedFavoriteIdsHasMorePages', !!meta?.last_id);
  }

  @Mutation
  public UPDATE_FAVORITE(favorite: Favorite) {
    Vue.set(this.favoriteMap, favorite.id, favorite);
  }

  @Mutation
  public ADD_FOLDER_FAVORITES(folderId: string) {
    if (this.favorite === null || folderId === undefined) {
      return;
    }
    this.favorite.folder_ids.push(folderId);
  }

  @Mutation
  public REMOVE_FOLDER_FAVORITES(folderId: string) {
    if (this.favorite === null || folderId === undefined) {
      return;
    }
    const folderIndex = this.favorite.folder_ids.findIndex((folder) => folder === folderId);
    this.favorite.folder_ids.splice(folderIndex, 1);
  }

  @Mutation
  SET_FOLDER_PREVIEWS({ folderId, ids, meta }: FolderPreview) {
    folderId = folderId.toLowerCase();
    if (this.folderPreviewMap[folderId] === undefined) {
      Vue.set(this.folderPreviewMap, folderId, emptyFolderFavoritesFactory());
    }
    const folderPreview = {
      ids: ids ?? null,
      meta: meta ?? null,
    };
    Vue.set(this.folderPreviewMap, folderId, folderPreview);
  }

  @Mutation
  SET_FOLDER_FAVORITES({
    folderIdParam,
    ids,
    meta,
    query = '',
    overwrite = false,
  }: {
    folderIdParam: string;
    ids: string[];
    meta?: FavoritesServiceMeta | null;
    query?: string;
    overwrite?: boolean;
  }) {
    // Normalize folder id
    const folderId = folderIdParam.toLowerCase();

    // Initialize Folder Favorites Object if it doesn't exist
    if (this.folderFavoritesMap[folderId] === undefined) {
      Vue.set(this.folderFavoritesMap, folderId, emptyFolderFavoritesFactory());
    }

    if (overwrite) {
      Vue.set(this.folderFavoritesMap[folderId] as FolderFavorites, 'ids', []);
    }

    // Prevent duplicate ids from being added to the folder in folderFavoritesMap
    const folderFavorites = this.folderFavoritesMap[folderId] as FolderFavorites;
    const existingFavoriteIds = [...folderFavorites.ids];
    const newFavoriteIds = ids.filter((id) => existingFavoriteIds.indexOf(id) < 0);

    let uniqueFavoriteIds;
    if (newFavoriteIds.length === 0) {
      // All favorites already were in map, show them in the new order
      uniqueFavoriteIds = [...existingFavoriteIds];
    } else {
      // Add new ids to the end of the array
      uniqueFavoriteIds = [...existingFavoriteIds, ...newFavoriteIds];
    }

    // only allowed because already doing an undefined check above, but typescript doesnt know that
    Vue.set(this.folderFavoritesMap[folderId] as FolderFavorites, 'ids', uniqueFavoriteIds);
    Vue.set(this.folderFavoritesMap[folderId] as FolderFavorites, 'meta', meta ?? null);
    Vue.set(this, 'favoritesMetaLastId', meta?.last_id);
    this.folderFavoritesMap[folderId]!.query = query;
  }

  @Mutation
  public RESET_FOLDERS_FAV_MAP(folderId: string) {
    Vue.set(this.folderFavoritesMap, folderId, emptyFolderFavoritesFactory());
  }

  @Mutation
  public SET_SERVER_ERROR(error: ApiErrorResponse | null) {
    Vue.set(this, 'serverError', error);
  }

  @Mutation
  public UPDATE_FAVORITE_SORT_PARAMETER(newFavoriteSortParameter: FavoriteSortOptions) {
    Vue.set(this, 'favoriteSortParameter', newFavoriteSortParameter);
  }

  @Mutation
  public RESET_STORE() {
    Vue.set(this, 'favorite', null);
    Vue.set(this, 'favoriteMap', {});
    Vue.set(this, 'folderFavoritesMap', {});
    Vue.set(this, 'folderPreviewMap', {});
    Vue.set(this, 'favoritesMetaLastId', null);
    Vue.set(this, 'recentFavoriteIds', []);
    Vue.set(this, 'recentFavoritesMetaLastId', null);
    Vue.set(this, 'serverError', null);
  }
}

export default getModule(Favorites);
