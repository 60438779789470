export enum MyFoldersSnackbarThemeOptions {
  DEFAULT = 'default',
  EMPTY = 'empty',
  ERROR = 'error',
  LIGHT = 'light',
  SUCCESS = 'success',
}

export enum MyFoldersSnackbarIconOptions {
  DEFAULT = 'rsfont-launch',
  ERROR = 'error_outline',
  FOLDER_ADD = 'ltkfont-add-folder_v2',
  FOLDER_MINUS = 'ltkfont-minus-folder',
  NONE = '',
  SUCCESS = 'rsfont-check-line_v2',
  TAG_OUTLINE = 'ltkfont-tag-outline',
}

export enum MyFoldersSnackbarEventOptions {
  ADD_FOLDER_EVENT = 'add_folder',
  DELETE_FOLDER_EVENT = 'delete_folder',
  ERROR_ALREADY_EXISTS = 'error_already_exists',
  ERROR_GENERIC = 'error',
  UPDATE_FOLDER_EVENT = 'update_folder',
  ADD_FAVORITE_EVENT = 'add_favorite',
  DELETE_FAVORITE_EVENT = 'delete_favorite',
  UPDATE_FAVORITE_EVENT = 'update_favorite',
}
