import VueRouter from 'vue-router';

import { handleErrorsFor } from '@/router/navigation-guards/beforeEach/errors/handleErrorsFor';

import { checkAuthentication } from '@/router/navigation-guards/beforeEach/authentication/checkAuthentication';
import { checkExperiments } from '@/router/navigation-guards/beforeEach/amplitude/checkExperiments';
import { checkRedirect } from '@/router/navigation-guards/beforeEach/authentication/checkRedirect';
import { isUserApproved } from '@/router/navigation-guards/beforeEach/authentication/isUserApproved';
import { fetchExchangeRates } from '@/router/navigation-guards/beforeEach/fetchExchangeRates';
import { checkUserStatus } from '@/router/navigation-guards/beforeEach/checkUserStatus';

import { checkPageTitle } from '@/router/navigation-guards/afterEach/checkPageTitle';
import { datadogView } from '@/router/navigation-guards/afterEach/datadogView';
import { checkAmplitude } from '@/router/navigation-guards/beforeEach/amplitude/checkAmplitude';
import { checkApplicationStatus } from '@/router/navigation-guards/beforeEach/checkApplicationStatus';
import { checkEmailVerification } from '@/router/navigation-guards/beforeEach/checkEmailVerification';

/**
 * @description Registers global navigation guards and hooks for Vue Router.
 * See docs for Vue Router navigation guard and hook middleware: https://v3.router.vuejs.org/guide/advanced/navigation-guards.html
 * @param router
 */
export function registerNavigationGuards(router: VueRouter) {
  const pipeline = {
    beforeEach: [
      checkAuthentication,
      checkAmplitude,
      checkRedirect,
      checkExperiments,
      checkEmailVerification,
      checkApplicationStatus,
      isUserApproved(checkUserStatus),
      isUserApproved(fetchExchangeRates),
    ],
    afterEach: [datadogView, checkPageTitle],
  };

  pipeline.beforeEach.forEach((f) => {
    router.beforeEach(handleErrorsFor(f));
  });

  pipeline.afterEach.forEach((f) => {
    router.afterEach(f);
  });
}
