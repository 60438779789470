import { RouteConfig } from 'vue-router';
import {
  ACCOUNT_SETUP,
  ACCOUNT_SETUP_UPDATED_TERMS,
  ACCOUNT_SETUP_TERMS,
  ACCOUNT_SETUP_DETAILS,
  ACCOUNT_SETUP_PLATFORM,
  ACCOUNT_SETUP_CREATE_SHOP,
  ACCOUNT_SETUP_DONE,
  RESET_PASSWORD,
} from '@/utils/router/namedRoutes';

import LeavePagePopupLayout from '@/components/LeavePagePopupLayout.vue';

/*
  Documentation of using gettext without Vue: https://github.com/Polyconseil/vue-gettext#usage-of-translate-without-vue
  Note: Gives warning of no exported member
*/

const routes: RouteConfig[] = [
  {
    path: '/account-setup',
    component: () => import(/* webpackChunkName: "account-setup" */ '../AccountSetupWizard.vue'),
    children: [
      {
        path: '',
        name: ACCOUNT_SETUP,
      },
      {
        path: 'updated-terms',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            name: ACCOUNT_SETUP_UPDATED_TERMS,
            component: () => import(/* webpackChunkName: "account-setup" */ '../views/Terms.vue'),
          },
        ],
      },
      {
        path: 'terms',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            name: ACCOUNT_SETUP_TERMS,
            component: () => import(/* webpackChunkName: "account-setup" */ '../views/Terms.vue'),
          },
        ],
      },
      {
        path: 'account-details',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            name: ACCOUNT_SETUP_DETAILS,
            component: () => import(/* webpackChunkName: "account-setup" */ '../views/AccountDetails.vue'),
          },
        ],
      },
      {
        path: 'platform-information',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            name: ACCOUNT_SETUP_PLATFORM,
            component: () => import(/* webpackChunkName: "account-setup" */ '../views/PlatformInformation.vue'),
          },
        ],
      },
      {
        path: 'create-shop',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            name: ACCOUNT_SETUP_CREATE_SHOP,
            component: () => import(/* webpackChunkName: "account-setup" */ '../views/CreateShop.vue'),
          },
        ],
      },
      {
        path: 'done',
        name: ACCOUNT_SETUP_DONE,
        component: () => import(/* webpackChunkName: "account-setup" */ '../views/DoneDownload.vue'),
      },
    ],
  },
  {
    path: '/reset-password',
    name: RESET_PASSWORD,
    component: () => import(/* webpackChunkName: "reset" */ '../views/ResetPassword.vue'),
  },
];

export default routes;
