import { NavigationGuard } from 'vue-router';
import { auth } from '@/utils/auth';
import AccountsStore from '@/store/accounts';
import { UserStatus } from '@/utils/decodeUserStatus';

/**
 * @description A higher-order function for conditional navigation guard usage. Will call your navigation guard if the user is authenticated. Will call next() to move to the next navigation guard otherwise.
 */
export const isUserApproved: (f: NavigationGuard) => NavigationGuard = (f) => async (to, from, next) => {
  const isLoggedIn = await auth.isAuthenticated();

  const userStatus = AccountsStore.userStatusRoles;
  const isApproved = userStatus.includes(UserStatus.APPROVED);

  return isLoggedIn && isApproved ? f(to, from, next) : next();
};
