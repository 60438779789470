import _Vue from 'vue';
import rsUiLibrary from '@rscollabs/rs-ui-library';

import {
  RsAutocomplete,
  RsAvatar,
  RsAppFooter,
  RsAppFooterBody,
  RsAppFooterGroup,
  RsAppFooterItem,
  RsBtn,
  RsCard,
  RsCardActions,
  RsCardText,
  RsCardMedia,
  RsCardTitle,
  RsCheckbox,
  RsChip,
  RsDataTable,
  RsDatePicker,
  RsDialog,
  RsDivider,
  RsFooter,
  RsForm,
  RsGlobe,
  RsIcon,
  RsImg,
  RsList,
  RsListGroup,
  RsListTile,
  RsListTileAction,
  RsListTileAvatar,
  RsListTileContent,
  RsListTileTitle,
  RsLogo,
  RsLogoLtk,
  RsMenu,
  RsNavigationDrawer,
  RsPagination,
  RsProgressCircular,
  RsRadioGroup,
  RsRadio,
  RsSelect,
  RsSnackbar,
  RsSpacer,
  RsStepper,
  RsStepperContent,
  RsStepperHeader,
  RsStepperItems,
  RsStepperStep,
  RsSubheader,
  RsTab,
  RsTabs,
  RsTextarea,
  RsTextField,
  RsToolbar,
  RsToolbarItems,
  RsTooltip,
} from '@rscollabs/rs-ui-library/src/components';

export default (Vue: typeof _Vue) => {
  Vue.use(rsUiLibrary, {
    components: {
      RsAutocomplete,
      RsAvatar,
      RsAppFooter,
      RsAppFooterBody,
      RsAppFooterGroup,
      RsAppFooterItem,
      RsBtn,
      RsCard,
      RsCardActions,
      RsCardText,
      RsCardMedia,
      RsCardTitle,
      RsCheckbox,
      RsChip,
      RsDataTable,
      RsDatePicker,
      RsDialog,
      RsDivider,
      RsFooter,
      RsForm,
      RsIcon,
      RsImg,
      RsList,
      RsListGroup,
      RsListTile,
      RsListTileAction,
      RsListTileAvatar,
      RsListTileContent,
      RsListTileTitle,
      RsLogo,
      RsLogoLtk,
      RsMenu,
      RsNavigationDrawer,
      RsPagination,
      RsProgressCircular,
      RsRadioGroup,
      RsRadio,
      RsSelect,
      RsSnackbar,
      RsSpacer,
      RsStepper,
      RsStepperContent,
      RsStepperHeader,
      RsStepperItems,
      RsStepperStep,
      RsSubheader,
      RsTab,
      RsTabs,
      RsTextarea,
      RsTextField,
      RsToolbar,
      RsToolbarItems,
      RsTooltip,
    },
  });
};
