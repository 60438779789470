import { defineStore } from 'pinia';
import { ref } from 'vue';
import { PostProduct } from '@/types/postProducts';
import { PostMatchType } from '@/types/postProducts';

export const usePostProductsStore = defineStore('Post Products', () => {
  // Initial State
  const products = ref<Array<PostProduct>>([]);

  // Setters
  const setProducts = (newProducts: PostProduct[]) => {
    products.value = [...newProducts];
  };

  const addProduct = (newProduct: PostProduct) => {
    products.value = [...products.value, newProduct];
  };

  /*
    In keeping with how iOS handles position we will use an explicit position
      value instead of an object's index in the array.
  */
  const removeByPosition = (position: number) => {
    const newProducts = products.value.filter((product) => product.position !== position);
    products.value = newProducts;
  };

  const removeByProductRef = (product_reference_id: string) => {
    const newProducts = products.value.filter((product) => product.product_reference_id !== product_reference_id);
    products.value = newProducts;
  };

  const removeByFavorite = (favoriteId: string) => {
    const newProducts = products.value.filter((product) => product.favorite_id !== favoriteId);
    products.value = newProducts;
  };

  const updateProduct = (index: number, newProduct: PostProduct) => {
    const newProducts = [...products.value];
    newProducts[index] = newProduct;
    products.value = [...newProducts];
  };

  const reset = () => {
    products.value = [];
  };

  return {
    products,
    addProduct,
    removeByFavorite,
    removeByPosition,
    removeByProductRef,
    reset,
    setProducts,
    updateProduct,
  };
});
