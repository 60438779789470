
import { defineComponent, onUnmounted, watchEffect, PropType } from 'vue';
import type { FocusTrap, KeyboardEventToBoolean, FocusTargetOrFalse } from 'focus-trap';
import * as focusTrap from 'focus-trap';

export default defineComponent({
  name: 'RsFocusTrap',
  model: {
    prop: 'active',
    event: 'update:active',
  },
  props: {
    target: {
      type: [HTMLElement, String] as PropType<HTMLElement | string | null>,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    escapeDeactivates: {
      type: Boolean as PropType<KeyboardEventToBoolean | boolean | undefined>,
      default: true,
    },
    outsideClick: {
      type: Boolean,
      default: undefined,
    },
    returnFocus: {
      type: Boolean,
      default: true,
    },
    setReturnFocus: {
      type: [String, HTMLElement, SVGElement] as PropType<FocusTargetOrFalse | undefined>,
      default: undefined,
    },
    preventScroll: {
      type: Boolean,
      default: true,
    },
    initialFocus: {
      type: [String, HTMLElement, SVGElement] as PropType<FocusTargetOrFalse | undefined>,
    },
  },
  emits: ['activate', 'deactivate', 'update:active'],
  setup(props, { emit }) {
    let trap = null as FocusTrap | null;

    watchEffect(
      () => {
        if (props.target instanceof HTMLElement) {
          trap = focusTrap.createFocusTrap(props.target, {
            returnFocusOnDeactivate: props.returnFocus,
            clickOutsideDeactivates: props.outsideClick,
            setReturnFocus: props.setReturnFocus as any,
            escapeDeactivates: props.escapeDeactivates,
            preventScroll: props.preventScroll,
            initialFocus: props.initialFocus,
            fallbackFocus: props.target,
            onActivate: () => {
              emit('update:active', true);
              emit('activate', true);
            },
            onDeactivate: () => {
              emit('update:active', false);
              emit('activate', false);
              emit('deactivate', true);
            },
          });
          if (props.active || props.modelValue) {
            trap.activate();
          } else {
            trap.deactivate();
          }
        }
      },
      { flush: 'pre' },
    );

    onUnmounted(() => {
      trap?.deactivate();
    });
  },
  render(): any {
    return this.$slots.default;
  },
});
